import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    createUser,
    getAllRoles
} from "../../../../../appRedux/actions";
import Create from './create';

class CreateContainer extends React.Component {

    onFinish = values => {
        const { history, roles: { list } } = this.props
        const handleSuccess = () => {
            message.success('User created successfully');
            history.push('/main/settings/user/users');
        }
        const handleFailure = () => {
            message.error('User creation failed, please try again');
        }
        list.map( item => {
            if(item.name === values.role_id) {
                values.role_id = item.id
            }
        })
        this.props.createUser(handleSuccess, handleFailure, values)
    }
    componentDidMount() {
      this.props.getAllRoles();
    }
    render() {
        const { isFetching, roles } = this.props;
        return (
            <Create onFinish={this.onFinish} isFetching={isFetching} roles={roles} />
        );
    }
};

const mapStateToProps = ({ user }) => {
    const { isFetching, roles } = user
    return { isFetching, roles }
};
export default connect(mapStateToProps, {
    createUser,
    getAllRoles
})(CreateContainer);
