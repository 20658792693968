import React from "react";
import { Route, Switch } from "react-router-dom";
import Transit from "./transit";

const TransitRoute = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/transit`} component={Transit} />
  </Switch>
);

export default TransitRoute;
