import Constants from '../../constants/state';

const INITIAL_STATE = {
  data: [],
  alllist: [],
  details: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {    
    case Constants.GET_ALL_STATES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_STATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_STATES_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_STATE_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_STATE_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_STATE_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.STATE_REGISTER_REQUEST:
        return { 
            ...state, 
            isFetching: true 
        };
    case Constants.STATE_REGISTER_ERROR:
        return {
              ...state,
              isFetching: false 
        };
    case Constants.STATE_REGISTER_SUCCESS:
        return {
              ...state, 
              isFetching: false 
        };
    case Constants.DELETE_STATE_REQUEST:
        return {
            ...state,
            isFetching: true
        }
    case Constants.DELETE_STATE_SUCCESS:
        return {
            ...state,
            stateDelete: action.payload,
            isFetching: false
        }
    case Constants.DELETE_STATE_FAIL:
        return {
            ...state,
            error: action.payload,
            isFetching: false
        }
        case Constants.STATE_UPDATE_REQUEST:
          return {
              ...state,
              isFetching: true
          }
      case Constants.STATE_UPDATE_SUCCESS:
          return {
              ...state,
              // stateDelete: action.payload,
              isFetching: false
          }
      case Constants.STATE_UPDATE_ERROR:
          return {
              ...state,
              error: action.payload,
              isFetching: false
          }
      case Constants.RESET_DETAILS:
          return {
            ...state,
            details: {},
          };
    case 'SIMPLE_ACTION':
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};
