import React from "react";
import { Button, Card, Row, Col, Table, Form, Input, Tooltip, Divider, Popconfirm, message } from "antd";
import { SnippetsFilled, EditFilled, DeleteFilled, ReloadOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import { SearchOutlined } from '@ant-design/icons';
import { Space } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import {
  getAllRoles,
  deleteRole
} from "../../../../../appRedux/actions";
import DataExport from "../../../../components/feedback/Export/DataExport";
import { hasActionPermission } from "../../../../../util/rolesHelper";
import { ACTION_CREATE, ACTION_DELETE, ACTION_EDIT, ACTION_VIEW, FEATURE_ROLE, FEATURE_USER } from "../../../../../constants/Roles";

class LrDetails extends React.Component {
  state = {
    tableConfig: {
    },
    page: 1,
    pageSize: 20,
    dataExportVisible: false
  };

  componentDidMount() {
    this.props.getAllRoles();
  }

  reactivate = (id) => {
    const data = {
      id:id,
      deleted_at:null
    }
    const handleSuccess = () => {
      message.success('Role reactivated successfully');
      this.props.getAllRoles();
    }
    const handleFailure = () => {
      message.error('Role reactivation failed, please try again');
    }
    this.props.updateUser(handleSuccess, handleFailure, data)
  }
  confirm = (id) => {
    const { history } = this.props
    const handleSuccess = () => {
        message.success('Role deleted successfully');
        this.props.getAllRoles();
    }
    const handleFailure = () => {
        message.error('Role deletion failed, please try again');
    }
    this.props.deleteRole(handleSuccess, handleFailure, id)
  }

  setPage = (page, pageSize) => {
    this.setState({page, pageSize})
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  dataExportModel = (dataExportVisible) => {
    this.setState({dataExportVisible})
  }
  render() {
    const { setPage } = this;
    const { tableConfig, pageSize, dataExportVisible } = this.state;
    const { isFetching, roles: { list }, authUser } = this.props;
    let userAccess = [];
    if (authUser && authUser.details && authUser.details.Role && authUser.details.Role) {
      userAccess = JSON.parse(authUser.details.Role.access);
    }
    const columns = [
      {
        title: 'ID',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        render: (value, item, index) => <span className="gx-link">{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
  
      },
      {
        title: 'Role Name',
        dataIndex: 'role',
        key: 'role',
        render: text => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("role"),
        sorter: (a, b) => a["role"].localeCompare(b["role"]),
      }, {
        title: 'Action',
        key: 'action',
        width: 150,
        render: (text, record) => {
          const { authUser } = this.props;
          let userAccess = [];
          if(authUser && authUser.details && authUser.details.Role && authUser.details.Role) {
            userAccess = JSON.parse(authUser.details.Role.access);
          }
          return(
          <span>
             {  record.deleted_at ?'':  <>
           { hasActionPermission(userAccess, FEATURE_ROLE, ACTION_VIEW) ? <Tooltip title="View">
              <Link to={`/main/settings/role/view/${record.id}`}>
                <Button className="gx-margin-none" type="text" size={"small"} icon={<SnippetsFilled />} />
              </Link>
            </Tooltip>:null}
            <Divider type="vertical"/>
            { hasActionPermission(userAccess, FEATURE_ROLE, ACTION_EDIT) ?<Tooltip title="Edit">
              <Link to={`/main/settings/role/edit/${record.id}`}>
                <Button className="gx-margin-none" type="text" size={"small"} icon={<EditFilled />} />
              </Link>
            </Tooltip>:null}
            <Divider type="vertical"/>
            { hasActionPermission(userAccess, FEATURE_ROLE, ACTION_DELETE)  && !record.deleted_at ? <Tooltip title="Delete">
              <Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => this.confirm(record.id)} onCancel={() => this.cancel()} okText="Yes"
                          cancelText="No">
                <Button className="gx-margin-none" type="text" size={"small"} icon={<DeleteFilled />} />
              </Popconfirm>
            </Tooltip>:null}
            </>}
            { hasActionPermission(userAccess, FEATURE_ROLE, ACTION_DELETE)  && record.deleted_at ? <Tooltip title="Reactivate">
                  <Popconfirm title="Are you sure you want to reactivate this user?" onConfirm={() => this.reactivate(record.id)} onCancel={() => this.cancel()} okText="Yes"
                    cancelText="No">
                    <Button className="gx-margin-none" type="text" size={"small"} icon={<ReloadOutlined />} />
                  </Popconfirm>
                </Tooltip> : null}
          </span>
          )
          }
      }];
    return (
      <Card title="Role">
        {dataExportVisible ? <DataExport
          dataExportModel={this.dataExportModel}
          visible={dataExportVisible}
          columns={columns}
          data={list}
          fileName={`Roles_${Date()}`}
        /> : null}
        <Row>
          <Col span={24}>
            {hasActionPermission(userAccess, FEATURE_ROLE, ACTION_CREATE) ? <Link to="/main/settings/role/create">
              <Button
                type="primary"
                className="gx-float-right"
              >
                Add Role
            </Button>
            </Link> : null}
            <Button
              type="primary"
              className="gx-float-right"
              style={{ marginRight: "10px" }}
              onClick={() => this.dataExportModel(true)}
            >
              Export Data
            </Button>
          </Col>
        </Row>
        { hasActionPermission(userAccess, FEATURE_ROLE, ACTION_VIEW) ? <Table
          pagination={{
            onChange(current, pageSize) {
              setPage(current, pageSize);
            },
            pageSize
          }}
          bordered={true} size={'small'} className="gx-table-responsive" loading={isFetching} columns={columns} dataSource={list} /> : null}
      </Card>
    );
  }
}

const mapStateToProps = ({ user }) => {
  const { roles, isFetching, authUser } = user

  return { roles, isFetching, authUser }
};
export default connect(mapStateToProps, {
  getAllRoles,
  deleteRole
})(LrDetails);