import React from "react";
import { Button, Card, Row, Col, Table, Tooltip, Divider, Popconfirm, message, Calendar, DatePicker } from "antd";
import { SnippetsFilled, EditFilled, DeleteFilled } from '@ant-design/icons';
import {connect} from "react-redux";
import moment from 'moment';
import { DateRange } from 'react-date-range';
import TaskList from "components/dashboard/CRM/TaskList";
import SiteVisit from "components/dashboard/CRM/SiteVisit";
import TaskByStatus from "components/dashboard/CRM/TaskByStatus";
import Overview from "components/dashboard/CRM/Overview";
import Auxiliary from "util/Auxiliary";
import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";
import { taskList } from "./CRM/data";
import {
  getAllUsers,
  deleteUser
} from "../../../appRedux/actions";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const { RangePicker } = DatePicker;
const columns = [{
  title: 'LR Number',
  dataIndex: 'id',
  width: 150,
}, {
  title: 'Date',
  dataIndex: 'date',
  width: 150,
}, {
  title: 'Consignee Name',
  dataIndex: 'customer',
}, {
  title: 'Destination',
  dataIndex: 'destination',
}, {
  title: 'Status',
  dataIndex: 'status',
}];

const data = [];
for (let i = 0; i < 50; i++) {
  data.push({
    key: i,
    id: i,
    date: moment().format('DD-MM-YYYY'),
    customer: `Name`,
    destination: `City name`,
    status: `Dispatch`,
  });
}
const driverColumns = [{
  title: 'Name',
  dataIndex: 'id',
  width: 300,
}, {
  title: 'Phone number',
  dataIndex: 'number',
  width: 200,
}, {
  title: 'Amount',
  dataIndex: 'amount',
}];

const driverData = [];
for (let i = 0; i < 50; i++) {
  driverData.push({
    key: i,
    id: `Driver name${i}`,
    number: '9090909090',
    amount: 5*i,
  });
}

class Dashboard extends React.Component {
  state = {
    tableConfig: {
    },
    selectedDates: [{
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }]
  };

  componentDidMount() {
    this.props.getAllUsers();
  }

  render() {
    const { tableConfig, selectedDates } = this.state;
    const { isFetching, alllist: { list } } = this.props;
    return (
      <Auxiliary>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <IconWithTextCard cardColor="package-picked" icon="orders" title="09" subTitle="Package Picked"/>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <IconWithTextCard cardColor="dispatched" icon="sent" title="687" subTitle="Dispatched"/>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <IconWithTextCard cardColor="in-transit" icon="map-drawing" title="04" subTitle="In-Transit"/>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <IconWithTextCard cardColor="delivered" icon="feedback" title="09" subTitle="Delivered"/>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <IconWithTextCard cardColor="invoiced" icon="pricing-table" title="09" subTitle="Invoiced"/>
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <TaskByStatus/>
              </Col>
              <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                  <SiteVisit/>
              </Col>
              <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                <Card title="Driver Advance">
                  <Table className="gx-table-responsive" columns={driverColumns} dataSource={driverData} pagination={{pageSize: 50}}
                        scroll={{y: 205}}/>
                </Card>
              </Col>
              <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                <Overview/>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card title="LR Details">
                  <Row>
                    <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                        <Table className="gx-table-responsive" columns={columns} dataSource={data} pagination={{pageSize: 50}}
                              scroll={{y: 240}}/>
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                      <DateRange
                        onChange={item => {
                          this.setState({selectedDates: [item.selection]})}
                        }
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={selectedDates}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ user }) => {
  const { alllist, isFetching } = user
  return { alllist, isFetching }
};
export default connect(mapStateToProps, {
  getAllUsers,
  deleteUser
})(Dashboard);