import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    createBranch,
    getAllCities
} from "../../../../../appRedux/actions";
import Create from './create';

class CreateContainer extends React.Component {

    onFinish = values => {
        const { history, alllist: { list }, modalAction } = this.props
        const handleSuccess = () => {
            message.success('Branch created successfully');
            if(modalAction) {
                modalAction();
            } else {
                history.push('/main/master/branch/branches');
            }
        }
        const handleFailure = () => {
            message.error('Branch creation failed, please try again');
        }
        this.props.createBranch(handleSuccess, handleFailure, values)
    }
    componentDidMount() {
      this.props.getAllCities();
    }
    render() {
        const { isFetching, alllist } = this.props;
        return (
            <Create onFinish={this.onFinish} isFetching={isFetching} alllist={alllist} />
        );
    }
};

const mapStateToProps = ({ branch, city }) => {
    const { alllist } = city
    const { isFetching } = branch
    return { isFetching, alllist }
};
export default connect(mapStateToProps, {
    createBranch,
    getAllCities
})(CreateContainer);