import React, { useState, useEffect } from "react";
import { Modal, Radio, Form, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { DateRange } from "react-date-range";
import { getReportInvoices, getAllCustomers, getConsigneeInvoices, getConsignorInvoices } from "../../../../appRedux/actions";

const { Option } = Select;

const DataExport = ({ visible, reportModel }) => {
    const dispatch = useDispatch();
    const { alllist } = useSelector(({ customer }) => customer);
    const [selectedDates, setSelectedDates] = useState(
        {
            startDate: moment().startOf("month").toDate(),
            endDate: moment().toDate(),
            key: "selection",
        },
    );
    const [reportType, setReportType] = useState("none");
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [customerError, setCustomerError] = useState(false);
    useEffect(() => {
        dispatch(getAllCustomers())
    }, [dispatch]);
    useEffect(() => {
        const customers = [];
        if (alllist && alllist.list) {
            for (let i = 0; i < alllist.list.length; i++) {
                customers.push({
                    key: alllist.list[i].id,
                    value: alllist.list[i].name,
                });
            }
        }
        setCustomers(customers)
    }, [alllist]);
    const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType })
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }
    const exportToJson = (selecteddata, fileName) => {
        var json = selecteddata;
        var fields = Object.keys(json[0])
        var replacer = function (key, value) { return value === null ? '' : value }
        var csv = json.map(function (row) {
            return fields.map(function (fieldName) {
                return JSON.stringify(row[fieldName], replacer)
            }).join(',')
        })
        csv.unshift(fields.join(',')) // add header column
        csv = csv.join('\r\n');
        downloadFile({
            data: csv,
            fileName: fileName,
            fileType: 'text/csv',
        })
    }
    const handleOk = (e) => {
        switch (reportType) {
            case 'consignee':
                if (customer) {
                    const handleSuccess = (res) => {
                        const dataList = res.list.map((item, itemIndex) => {
                            return {
                                'Sr no.': itemIndex + 1,
                                'Invoice Date': moment(item['Invoice.invoiceDate']).format('DD-MM-YYYY HH:mm:ss'),
                                'Invoice Number': `EELPL/${item['Invoice.finacialYear']}/${String(item['Invoice.invoiceNo']).padStart(4, '0')}`,
                                'LR number': item['LRdetail.number'],
                                'Origin': `${item['LRdetail.origin_city.name']}, ${item['LRdetail.origin_state.name']}`,
                                'Destination': `${item['LRdetail.destination_city.name']}, ${item['LRdetail.destination_state.name']}`,
                                'Freight': item.freight,
                                'Detention': item.detention,
                                '2 Point Delivery': item.pointDelivery,
                                'Express Charge': item.expressCharge,
                                'Loading / Unloading': `${item.loading} / ${item.unloading}`,
                                'Total Amount': item['Invoice.total'],
                                'CGST': (item['Invoice.cgst'] * item['Invoice.total']) / 100,
                                'SGST': (item['Invoice.sgst'] * item['Invoice.total']) / 100,
                                'IGST': (item['Invoice.igst'] * item['Invoice.total']) / 100,
                                'Grant Total Amount': item['Invoice.amount'],
                            }
                        });
                        if(dataList.length) {
                            exportToJson(dataList, 'Invoices_Report_Consignee.csv');
                        } else {
                            message.error(`No data found for selected dates, hence unable to generate report.`);
                        }
                    }
                    const handleFailure = () => {
                        console.log("res error>>>>");
                    }
                    dispatch(getConsigneeInvoices(customer, selectedDates, handleSuccess, handleFailure));
                    reportModel(false);
                } else {
                    setCustomerError(true);
                }
                break;
            case 'consignor':
                if (customer) {
                    const handleSuccess = (res) => {
                        const dataList = res.list.map((item, itemIndex) => {
                            return {
                                'Sr no.': itemIndex + 1,
                                'Invoice Date': moment(item['Invoice.invoiceDate']).format('DD-MM-YYYY HH:mm:ss'),
                                'Invoice Number': `EELPL/${item['Invoice.finacialYear']}/${String(item['Invoice.invoiceNo']).padStart(4, '0')}`,
                                'LR number': item['LRdetail.number'],
                                'Origin': `${item['LRdetail.origin_city.name']}, ${item['LRdetail.origin_state.name']}`,
                                'Destination': `${item['LRdetail.destination_city.name']}, ${item['LRdetail.destination_state.name']}`,
                                'Freight': item.freight,
                                'Detention': item.detention,
                                '2 Point Delivery': item.pointDelivery,
                                'Express Charge': item.expressCharge,
                                'Loading / Unloading': `${item.loading} / ${item.unloading}`,
                                'Total Amount': item['Invoice.total'],
                                'CGST': (item['Invoice.cgst'] * item['Invoice.total']) / 100,
                                'SGST': (item['Invoice.sgst'] * item['Invoice.total']) / 100,
                                'IGST': (item['Invoice.igst'] * item['Invoice.total']) / 100,
                                'Grant Total Amount': item['Invoice.amount'],
                            }
                        });
                        if(dataList.length) {
                            exportToJson(dataList, 'Invoices_Report_Consignor.csv');
                        } else {
                            message.error(`No data found for selected dates, hence unable to generate report.`);
                        }
                    }
                    const handleFailure = () => {
                        console.log("res error>>>>");
                    }
                    dispatch(getConsignorInvoices(customer, selectedDates, handleSuccess, handleFailure));
                    reportModel(false);
                } else {
                    setCustomerError(true);
                }
                break;
            default:
                const handleSuccess = (res) => {
                    const dataList = res.list.map((item, itemIndex) => {
                        return {
                            'Sr no.': itemIndex + 1,
                            'Invoice Date': moment(item.invoiceDate).format('DD-MM-YYYY HH:mm:ss'),
                            'Invoice Number': `EELPL/${item.finacialYear}/${String(item.invoiceNo).padStart(4, '0')}`,
                            'Customer Name': item['Customer.name'],
                            'GST number': item['Customer.GST_number'],
                            'Total Amount': item.total,
                            'CGST': (item.cgst * item.total) / 100,
                            'SGST': (item.sgst * item.total) / 100,
                            'IGST': (item.igst * item.total) / 100,
                            'Grant Total Amount': item.amount,
                        }
                    });
                    if(dataList.length) {
                        exportToJson(dataList, 'Invoices_Report.csv');
                    } else {
                        message.error(`No data found for selected dates, hence unable to generate report.`);
                    }
                }
                const handleFailure = () => {
                    console.log("res error>>>>");
                }
                dispatch(getReportInvoices(selectedDates, handleSuccess, handleFailure));
                reportModel(false);
                break;
        }
    };
    const handleCancel = (e) => {
        reportModel(false);
    };
    return (
        <Modal
            title="Report"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            className="fx-data-export"
        >
            <DateRange
                onChange={(item) => {
                    console.log("item>>>>", item);
                    setSelectedDates(item.selection);
                }}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={[selectedDates]}
            />
            <Radio.Group value={reportType} onChange={(event) => setReportType(event.target.value)} className="gx-mb-3">
                <Radio.Button value="none">None</Radio.Button>
                <Radio.Button value="consignee">Consignee</Radio.Button>
                <Radio.Button value="consignor">Consignor</Radio.Button>
            </Radio.Group>
            <div className="invoice-report-customer-section">
                {reportType === 'none' ? null : reportType === 'consignor' ? <Form.Item
                    label="Consignor Name"
                    name="consignorId"
                    validateStatus={customerError ? "error" : ""}
                    help={customerError ? "Please select consignor." : ""}
                >
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Consignor"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(event) => setCustomer(event)}
                    >
                        {customers.map((d) => (
                            <Option key={d.key}>{d.value}</Option>
                        ))}
                    </Select>
                </Form.Item> : <Form.Item
                    label="Consignee Name"
                    name="consigneeId"
                    validateStatus={customerError ? "error" : ""}
                    help={customerError ? "Please select consignee." : ""}
                >
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Consignee"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(event) => setCustomer(event)}
                    >
                        {customers.map((d) => (
                            <Option key={d.key}>{d.value}</Option>
                        ))}
                    </Select>
                </Form.Item>}
            </div>
        </Modal>
    );
}

export default DataExport;
