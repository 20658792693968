import Constants from "constants/Transit";

const INITIAL_STATE = {
  data: [],
  alllist: [],
  details: {},
  lrtransitdetails: {},
  deliveryStatuses: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {    
    case Constants.GET_ALL_TRANSIT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_TRANSIT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_TRANSIT_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_TRANSIT_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_TRANSIT_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_TRANSIT_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.GET_LR_TRANSIT_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_LR_TRANSIT_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lrtransitdetails: action.payload,
      };
    case Constants.GET_LR_TRANSIT_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.CREATE_TRANSIT_REQUEST:
        return { 
            ...state, 
            isFetching: true 
        };
    case Constants.CREATE_TRANSIT_ERROR:
        return {
              ...state,
              isFetching: false 
        };
    case Constants.CREATE_TRANSIT_SUCCESS:
        return {
              ...state, 
              isFetching: false 
        };
    case Constants.DELETE_TRANSIT_REQUEST:
        return {
            ...state,
            isFetching: true
        }
    case Constants.DELETE_TRANSIT_SUCCESS:
        return {
            ...state,
            countryDelete: action.payload,
            isFetching: false
        }
    case Constants.DELETE_TRANSIT_FAIL:
        return {
            ...state,
            error: action.payload,
            isFetching: false
        }

    case Constants.UPDATE_TRANSIT_REQUEST:
      return {
          ...state,
          isFetching: true
      };
    case Constants.UPDATE_TRANSIT_SUCCESS:
        return {
            ...state,
            isFetching: false
        };
    case Constants.UPDATE_TRANSIT_ERROR:
        return {
            ...state,
            isFetching: false
        };    
    case Constants.GET_ALL_DELIVERY_STATUS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_DELIVERY_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        deliveryStatuses: action.payload,
      };  
    case Constants.RESET_DETAILS:
        return {
          ...state,
          lrtransitdetails: {},
        };  
    case Constants.GET_ALL_DELIVERY_STATUS_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    default:
      return state;
  }
};
