import React from 'react';
import {
    Card,
    Descriptions
} from 'antd';
import { connect } from "react-redux";
import {
    getCountryDetails,
} from "../../../../../appRedux/actions";

class Country extends React.Component {

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getCountryDetails(id);
    }

    render() {
        const { isFetching, details } = this.props;
        return (
            <Card className="gx-card" title="Country Information">
                  <Descriptions title="">
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Country Name">{details && details.name ? details.name: ''}</Descriptions.Item>                   
                </Descriptions>
            </Card>
        );
    }
};

const mapStateToProps = ({ country }) => {
    const { isFetching, details } = country
    return { isFetching, details }
};
export default connect(mapStateToProps, {
    getCountryDetails
})(Country);