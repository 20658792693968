import Constants from "constants/Country";
import { API_DOMAIN } from '../../env';

export const getAllCountries = () => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_COUNTRIES_REQUEST,
    });
    return fetch(`${API_DOMAIN}/countries`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_COUNTRIES_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_COUNTRIES_SUCCESS,
                    payload: body,
                });
            }
        });
};

export const getCountryDetails = (id) => function (dispatch) {
    dispatch({
      type: Constants.GET_COUNTRY_DETAILS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/country/details/${id}`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_COUNTRY_DETAILS_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_COUNTRY_DETAILS_SUCCESS,
            payload: body,
          });
        }
      });
  };


export const createCountry = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.COUNTRY_REGISTER_REQUEST
        });
        fetch(`${API_DOMAIN}/country`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.COUNTRY_REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.COUNTRY_REGISTER_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

export const updateCountry = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.COUNTRY_UPDATE_REQUEST
        });
        fetch(`${API_DOMAIN}/country/update/${data.id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.COUNTRY_UPDATE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.COUNTRY_UPDATE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
  }

export const deleteCountry = (handleSuccess,id) => {
    console.log("id", id)
    return function (dispatch) {
        dispatch({
            type: Constants.DELETE_COUNTRY_REQUEST
        });
        return fetch(`${API_DOMAIN}/country/delete/${id}`, {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrer: "no-referrer",
        }).then(() => {
            dispatch({
                type: Constants.DELETE_COUNTRY_SUCCESS,
                payload: ''
            });
            handleSuccess();
        });
    }
};
export const reset = () => function (dispatch) {
    dispatch({
      type: Constants.RESET_DETAILS,
    });
  };