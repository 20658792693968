import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_INVOICE_REQUEST',
  'GET_ALL_INVOICE_SUCCESS',
  'GET_ALL_INVOICE_FAIL',
  'GET_INVOICE_REQUEST',
  'GET_INVOICE_SUCCESS',
  'GET_INVOICE_FAIL',
  'INVOICE_REGISTER_REQUEST',
  'INVOICE_REGISTER_ERROR',
  'INVOICE_REGISTER_SUCCESS',
  'DELETE_INVOICE_REQUEST',
  'DELETE_INVOICE_SUCCESS',
  'DELETE_INVOICE_FAIL',
  'INVOICE_UPDATE_REQUEST',
  'INVOICE_UPDATE_ERROR',
  'INVOICE_UPDATE_SUCCESS',
  'DELETE_INVOICE_REQUEST',
  'DELETE_INVOICE_SUCCESS',
  'INVOICE_PREVIEW_REGISTER_REQUEST',
  'INVOICE_PREVIEW_REGISTER_ERROR',
  'INVOICE_PREVIEW_REGISTER_SUCCESS',
  'DELETE_INVOICE_PREVIEW_REQUEST',
  'DELETE_INVOICE_PREVIEW_SUCCESS',
  'GET_REPORT_INVOICE_REQUEST',
  'GET_REPORT_INVOICE_SUCCESS',
  'GET_REPORT_INVOICE_FAIL',
  'GET_CONSIGNEE_INVOICE_REQUEST',
  'GET_CONSIGNEE_INVOICE_SUCCESS',
  'GET_CONSIGNEE_INVOICE_FAIL',
  'GET_CONSIGNOR_INVOICE_REQUEST',
  'GET_CONSIGNOR_INVOICE_SUCCESS',
  'GET_CONSIGNOR_INVOICE_FAIL',
]);
