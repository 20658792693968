import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Card,
    DatePicker,
    TimePicker,
    Select
} from 'antd';
import Pattern from 'constants/Pattern';
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 5 },
        lg: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        span: 24,
    },
};
const Create = (props) => {
    const { onFinish, alllist } = props;
    const [form] = Form.useForm();

    const onReset = () => {
        form.resetFields();
    };
    const Cityoptions = [];
    if (alllist && alllist.list) {
        for (let i = 0; i < alllist.list.length; i++) {
            Cityoptions.push({
                key: alllist.list[i].id,
                value: `${alllist.list[i].name}, ${alllist.list[i]["State.name"]}`,
            });
        }
    }
    return (
        <Card className="gx-card" title="Branch creation Form">
            <Form
                {...formItemLayout}
                form={form}
                name="branch"
                onFinish={onFinish}
                scrollToFirstError
            >

                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter branch name.', whitespace: true, }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Incharge"
                    name="incharge"
                    rules={[
                        { required: true, message: 'Please enter incharge name.' , whitespace: true},
                        {
                            pattern: new RegExp(Pattern.ALPHABET_SPACE_DOT),
                            message: "Please enter valid incharge name."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Contact Number"
                    name="contact_number"
                    rules={[
                        { required: true, message: 'Please enter contact number.' },
                        {
                            pattern: new RegExp(Pattern.PHONE_NUMBER),
                            message: "Please enter valid contact number."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Alternate Contact Number"
                    name="alternate_contact_number"
                    rules={[
                        { required: false, message: 'Please enter contact number.' },
                        {
                            pattern: new RegExp(Pattern.PHONE_NUMBER),
                            message: "Please enter valid contact number."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                        { required: false, message: 'Please input your address.' },
                    ]} >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Pin code"
                    name="pin_code"
                    rules={[
                        { required: false, message: 'Please enter pin code.' },
                        {
                            pattern: new RegExp(Pattern.PIN_CODE),
                            message: "Please enter valid pin code."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="city_id"
                    label="City"
                    rules={[{ required: true, message: 'Please select city.', whitespace: true }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {Cityoptions.map(d => <Option key={d.key}>{d.value}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Email Address"
                    name="email_address"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail.',
                        },
                        {
                            required: false,
                            message: 'Please input your E-mail.',
                        }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Opening Time"
                    name="opening_time"
                    rules={[{ required: false, message: 'Please enter opening time.' }]}
                >
                    <TimePicker use12Hours format="hh:mm A" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Closing Time"
                    name="closing_time"
                    rules={[{ required: false, message: 'Please enter closing time.' }]}
                >
                    <TimePicker use12Hours format="hh:mm A" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Remarks"
                    name="remarks"
                    rules={[{ required: false, message: 'Please enter remarks.', whitespace: true, }]}
                >
                    <TextArea rows={3} />
                </Form.Item>

                <Form.Item {...tailFormItemLayout} className="gx-text-center">
                    <Button type="primary" htmlType="submit">
                        Create
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
export default Create;