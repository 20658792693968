import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Card,
    Select,
} from 'antd';
import Pattern from '../../../../../constants/Pattern'

const { TextArea } = Input;
const { Option } = Select


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 5 },
        lg: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        span: 24,
    },
};
const Edit = (props) => {
    const { onFinish, details, alllist } = props;
    console.log("details", details, "alllist", alllist)
    const [form] = Form.useForm();

    const onReset = () => {
        form.resetFields();
    };
    let newDetails = null;
    console.log("details", details)
    if (details && alllist && alllist.list) {
        newDetails = { ...details };
        if (alllist && alllist.list && newDetails.city_id) {
            alllist.list.map(item => {
                if (newDetails.city_id === item.id) {
                    newDetails.city_id = item.name
                }
            })
        }
    }
    const Cityoptions = [];
    if (alllist && alllist.list) {
        for (let i = 0; i < alllist.list.length; i++) {
            Cityoptions.push({
                key: alllist.list[i].id,
                value: `${alllist.list[i].name}, ${alllist.list[i]["State.name"]}`,
            });
        }
    }
    return (
        <Card className="gx-card" title="Customer Edit Form">
            {newDetails && newDetails.id ? <Form
                {...formItemLayout}
                form={form}
                name="customer"
                onFinish={onFinish}
                initialValues={newDetails}
                scrollToFirstError
            >
                <Form.Item
                    label="Name"
                    name="name"

                    rules={[
                        { required: true, message: 'Please enter name.', whitespace: true },
                        {
                            pattern: new RegExp(Pattern.FULLNAME),
                            message: "Please enter valid name."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Address"
                    name="address"

                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="City"
                    name="city_id"
                    rules={[{ required: true, message: 'Please select city.'}]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {Cityoptions.map(d => <Option key={d.key}>{d.value}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Pin Code"
                    name="pinCode"
                    rules={[
                        { required: false, message: 'Please enter pin code.', whitespace: true },
                        {
                            pattern: new RegExp(Pattern.PIN_CODE),
                            message: "Please enter valid pin code."
                        },
                    ]}
                >
                    <Input min="0" style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    label="Contact Name"
                    name="contactName"

                    rules={[
                        {
                            pattern: new RegExp(Pattern.FULLNAME),
                            message: "Please enter valid contact name."
                        },
                    ]}

                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Contact Number"
                    name="contactNumber"
                    rules={[
                        { required: true, message: 'Please enter phone number.', whitespace: true },
                        {
                            pattern: new RegExp(Pattern.PHONE_NUMBER),
                            message: "Please enter valid phone number."
                        },
                    ]}
                >
                    <Input min="0" style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    label="Email Address"
                    name="emailAddress"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail.',
                        },
                        {
                            required: false,
                            message: 'Please input your E-mail.',
                        }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="PAN Number"
                    name="pan"
                    rules={[
                        { required: false, message: 'Please input your PAN number.', whitespace: true },
                        {
                            pattern: new RegExp(Pattern.PAN_NO),
                            message: "Please enter valid PAN number(ex.ALWPG5809L)."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="GST Number"
                    name="GSTNumber"
                    rules={[
                        { required: false, message: 'Please input your GST number.', whitespace: true },
                        {
                            pattern: new RegExp(Pattern.GST),
                            message: "Please enter valid GST number."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Remarks"
                    name="remarks"
                    rules={[{ required: false, message: 'Please enter remarks.', whitespace: true, }]}
                >
                    <TextArea rows={3} />
                </Form.Item>
                <Form.Item {...tailFormItemLayout} className="gx-text-center">
                    <Button type="primary" htmlType="submit">
                        Update
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                </Form.Item>
            </Form> : null}
        </Card>
    );
};
export default Edit;