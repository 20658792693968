import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    getAllCountries,
    createState,
} from "../../../../../appRedux/actions";
import Create from './create';

class CreateContainer extends React.Component {

    onFinish = values => {
        const { history, alllist: { list } } = this.props
        const handleSuccess = () => {
            message.success('State created successfully');
            history.push('/main/master/state/states');
        }
        const handleFailure = () => {
            message.error('State creation failed, please try again');
        }
        list.map( item => {
            if(item.name === values.country_id) {
                values.country_id = item.id
            }
        })
        this.props.createState(handleSuccess, handleFailure, values)
    }
    componentDidMount() {
      this.props.getAllCountries();
    }
    render() {
        const { isFetching, alllist } = this.props;
        return (
            <Create onFinish={this.onFinish} isFetching={isFetching} alllist={alllist} />
        );
    }
};

const mapStateToProps = ({ state, country }) => {
    const { alllist } = country
    const { isFetching } = state
    return { isFetching, alllist }
};
export default connect(mapStateToProps, {
    createState,
    getAllCountries
})(CreateContainer);