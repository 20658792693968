import React, { useState, useEffect } from "react";
import { Row, Col, Select } from 'antd';
import _ from 'lodash';
import Widget from "components/Widget";
import LineIndicator from "./LineIndicator";
import BubbleMap from "./BubbleMap/index";

const { Option } = Select;
const Overview = ({ alllistVehicle, monthlyRevenueVehicle, onRevenueClick }) => {
  let valueObj = { revenue: 0, target: 0 }
  const [value, setValue] = useState(valueObj);
  const [monthlyRevenueVehicleObj, setObj] = useState();
  const [valueSet, setValueDone] = useState(true);
  const [vehicle, setVechicle] = useState();
  const [vehicleType, setVechicleType] = useState('own');
  if(monthlyRevenueVehicle && monthlyRevenueVehicle.data && (valueSet || !_.isEqual(monthlyRevenueVehicleObj, monthlyRevenueVehicle)) && alllistVehicle && alllistVehicle.list) {
    monthlyRevenueVehicle.data.map(monthlyRevenueVehicleValue => {
          if(monthlyRevenueVehicleValue.vehical_id != null) {
            valueObj.revenue = parseFloat(monthlyRevenueVehicleValue.revenue) ? valueObj.revenue + parseFloat(monthlyRevenueVehicleValue.revenue) : valueObj.revenue;
          }
    })
    alllistVehicle.list.map(alllistVehicleValue => {
      valueObj.target = valueObj.target + 300000;
    })
    setValueDone(false);
    setValue(valueObj);
    setObj(monthlyRevenueVehicle);
    setVechicle('all');
    setVechicleType('own');
  }
  let options = [];
  if(alllistVehicle && alllistVehicle.list) {
    options = alllistVehicle.list.map(alllistVehicleObj => {
      return <Option value={alllistVehicleObj.id}>{alllistVehicleObj.vehicleNumber}</Option>
    })
  }
  const onChange = (event) => {
    console.log("event>>>", event);
    if(event == 'own') {
      event = event == 'own' ? 'all' : event;
      setVechicle(event);
      setVechicleType('own');
      if(event == 'all') {
        monthlyRevenueVehicle.data.map(monthlyRevenueVehicleValue => {
          if(monthlyRevenueVehicleValue.vehical_id != null) {
            valueObj.revenue = parseFloat(monthlyRevenueVehicleValue.revenue) ? valueObj.revenue + parseFloat(monthlyRevenueVehicleValue.revenue) : valueObj.revenue;
          }
        })
        alllistVehicle.list.map(alllistVehicleValue => {
          valueObj.target = valueObj.target + 300000;
        })
        setValue(valueObj);
      } else {
        const selectVehicleData = _.find(monthlyRevenueVehicle.data, ['vehical_id', event])
        if(selectVehicleData) {
          setValue({ revenue: selectVehicleData.revenue ? selectVehicleData.revenue : 0, target: selectVehicleData.target });
        } else {
          setValue({ revenue: 0, target: 300000 })
        }
      }
    } else {
        const selectVehicleData = _.find(monthlyRevenueVehicle.data, ['vehical_id', null])
        if(selectVehicleData) {
          setValue({ revenue: selectVehicleData.revenue ? selectVehicleData.revenue : 0, target: selectVehicleData.target });
        } else {
          setValue({ revenue: 0, target: 300000 })
        }
        setVechicleType('hire');
    }
  }
  const onChangeOwn = (event) => {
    setVechicle(event);
    if(event == 'all') {
      monthlyRevenueVehicle.data.map(monthlyRevenueVehicleValue => {
        if(monthlyRevenueVehicleValue.vehical_id != null) {
          valueObj.revenue = parseFloat(monthlyRevenueVehicleValue.revenue) ? valueObj.revenue + parseFloat(monthlyRevenueVehicleValue.revenue) : valueObj.revenue;
        }
      })
      alllistVehicle.list.map(alllistVehicleValue => {
        valueObj.target = valueObj.target + 300000;
      })
      setValue(valueObj);
    } else {
      const selectVehicleData = _.find(monthlyRevenueVehicle.data, ['vehical_id', event])
      if(selectVehicleData) {
        setValue({ revenue: selectVehicleData.revenue ? selectVehicleData.revenue : 0, target: selectVehicleData.target });
      } else {
        setValue({ revenue: 0, target: 300000 })
      }
    }
  }
  options.unshift(<Option value={'all'}>ALL</Option>)
  return (
    <Widget title={
      <h2 className="h4 gx-text-capitalize gx-mb-0">Vehicle Revenue</h2>
    } styleName="gx-text-center">
      <div className="gx-py-1">
      <Row>
        <Col span={8}></Col>
        <Col span={16}>
          <Select onChange={(event) => onRevenueClick(event)} defaultValue="month" style={{ width: '100%'}}>
            <Option value="day">Today</Option>
            <Option value="week">Current week</Option>
            <Option value="month">Current month</Option>
            <Option value="year">Current year</Option>
          </Select>
        </Col>
      </Row>
        <div className="">
          <div className="gx-overview-row">
            <div className="gx-overview-description">
              <div className="gx-revenu gx-revenu-total total-border">
                <h1>{value.revenue}</h1>
                <span className="gx-fs-md">Total Revenue</span>
              </div>

              {vehicleType == 'own' ? <div className="gx-revenu">
                <div className="gx-revenu-row">
                  <div className="gx-revenu gx-revenu-total">
                    <h1>{value.target}</h1>
                    <span className="gx-fs-md">Target Revenue</span>
                  </div>
                </div>
              </div> : null }
            </div>
          </div>
        </div>
        <Row>
          <Col span={8}>
            <Select value={vehicleType} onChange={(event) => onChange(event)} defaultValue="own" style={{ width: '100%'}}>
              <Option value="own">Own</Option>
              <Option value="hire">Hire</Option>
            </Select>
          </Col>
          {vehicleType == 'own' ? <Col span={16}>
            <Select value={vehicle} onChange={(event) => onChangeOwn(event)} defaultValue="all" style={{ width: '100%' }}>
              {options}
            </Select>
          </Col> : <Col span={16}></Col> }
        </Row>
      </div>
    </Widget>

  );
};

export default Overview;
