import React from 'react';
import {
    Card,
    Descriptions,
    Table
} from 'antd';
import { connect } from "react-redux";
import {
    getLrDetails,
    reset
} from "../../../../appRedux/actions";
import moment from 'moment';

class Country extends React.Component {
    state = {
      tableConfig: {
      },
      page: 1,
      pageSize: 20
    };

  columns = [
    {
      title: 'ID',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (value, item, index) => <span className="gx-link">{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
      
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNo',
      key: 'number',
      render: text => <span className="gx-link">{text}</span>,
      
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: text => <span className="gx-link">{text}</span>,
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
      render: text => <span className="gx-link">{text}</span>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: text => <span className="gx-link">{text}</span>,
    },
    {
      title: 'E-Way Bill',
      dataIndex: 'eway',
      key: 'eway',
      render: text => <span className="gx-link">{text}</span>,
    },
    {
      title: 'Validity',
      dataIndex: 'validity',
      key: 'validity',
      render: text => <span className="gx-link">{moment(text).format('DD-MM-YYYY')}</span>,
    }];
    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getLrDetails(id);
    }
    componentWillUnmount() {
        this.props.reset();
    }

    setPage = (page, pageSize) => {
      this.setState({page, pageSize})
    }

    render() {
        const { pageSize } = this.state;
        const { isFetching, details } = this.props;
        let newDetails=null;
        if(details) {
            newDetails = {...details};
            if(details.date){
                newDetails.date = moment(details.date).format('DD/MM/YYYY')
            } 
        }
        return (
            <Card className="gx-card" title="LR Details Information">
                  <Descriptions title="">
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Location Type">{newDetails && newDetails.locationType ? newDetails.locationType: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="LR Vehicle Type">{newDetails && newDetails.lrVehicleType ? newDetails.lrVehicleType: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="LR Type">{newDetails && newDetails.lrType ? newDetails.lrType: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="LR Number">{newDetails && newDetails.number ? newDetails.number: ''}</Descriptions.Item>
                    {newDetails && newDetails.locationType && newDetails.locationType == 'BR' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Branch Name">{newDetails && newDetails['Branch.name'] ? newDetails['Branch.name']: ''}</Descriptions.Item> : null }
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Date">{newDetails && newDetails.date && newDetails.date ? newDetails.date: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Booking Type">{newDetails && newDetails.bookingType && newDetails.bookingType ? newDetails.bookingType: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Weight(KG)">{newDetails && newDetails.weight ? newDetails.weight: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Package Number">{newDetails && newDetails.packageNumber ? newDetails.packageNumber: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Freight Amount">{newDetails && newDetails.amount ? newDetails.amount: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Origin State">{newDetails ? newDetails['origin_state.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Origin City">{newDetails ? newDetails['origin_city.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Destination State">{newDetails ? newDetails['destination_state.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Destination City">{newDetails && newDetails['destination_city.name']  ? newDetails['destination_city.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignor Name">{newDetails  ? newDetails['consignor.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignor Address">{newDetails ? newDetails['consignor.address']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignor Contact Number">{newDetails ? newDetails['consignor.contact_number']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignee Name">{newDetails ? newDetails['consignee.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignee Address">{newDetails  ? newDetails['consignee.address']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignee Contact Number">{newDetails ? newDetails['consignee.contact_number']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Additional Delivery Point Address">{newDetails ? newDetails['additionalAddress']: ''}</Descriptions.Item>
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Own' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Vehicle Number">{newDetails ? newDetails['Vehical.vehicleNumber']: ''}</Descriptions.Item> : null }
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Own' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Driver Name">{newDetails? `${newDetails['Driver.first_name']} ${newDetails['Driver.last_name']}`: ''}</Descriptions.Item> : null }
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Hire' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Vehicle Number">{newDetails ? newDetails['vehicleNumber']: ''}</Descriptions.Item> : null }
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Hire' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Driver name">{newDetails ? newDetails['driverName']: ''}</Descriptions.Item> : null }
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Hire' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Driver Number">{newDetails ? newDetails['contactNumber']: ''}</Descriptions.Item> : null }
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Insurance of Goods">{newDetails && newDetails.insuranceOfGoods && newDetails.insuranceOfGoods ? newDetails.insuranceOfGoods: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Mode of Payment">{newDetails && newDetails.modeOfPayment && newDetails.modeOfPayment ? newDetails.modeOfPayment: ''}</Descriptions.Item>
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Own' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Driver Number">{newDetails ? newDetails['Driver.contactNumber']: ''}</Descriptions.Item> : null }
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Status">{newDetails ? newDetails['Deliverystatus.status']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Broker Name">{newDetails  ? newDetails['Broker.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Broker Address">{newDetails  ? newDetails['Broker.address']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Broker Contact">{newDetails  ? newDetails['Broker.contactNumber']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="E-Way Bill">{newDetails && newDetails.eway ? newDetails.eway: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Remarks">{newDetails && newDetails.remarks ? newDetails.remarks: ''}</Descriptions.Item>
                </Descriptions>
                <Table
                    pagination={{
                    onChange(current, pageSize) {
                        this.setPage(current, pageSize);
                    },
                    pageSize
                    }}
                bordered={true} size={'small'} className="gx-table-responsive" loading={isFetching} columns={this.columns} dataSource={newDetails.invoices} />
            </Card>
        );
    }
};

const mapStateToProps = ({ lrdetails }) => {
    const { isFetching, details } = lrdetails
    return { isFetching, details }
};
export default connect(mapStateToProps, {
    getLrDetails,
    reset
})(Country);