import Constants from "constants/Transit";
import { API_DOMAIN } from '../../env';

export const getAllTransits = () => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_TRANSIT_REQUEST,
    });
    return fetch(`${API_DOMAIN}/transits`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_TRANSIT_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_TRANSIT_SUCCESS,
                    payload: body,
                });
            }
        });
};

export const getTransitDetails = (id) => function (dispatch) {
    dispatch({
      type: Constants.GET_TRANSIT_DETAILS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/transit/details/${id}`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_TRANSIT_DETAILS_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_TRANSIT_DETAILS_SUCCESS,
            payload: body,
          });
        }
      });
  };

  export const getLRTransitDetails = (id, handleSuccess) => function (dispatch) {
      dispatch({
        type: Constants.GET_LR_TRANSIT_DETAILS_REQUEST,
      });
      return fetch(`${API_DOMAIN}/transit/lr/${id}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: Constants.GET_LR_TRANSIT_DETAILS_FAIL,
              payload: body.error,
            });
          } else {
            dispatch({
              type: Constants.GET_LR_TRANSIT_DETAILS_SUCCESS,
              payload: body,
            });
            if(handleSuccess) handleSuccess(body)
          }
        });
    };


export const createTransit = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.CREATE_TRANSIT_REQUEST
        });
        fetch(`${API_DOMAIN}/transit/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.CREATE_TRANSIT_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.CREATE_TRANSIT_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

export const getAllDeliveryStatus = () => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_DELIVERY_STATUS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/deliverystatus`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_DELIVERY_STATUS_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_DELIVERY_STATUS_SUCCESS,
                    payload: body,
                });
            }
        });
};

export const reset = () => function (dispatch) {
  dispatch({
    type: Constants.RESET_DETAILS,
  });
};