import Constants from "constants/Trip";

const INITIAL_STATE = {
  data: [],
  alllist: [],
  tripuploadlist: [],
  alllisturl: {},
  details: {},
  deliveryStatuses: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
  tripLrdetails: {},
  tripDieseldetails: {},
  tripAdvancedetails: {},
  tripPreview: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Constants.GET_ALL_TRIP_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_TRIP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_TRIP_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_TRIP_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_TRIP_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_TRIP_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.GET_TRIP_DETAILS_LR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_TRIP_DETAILS_LR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tripLrdetails: action.payload,
      };
    case Constants.GET_TRIP_DETAILS_LR_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.CREATE_TRIP_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.CREATE_TRIP_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.CREATE_TRIP_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.DELETE_TRIP_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case Constants.DELETE_TRIP_SUCCESS:
      return {
        ...state,
        countryDelete: action.payload,
        isFetching: false
      }
    case Constants.DELETE_TRIP_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }

    case Constants.UPDATE_TRIP_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.UPDATE_TRIP_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.UPDATE_TRIP_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.CREATE_TRIP_DIESEL_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.CREATE_TRIP_DIESEL_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.CREATE_TRIP_DIESEL_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.CREATE_TRIP_ADVANCE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.CREATE_TRIP_ADVANCE_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.CREATE_TRIP_ADVANCE_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.GET_TRIP_DIESEL_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.GET_TRIP_DIESEL_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.GET_TRIP_DIESEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tripDieseldetails: action.payload,
      };
    case Constants.GET_TRIP_ADVANCE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.GET_TRIP_ADVANCE_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.GET_TRIP_ADVANCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tripAdvancedetails: action.payload,
      };
    
      case Constants.DELETE_TRIP_DIESEL_REQUEST:
        return {
          ...state,
          isFetching: true
        };
      case Constants.DELETE_TRIP_DIESEL_ERROR:
        return {
          ...state,
          isFetching: false
        };
      case Constants.DELETE_TRIP_DIESEL_SUCCESS:
        return {
          ...state,
          isFetching: false,
        };
      case Constants.DELETE_TRIP_ADVANCE_REQUEST:
        return {
          ...state,
          isFetching: true
        };
      case Constants.DELETE_TRIP_ADVANCE_ERROR:
        return {
          ...state,
          isFetching: false
        };
      case Constants.DELETE_TRIP_ADVANCE_SUCCESS:
        return {
          ...state,
          isFetching: false
        };
      case Constants.CREATE_TRIP_URL_REQUEST:
        return {
          ...state,
          isFetching: true
        };
      case Constants.CREATE_TRIP_URL_ERROR:
        return {
          ...state,
          isFetching: false
        };
      case Constants.CREATE_TRIP_URL_SUCCESS:
        return {
          ...state,
          isFetching: false
        };
      case Constants.GET_ALL_TRIP_URL_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case Constants.GET_ALL_TRIP_URL_SUCCESS:
        return {
          ...state,
          isFetching: false,
          alllisturl: action.payload,
        };
      case Constants.GET_ALL_TRIP_URL_FAIL:
        return {
          ...state,
          isFetching: false,
          listError: action.error,
        };
      case Constants.DELETE_TRIPURL_REQUEST:
        return {
          ...state,
          isFetching: true
        }
      case Constants.DELETE_TRIPURL_SUCCESS:
        return {
          ...state,
          isFetching: false
        }
      case Constants.GET_TRIP_URL_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case Constants.GET_TRIP_URL_SUCCESS:
        return {
          ...state,
          isFetching: false,
          tripPreview: action.payload,
        };
      case Constants.GET_TRIP_URL_FAIL:
        return {
          ...state,
          isFetching: false,
          tripPreview: null
        };
      case Constants.GET_ALL_TRIP_UPLOAD_REQUEST:
        return {
          ...state,
          isFetching: true,
          tripuploadlist: []
        };
      case Constants.GET_ALL_TRIP_UPLOAD_SUCCESS:
        return {
          ...state,
          isFetching: false,
          tripuploadlist: action.payload.list,
        };
      case Constants.GET_ALL_TRIP_UPLOAD_FAIL:
        return {
          ...state,
          isFetching: false,
          listError: action.error,
        };
      case Constants.DELETE_TRIP_UPLOADS_REQUEST:
        return {
          ...state,
          isFetching: true
        }
      case Constants.DELETE_TRIP_UPLOADS_SUCCESS:
        return {
          ...state,
          isFetching: false
        }
      case Constants.DELETE_TRIP_UPLOADS_FAIL:
        return {
          ...state,
          error: action.payload,
          isFetching: false
        }
    default:
      return state;
  }
};
