const data = [
  {name: 'Jan', Target: 3000000, Revenue: 2400000, amt: 2400},
  {name: 'Feb', Target: 3000000, Revenue: 1398000, amt: 2210},
  {name: 'Mar', Target: 3000000, Revenue: 2800000, amt: 2290},
  {name: 'Apr', Target: 3000000, Revenue: 3908000, amt: 2000},
  {name: 'May', Target: 3000000, Revenue: 4800000, amt: 2181},
  {name: 'Jun', Target: 3000000, Revenue: 3800000, amt: 2500},
  {name: 'Jul', Target: 3000000, Revenue: 4300000, amt: 2100},
  {name: 'Aug', Target: 3000000, Revenue: 4300000, amt: 2100},
  {name: 'Sep', Target: 3000000, Revenue: 4300000, amt: 2100},
  {name: 'Oct', Target: 3000000, Revenue: 4300000, amt: 2100},
  {name: 'Nov', Target: 3000000, Revenue: 4300000, amt: 2100},
  {name: 'Dec', Target: 3000000, Revenue: 4300000, amt: 2100},
];
export default data;
