import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    updateUser,
    getUserDetails,
    getAllRoles,
    reset
} from "../../../../../appRedux/actions";
import Edit from './edit';

class EditContainer extends React.Component {

    onFinish = values => {
        const { match: { params: { id } }, history, roles: { list } } = this.props
        const handleSuccess = () => {
            message.success('User updated successfully');
            history.push('/main/settings/user/users');
        }
        const handleFailure = () => {
            message.error('User updation failed, please try again');
        }
        values.id = id;
        list.map( item => {
            if(item.name === values.role_id) {
                values.role_id = item.id
            }
        })
        this.props.updateUser(handleSuccess, handleFailure, values)
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getUserDetails(id);
        this.props.getAllRoles();
    }
    componentWillUnmount() {
        this.props.reset()
    }

    render() {
        const { isFetching, details, roles } = this.props;
        return (
            <Edit onFinish={this.onFinish} isFetching={isFetching} details={details} roles={roles} />
        );
    }
};

const mapStateToProps = ({ user }) => {
    const { isFetching, details, roles } = user
    return { isFetching, details, roles }
};
export default connect(mapStateToProps, {
    updateUser,
    getUserDetails,
    getAllRoles,
    reset
})(EditContainer);
