import Constants from '../../constants/cities';

const INITIAL_STATE = {
  data: [],
  alllist: [],
  details: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {    
    case Constants.GET_ALL_CITIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_CITIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_CITIES_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_CITY_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_CITY_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_CITY_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.CITY_REGISTER_REQUEST:
        return { 
            ...state, 
            isFetching: true 
        };
    case Constants.CITY_REGISTER_ERROR:
        return {
              ...state,
              isFetching: false 
        };
    case Constants.CITY_REGISTER_SUCCESS:
        return {
              ...state, 
              isFetching: false 
        };
    case Constants.DELETE_CITY_REQUEST:
        return {
            ...state,
            isFetching: true
        }
    case Constants.DELETE_CITY_SUCCESS:
        return {
            ...state,
            CityDelete: action.payload,
            isFetching: false
        }
    case Constants.DELETE_CITY_FAIL:
        return {
            ...state,
            error: action.payload,
            isFetching: false
        }
        case Constants.CITY_UPDATE_REQUEST:
          return {
              ...state,
              isFetching: true
          }
      case Constants.CITY_UPDATE_SUCCESS:
          return {
              ...state,
              // stateDelete: action.payload,
              isFetching: false
          }
      case Constants.CITY_UPDATE_ERROR:
          return {
              ...state,
              error: action.payload,
              isFetching: false
          }
      case Constants.RESET_DETAILS:
      return {
        ...state,
        details: {},
      };
    case 'SIMPLE_ACTION':
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};
