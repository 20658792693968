import React from "react";
import { Button, Card, Row, Col, Table, Tooltip, Divider, Popconfirm, message, Input } from "antd";
import { SnippetsFilled, EditFilled, DeleteFilled, ReloadOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import { SearchOutlined } from '@ant-design/icons';
import { Space } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllVehicles,
  deleteVehicle,
  getAllVehicaltypes,
  getAllFueltanks,
  getAllPurchaseagencys,updateVehicle
} from "../../../../../appRedux/actions";
import moment from "moment";
import DataExport from "../../../../components/feedback/Export/DataExport";
import { hasActionPermission } from "../../../../../util/rolesHelper";
import { ACTION_CREATE, ACTION_DELETE, ACTION_EDIT, ACTION_VIEW, FEATURE_VEHICLE } from "../../../../../constants/Roles";

class Vehicles extends React.Component {
  state = {
    tableConfig: {
    },
    page: 1,
    pageSize: 20,
    dataExportVisible: false
  };

  componentDidMount() {
    this.props.getAllVehicles(true);
    this.props.getAllVehicaltypes();
    this.props.getAllFueltanks();
    this.props.getAllPurchaseagencys();
  }
  confirm = (id) => {
    console.log("id", id)
    const { history } = this.props
    const handleSuccess = () => {
      message.success('Vehicle deleted successfully');
      this.props.getAllVehicles(true);
    }
    const handleFailure = () => {
      message.error('Vehicle deletion failed, please try again');
    }
    this.props.deleteVehicle(handleSuccess, id)
  }
  cancel = () => {

  }
  reactivate = (id) => {
    const data = {
      id:id,
      deleted_at:null,
      deletedAt:null
    }
    const handleSuccess = () => {
      message.success('Vehicle details reactivated successfully');
      this.props.getAllVehicles(true);
    }
    const handleFailure = () => {
      message.error('Vehicle details reactivation failed, please try again');
    }
    this.props.updateVehicle(handleSuccess, handleFailure, data)
  }

  setPage = (page, pageSize) => {
    this.setState({page, pageSize})
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  dataExportModel = (dataExportVisible) => {
    this.setState({dataExportVisible})
  }
  render() {
    const { setPage } = this;
    const { tableConfig, pageSize, dataExportVisible } = this.state;
    const { isFetching, alllist: { list },authUser } = this.props;
    let userAccess = [];
    if(authUser && authUser.details && authUser.details.Role && authUser.details.Role) {
      userAccess = JSON.parse(authUser.details.Role.access);
    }
    const 
    columns = [
      {
        title: 'ID',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        render: (value, item, index) => <span className="gx-link">{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
        
      },
      {
        title: 'Vehicle Number',
        dataIndex: 'vehicleNumber',
        key: 'vehicleNumber',
        render: text => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("vehicleNumber"),
        sorter: (a, b) => a["vehicleNumber"].localeCompare(b["vehicleNumber"]),
      },
      {
        title: 'RC Date',
        dataIndex: 'RCDate',
        key: 'RCDate',
        render: text => <span className="gx-link">{moment(text).format('DD-MM-YYYY')}</span>,
        sorter: (a, b) => a["RCDate"] ? a["RCDate"].localeCompare(b["RCDate"]) : a["RCDate"],
      },
      {
        title: 'Fuel Type',
        dataIndex: 'fuelType',
        key: 'fuelType',
        render: text => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("fuelType"),
        sorter: (a, b) => a["fuelType"].localeCompare(b["fuelType"]),
      },
      {
        title: 'Fuel Tank Capacity',
        dataIndex: 'Fueltank.capacity',
        key: 'Fueltank.capacity',
        render: text => <span className="gx-link">{text}</span>,
        sorter: (a, b) => a['Fueltank.capacity'] - b['Fueltank.capacity'],
        ...this.getColumnSearchProps("Fueltank.capacity"),
      },
      {
        title: 'Vehicle Type',
        dataIndex: 'Vehicaltype.vehicalType',
        key: 'Vehicaltype.vehicalType',
        render: text => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("Vehicaltype.vehicalType"),
        sorter: (a, b) => a["Vehicaltype.vehicalType"].localeCompare(b["Vehicaltype.vehicalType"]),
      },
      {
        title: 'Action',
        key: 'action',
        width: 250,
        fixed: 'right',
        render: (text, record) => {
          const { authUser } = this.props;
  
          let userAccess = [];
          if(authUser && authUser.details && authUser.details.Role && authUser.details.Role) {
            userAccess = JSON.parse(authUser.details.Role.access);
          }
          return(
          <span>
             {  hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_DELETE)  && record.deleted_at ?'':  <>
            { hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_VIEW) ?   <Tooltip title="View">
              <Link to={`/main/master/vehicle/view/${record.id}`}>
                <Button className="gx-margin-none" type="text" size={"small"} icon={<SnippetsFilled />} />
              </Link>
            </Tooltip>:null}
            <Divider type="vertical" />
            { hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_EDIT) ?   <Tooltip title="Edit">
              <Link to={`/main/master/vehicle/edit/${record.id}`}>
                <Button className="gx-margin-none" type="text" size={"small"} icon={<EditFilled />} />
              </Link>
            </Tooltip>:null}
            <Divider type="vertical" />
            { hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_DELETE)  && !record.deleted_at  ?   <Tooltip title="Delete">
              <Popconfirm title="Are you sure you want to delete this vehicle?" onConfirm={() => this.confirm(record.id)} onCancel={() => this.cancel()} okText="Yes"
                cancelText="No">
                <Button className="gx-margin-none" type="text" size={"small"} icon={<DeleteFilled />} />
              </Popconfirm>
            </Tooltip>:null}
            </>}
            { hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_DELETE)  && record.deleted_at ? <Tooltip title="Reactivate">
                <Popconfirm title="Are you sure you want to reactivate this vehicle Details?" onConfirm={() => this.reactivate(record.id)} onCancel={() => this.cancel()} okText="Yes"
                  cancelText="No">
                  <Button className="gx-margin-none" type="text" size={"small"} icon={<ReloadOutlined />} />
                </Popconfirm>
              </Tooltip> : null}
          </span>
        )}
      }];
    return (
      <Card title="Vehicles">
        {dataExportVisible ? <DataExport
          dataExportModel={this.dataExportModel}
          visible={dataExportVisible}
          columns={columns}
          data={list}
          fileName={`Vehicles_${Date()}`}
        /> : null}
        <Row>
          <Col span={24}>
          { hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_CREATE) ?    <Link to="/main/master/vehicle/create">
              <Button
                type="primary"
                className="gx-float-right"
              >
                Add Vehicle
              </Button>
            </Link>:null}
            <Button
              type="primary"
              className="gx-float-right"
              style={{ marginRight: "10px" }}
              onClick={() => this.dataExportModel(true)}
            >
              Export Data
            </Button>
          </Col>
        </Row>
        { hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_VIEW) ?     <Table
            pagination={{
              onChange(current, pageSize) {
                setPage(current, pageSize);
              },
              pageSize
            }}
        bordered={true} size={'small'} className="gx-table-responsive" loading={isFetching} columns={columns} dataSource={list} />:null}
      </Card>
    );
  }
}

const mapStateToProps = ({ vehicle ,user}) => {
  const { alllist, isFetching } = vehicle
  const { authUser } = user

  return { alllist, isFetching,authUser}
};
export default connect(mapStateToProps, {
  getAllVehicles,
  deleteVehicle,
  getAllVehicaltypes,
  getAllFueltanks,
  getAllPurchaseagencys,updateVehicle
})(Vehicles);