import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_STATES_REQUEST',
  'GET_ALL_STATES_SUCCESS',
  'GET_ALL_STATES_FAIL',
  'GET_STATE_DETAILS_REQUEST',
  'GET_STATE_DETAILS_SUCCESS',
  'GET_STATE_DETAILS_FAIL',
  'STATE_REGISTER_REQUEST',
  'STATE_REGISTER_ERROR',
  'STATE_REGISTER_SUCCESS',
  'DELETE_STATE_REQUEST',
  'DELETE_STATE_SUCCESS',
  'DELETE_STATE_FAIL',
  'STATE_UPDATE_REQUEST',
  'STATE_UPDATE_ERROR',
  'STATE_UPDATE_SUCCESS',
  'RESET_DETAILS'
]);
