import Constants from '../../constants/Vehicle';
import { API_DOMAIN } from '../../env';

export const getAllVehicles = (paranoid) => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_VEHICLES_REQUEST,
  });
  return fetch(`${API_DOMAIN}/vehicals?paranoid=${paranoid}`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_VEHICLES_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_VEHICLES_SUCCESS,
          payload: body,
        });
      }
    });
};

export const createVehicle = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.VEHICLE_REGISTER_REQUEST
      });
      fetch(`${API_DOMAIN}/vehical/create`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.VEHICLE_REGISTER_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  dispatch({
                      type: Constants.VEHICLE_REGISTER_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}

export const getVehicleDetails = (id) => function (dispatch) {
  dispatch({
    type: Constants.GET_VEHICLE_DETAILS_REQUEST,
  });
  return fetch(`${API_DOMAIN}/vehical/details/${id}`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_VEHICLE_DETAILS_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_VEHICLE_DETAILS_SUCCESS,
          payload: body,
        });
      }
    });
};

export const deleteVehicle = (handleSuccess,id) =>{
  console.log("id",id)
  return function (dispatch) {
      dispatch({
          type: Constants.DELETE_VEHICLE_REQUEST
      });
      return fetch(`${API_DOMAIN}/vehical/delete/${id}`,{
          method: "DELETE", 
          mode: "cors", 
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
              "Content-Type": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow",
          referrer: "no-referrer",
      }).then(() => {
                  dispatch({
                      type: Constants.DELETE_VEHICLE_SUCCESS,
                      payload:''
                  });
                  handleSuccess();
          });
  }
};

export const updateVehicle = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.VEHICLE_UPDATE_REQUEST
      });
      fetch(`${API_DOMAIN}/vehical/update/${data.id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json'
          }
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.VEHICLE_UPDATE_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  dispatch({
                      type: Constants.VEHICLE_UPDATE_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}

export const getAllPurchaseagencys = () => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_PURCHASEAGENCYS_REQUEST,
  });
  return fetch(`${API_DOMAIN}/purchaseagencys`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_PURCHASEAGENCYS_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_PURCHASEAGENCYS_SUCCESS,
          payload: body,
        });
      }
    });
};



export const getAllFueltanks = () => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_FUELTANKS_REQUEST,
  });
  return fetch(`${API_DOMAIN}/fueltanks`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_FUELTANKS_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_FUELTANKS_SUCCESS,
          payload: body,
        });
      }
    });
};

export const getAllVehicaltypes = () => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_VEHICLETYPES_REQUEST,
  });
  return fetch(`${API_DOMAIN}/vehicaltypes`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_VEHICLETYPES_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_VEHICLETYPES_SUCCESS,
          payload: body,
        });
      }
    });
};
export const reset = () => function (dispatch) {
  dispatch({
    type: Constants.RESET_DETAILS,
  });
};
