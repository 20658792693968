import React from "react";
import {
  Radio,
  Button,
  Card,
  Row,
  Col,
  Tag,
  Table,
  Tooltip,
  Divider,
  Popconfirm,
  message,
  Select,
  Modal,
  Form,
  Input,
  DatePicker,
} from "antd";
import { SnippetsFilled, EditFilled, DeleteFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Pattern from "../../../../constants/Pattern";
import moment from "moment";
import _ from "lodash";
import {
  getAllLrDetails,
  getAllCustomers,
  createInvoice,
  clearAllLrDetails,
  deleteInvoice,
  createInvoicePreview,
  deleteInvoicePreview
} from "../../../../appRedux/actions";
import * as invoiceutil from "../../../../preview/invoice";
const { Option } = Select;
const { TextArea } = Input;

const finacialYear = [
  {
    key: "19-20",
    value: "19-20",
  },
  {
    key: "20-21",
    value: "20-21",
  },
  {
    key: "21-22",
    value: "21-22",
  },
  {
    key: "22-23",
    value: "22-23",
  },
  {
    key: "23-24",
    value: "23-24",
  },
  {
    key: "24-25",
    value: "24-25",
  },
];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 10 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 12 },
    lg: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    span: 24,
  },
};
class LrDetails extends React.Component {
  state = {
    tableConfig: {},
    selectedRowKeys: [],
    selectedFinacialYear: "24-25",
    customerId: 0,
    visible: false,
    invoiceData: [],
    page: 1,
    pageSize: 20,
    billParty: "consignor",
    invoiceDate: moment(),
    type: "RCM",
    tax: "5",
    isPreview: false,
  };
  columns = [
    {
      title: "ID",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) => (
        <span className="gx-link">
          {(this.state.page - 1) * this.state.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "LR Number",
      dataIndex: "number",
      key: "number",
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => (
        <span className="gx-link">{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Consignee Name",
      dataIndex: "consignee.name",
      key: "consignee.name",
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Consignor Name",
      dataIndex: "consignor.name",
      key: "consignor.name",
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Destination",
      dataIndex: "destination_city.name",
      key: "destination_city.name",
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "Deliverystatus.status",
      key: "Deliverystatus.status",
      render: (text) => <Tag color="#2db7f5">{text}</Tag>,
    },
  ];

  componentDidMount() {
    this.props.getAllCustomers();
    this.props.clearAllLrDetails();
  }
  confirm = (id) => {
    const {
      customerId,
      invoiceData,
      selectedRowKeys,
      selectedFinacialYear,
      invoiceDate,
      type,
      tax,
    } = this.state;
    const handleSuccess = () => {
      console.log("handle success");
      this.setState({ visible: false, isPreview: true });
    };
    const handleFailure = () => {
      message.error("Invoice creation failed, please try again");
    };
    let data = {};
    if (invoiceData && invoiceData.length) {
      data = {
        invoiceDate: invoiceDate,
        customerId: customerId,
        sacCode: "9967",
        placeOfSupply: 168,
        stateCode: 27,
        lr_ids: invoiceData,
        finacialYear: selectedFinacialYear,
        type: type,
        tax: type === "RCM" ? "" : tax,
      };
    } else {
      const {
        alllist: { list },
      } = this.props;
      const invoiceDataNew = selectedRowKeys.map((selectedRowKey) => {
        let lrObj = _.find(list, ["id", selectedRowKey]);
        return {
          freight: lrObj.amount ? lrObj.amount : 0,
          detention: "0",
          expressCharge: "0",
          loading: "0",
          lr_id: selectedRowKey,
          other: "0",
          pointDelivery: "0",
          unloading: "0",
        };
      });
      data = {
        invoiceDate: invoiceDate,
        customerId: customerId,
        sacCode: "9967",
        placeOfSupply: 168,
        stateCode: 27,
        lr_ids: invoiceDataNew,
        finacialYear: selectedFinacialYear,
      };
    }
    this.props.createInvoicePreview(handleSuccess, handleFailure, data);
  };
  onConfirm = (id) => {
    const {
      customerId,
      invoiceData,
      selectedRowKeys,
      selectedFinacialYear,
      invoiceDate,
      type,
      tax,
    } = this.state;
    const handleSuccess = () => {
      const { history } = this.props;
      message.success("Invoice created successfully");
      this.setState({ visible: false, invoiceData: [], isPreview: false });
      history.push("/main/invoice/invoices");
    };
    const handleFailure = () => {
      message.error("Invoice creation failed, please try again");
    };
    let data = {};
    if (invoiceData && invoiceData.length) {
      data = {
        invoiceDate: invoiceDate,
        customerId: customerId,
        sacCode: "9967",
        placeOfSupply: 168,
        stateCode: 27,
        lr_ids: invoiceData,
        finacialYear: selectedFinacialYear,
        type: type,
        tax: type === "RCM" ? "" : tax,
      };
    } else {
      const {
        alllist: { list },
      } = this.props;
      const invoiceDataNew = selectedRowKeys.map((selectedRowKey) => {
        let lrObj = _.find(list, ["id", selectedRowKey]);
        return {
          freight: lrObj.amount ? lrObj.amount : 0,
          detention: "0",
          expressCharge: "0",
          loading: "0",
          lr_id: selectedRowKey,
          other: "0",
          pointDelivery: "0",
          unloading: "0",
        };
      });
      data = {
        invoiceDate: invoiceDate,
        customerId: customerId,
        sacCode: "9967",
        placeOfSupply: 168,
        stateCode: 27,
        lr_ids: invoiceDataNew,
        finacialYear: selectedFinacialYear,
      };
    }
    this.props.createInvoice(handleSuccess, handleFailure, data);
  };

  handlePreviewCancel = () => {
    const { invoicePreviewData } = this.props;
    this.props.deleteInvoicePreview(() => {}, invoicePreviewData.id);
    this.setState({ isPreview: false });
  };

  customerSelect(value) {
    const { billParty } = this.state;
    this.setState({ customerId: value });
    let data = {
      searchText: "",
      consignorids: [parseInt(value)],
      status: [4],
    };
    if (billParty == "consignee") {
      data = {
        searchText: "",
        consigneeids: [parseInt(value)],
        status: [4],
      };
    }
    this.props.getAllLrDetails(data);
  }

  finacialYearSelect(value) {
    this.setState({ selectedFinacialYear: value });
  }

  onSelectChange = (selectedRowKeys) => {
    const {
      alllist: { list },
    } = this.props;
    const invoiceData = selectedRowKeys.map((selectedRowKey) => {
      let lrObj = _.find(list, ["id", selectedRowKey]);
      return {
        freight: lrObj.amount ? lrObj.amount : 0,
        detention: "0",
        expressCharge: "0",
        loading: "0",
        lr_id: selectedRowKey,
        other: "0",
        pointDelivery: "0",
        unloading: "0",
      };
    });
    this.setState({ selectedRowKeys, invoiceData });
  };

  onGenerateClick = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false, invoiceData: [] });
  };

  onFieldsChange = (fields, lrId) => {
    const {
      alllist: { list },
    } = this.props;
    const { invoiceData } = this.state;
    let invoiceDataObj = _.remove(invoiceData, ["lr_id", lrId]);
    let lrObj = _.find(list, ["id", lrId]);
    invoiceDataObj =
      invoiceDataObj && invoiceDataObj.length
        ? invoiceDataObj[0]
        : { lr_id: lrId };
    invoiceDataObj.amount = lrObj.amount ? lrObj.amount : 0;
    fields.map((field) => {
      invoiceDataObj[field.name[0]] = field.value;
    });

    invoiceData.push(invoiceDataObj);
    this.setState({ invoiceData });
  };

  setPage = (page, pageSize) => {
    this.setState({ page, pageSize });
  };

  onBillPartyChange = (e) => {
    this.setState({ billParty: e.target.value }, () => {
      const { billParty, customerId } = this.state;
      if (customerId) {
        let data = {
          searchText: "",
          consignorids: [parseInt(customerId)],
          status: [4],
        };
        if (billParty == "consignee") {
          data = {
            searchText: "",
            consigneeids: [parseInt(customerId)],
            status: [4],
          };
        }
        this.props.getAllLrDetails(data);
      }
    });
  };
  onInvoiceDateChange = (invoiceDate) => {
    this.setState({ invoiceDate });
  };
  onTypeChange = (event) => {
    this.setState({ type: event.target.value });
  };
  onTaxChange = (event) => {
    this.setState({ tax: event.target.value });
  };
  render() {
    const { setPage } = this;
    const {
      tableConfig,
      selectedRowKeys,
      visible,
      billParty,
      selectedFinacialYear,
      invoiceDate,
      pageSize,
      type,
      tax,
      isPreview,
    } = this.state;
    const {
      isFetching,
      alllist: { list },
      allCustomer,
      invoicePreviewData,
    } = this.props;
    const Customeroptions = [];
    if (allCustomer && allCustomer.list) {
      for (let i = 0; i < allCustomer.list.length; i++) {
        Customeroptions.push({
          key: allCustomer.list[i].id,
          value: allCustomer.list[i].name,
        });
      }
    }
    const rowSelection = {
      selectedRowKeys,
      onChange: (event) => this.onSelectChange(event),
    };
    return (
      <Card title="LR Details">
        {invoicePreviewData && invoicePreviewData.id ? (
          <Modal
            title="Invoice Preview"
            visible={isPreview}
            onOk={() => this.onConfirm()}
            okText="Confirm"
            onCancel={() => this.handlePreviewCancel()}
            width={"75%"}
            centered
          >
            <div
              dangerouslySetInnerHTML={{
                __html: invoiceutil.invoicehtml(invoicePreviewData),
              }}
            ></div>
          </Modal>
        ) : null}
        <Row>
          <Col span={24}>
            <Select
              className="gx-float-left"
              showSearch
              value={selectedFinacialYear}
              style={{
                width: "100px",
                marginRight: "20px",
                marginBottom: "10px",
              }}
              placeholder="Select Finacial Year"
              optionFilterProp="children"
              onSelect={(event) => this.finacialYearSelect(event)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {finacialYear.map((d) => (
                <Option key={d.key}>{d.value}</Option>
              ))}
            </Select>
            <Select
              className="gx-float-left"
              showSearch
              style={{
                width: "250px",
                marginRight: "20px",
                marginBottom: "10px",
              }}
              placeholder="Select Customer"
              optionFilterProp="children"
              onSelect={(event) => this.customerSelect(event)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Customeroptions.map((d) => (
                <Option key={d.key}>{d.value}</Option>
              ))}
            </Select>
            <Radio.Group
              style={{ marginTop: "5px" }}
              onChange={(event) => this.onBillPartyChange(event)}
              value={billParty}
            >
              <Radio value={"consignor"}>Consignor</Radio>
              <Radio value={"consignee"}>Consignee</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Table
          pagination={{
            onChange(current, pageSize) {
              setPage(current, pageSize);
            },
            pageSize,
          }}
          rowSelection={rowSelection}
          rowKey={"id"}
          bordered={true}
          size={"small"}
          className="gx-table-responsive"
          loading={isFetching}
          columns={this.columns}
          dataSource={list}
        />
        <Row>
          <Col span={24}>
            <Button
              type="primary"
              className="gx-float-left"
              onClick={() => this.onGenerateClick()}
            >
              Generate Invoice
            </Button>
          </Col>
        </Row>
        <Modal
          title="Invoice Details"
          visible={visible}
          onOk={this.confirm}
          okText="Confirm"
          onCancel={this.handleCancel}
          width={"95%"}
          centered
          destroyOnClose={true}
        >
          <Row>
            <Col span={24} className="gx-ml-3 gx-mb-3">
              <Row>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <DatePicker
                    style={{ width: "200px" }}
                    format={"DD/MM/YYYY"}
                    value={invoiceDate}
                    onChange={this.onInvoiceDateChange}
                  />
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Radio.Group value={type} onChange={this.onTypeChange}>
                    <Radio value="RCM">RCM</Radio>
                    <Radio value="Taxable">Taxable</Radio>
                  </Radio.Group>
                </Col>
                {type === "Taxable" ? (
                  <Col span={4} onChange={this.onTaxChange}>
                    <Radio.Group value={tax}>
                      <Radio value="5">5%</Radio>
                      <Radio value="12">12%</Radio>
                    </Radio.Group>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          <Row>
            {selectedRowKeys.map((item) => {
              const lrObjMap = _.find(list, ["id", item]);
              return (
                <Col  xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Card
                    className="gx-card"
                    title={`LR Number ${lrObjMap ? lrObjMap.number : ""}`}
                  >
                    <Form
                      {...formItemLayout}
                      name="country"
                      onFinish={this.confirm}
                      scrollToFirstError
                      onFieldsChange={(_, allFields) => {
                        this.onFieldsChange(allFields, item);
                      }}
                      initialValues={{ freight: lrObjMap.amount }}
                    >
                      <Form.Item
                        name="freight"
                        label="Freight"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid Freight.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="detention"
                        label="Detention"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid detention.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="pointDelivery"
                        label="2 Point Delivery"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid 2 point delivery.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="expressCharge"
                        label="Express Charge"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid express charge.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="loading"
                        label="Loading"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid loading.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="unloading"
                        label="Unloading"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid unloading.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="other"
                        label="Green Tax / Other"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid green tax / other.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name="remark" label="Remark">
                        <TextArea rows={1} />
                      </Form.Item>
                    </Form>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = ({ lrdetails, customer, invoice }) => {
  const { alllist, isFetching } = lrdetails;
  const { invoiceData, invoicePreviewData } = invoice;
  return { alllist, isFetching, allCustomer: customer.alllist, invoiceData, invoicePreviewData };
};
export default connect(mapStateToProps, {
  getAllLrDetails,
  getAllCustomers,
  createInvoice,
  clearAllLrDetails,
  deleteInvoice,
  createInvoicePreview,
  deleteInvoicePreview
})(LrDetails);
