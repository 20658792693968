import Constants from '../../constants/Invoice';
import { API_DOMAIN } from '../../env';
import moment from 'moment';

export const getAllInvoices = () => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_INVOICE_REQUEST,
    });
    return fetch(`${API_DOMAIN}/invoices`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_INVOICE_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_INVOICE_SUCCESS,
                    payload: body,
                });
            }
        });
};
export const getInvoice = (id) => function (dispatch) {
    dispatch({
      type: Constants.GET_INVOICE_REQUEST,
    });
    return fetch(`${API_DOMAIN}/invoices/details/${id}`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_INVOICE_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_INVOICE_SUCCESS,
            payload: body,
          });
        }
      });
  };

export const createInvoice = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.INVOICE_REGISTER_REQUEST
        });
        fetch(`${API_DOMAIN}/invoices/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.INVOICE_REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.INVOICE_REGISTER_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

export const createInvoicePreview = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.INVOICE_PREVIEW_REGISTER_REQUEST
        });
        fetch(`${API_DOMAIN}/invoicespreview/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.INVOICE_PREVIEW_REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.INVOICE_PREVIEW_REGISTER_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}
  
export const deleteInvoice = (handleSuccess,id, data) =>{
  return function (dispatch) {
      dispatch({
          type: Constants.DELETE_INVOICE_REQUEST
      });
      return fetch(`${API_DOMAIN}/invoices/delete/${id}`,{
          method: "DELETE", 
          mode: "cors", 
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
              "Content-Type": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow",
          referrer: "no-referrer",
          body: JSON.stringify(data),
      }).then(() => {
                  dispatch({
                      type: Constants.DELETE_INVOICE_SUCCESS,
                      payload:''
                  });
                  handleSuccess();
          });
  }
};
  
export const deleteInvoicePreview = (handleSuccess,id, data) =>{
  return function (dispatch) {
      dispatch({
          type: Constants.DELETE_INVOICE_PREVIEW_REQUEST
      });
      return fetch(`${API_DOMAIN}/invoicespreview/delete/${id}`,{
          method: "DELETE", 
          mode: "cors", 
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
              "Content-Type": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow",
          referrer: "no-referrer",
          body: JSON.stringify(data),
      }).then(() => {
                  dispatch({
                      type: Constants.DELETE_INVOICE_PREVIEW_SUCCESS,
                      payload:''
                  });
                  handleSuccess();
          });
  }
};

export const getReportInvoices = (selectedDates, handleSuccess, handleFailure) => function (dispatch) {
    dispatch({
        type: Constants.GET_REPORT_INVOICE_REQUEST,
    });
    return fetch(`${API_DOMAIN}/invoices/report?startDate=${moment(selectedDates.startDate).format('YYYY-MM-DD HH:MM:ss')}&endDate=${moment(selectedDates.endDate).format('YYYY-MM-DD HH:MM:ss')}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_REPORT_INVOICE_FAIL,
                    payload: body.error,
                });
                handleFailure(response, body);
            } else {
                dispatch({
                    type: Constants.GET_REPORT_INVOICE_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};

export const getConsigneeInvoices = (id, selectedDates, handleSuccess, handleFailure) => function (dispatch) {
    dispatch({
        type: Constants.GET_CONSIGNEE_INVOICE_REQUEST,
    });
    return fetch(`${API_DOMAIN}/invoicelrs/consignee/${id}?startDate=${moment(selectedDates.startDate).format('YYYY-MM-DD HH:MM:ss')}&endDate=${moment(selectedDates.endDate).format('YYYY-MM-DD HH:MM:ss')}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_CONSIGNEE_INVOICE_FAIL,
                    payload: body.error,
                });
                handleFailure(response, body);
            } else {
                dispatch({
                    type: Constants.GET_CONSIGNEE_INVOICE_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};

export const getConsignorInvoices = (id, selectedDates, handleSuccess, handleFailure) => function (dispatch) {
    dispatch({
        type: Constants.GET_CONSIGNOR_INVOICE_REQUEST,
    });
    return fetch(`${API_DOMAIN}/invoicelrs/consignor/${id}?startDate=${moment(selectedDates.startDate).format('YYYY-MM-DD HH:MM:ss')}&endDate=${moment(selectedDates.endDate).format('YYYY-MM-DD HH:MM:ss')}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_CONSIGNOR_INVOICE_FAIL,
                    payload: body.error,
                });
                handleFailure(response, body);
            } else {
                dispatch({
                    type: Constants.GET_CONSIGNOR_INVOICE_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};

  

