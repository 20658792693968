import Constants from '../../constants/lrdetails';

const INITIAL_STATE = {
  data: [],
  alllist: [],
  details: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
  alllistDS:[],
  duplicateLR: false,
  lrNumber: '',
  lrDetailsData: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {    
    case Constants.GET_ALL_LRDETAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_LRDETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_LRDETAIL_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.CLEAR_ALL_LRDETAIL:
      return {
        ...state,
        isFetching: false,
        alllist: [],
      };
    case Constants.GET_LRDETAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_LRDETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_LRDETAIL_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.GET_LRDETAIL_NUMBER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_LRDETAIL_NUMBER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lrNumber: action.payload.lrNumber,
      };
    case Constants.GET_LRDETAIL_NUMBER_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.GET_LRDETAIL_DUPLICATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_LRDETAIL_DUPLICATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        duplicateLR: action.payload.duplicate,
      };
    case Constants.GET_LRDETAIL_DUPLICATE_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.LRDETAIL_REGISTER_REQUEST:
        return { 
            ...state, 
            isFetching: true 
        };
    case Constants.LRDETAIL_REGISTER_ERROR:
        return {
              ...state,
              isFetching: false 
        };
    case Constants.LRDETAIL_REGISTER_SUCCESS:
        return {
              ...state, 
              isFetching: false,
              lrDetailsData: action.payload,
        };
    case Constants.DELETE_LRDETAIL_REQUEST:
        return {
            ...state,
            isFetching: true
        }
    case Constants.DELETE_LRDETAIL_SUCCESS:
        return {
            ...state,
            stateDelete: action.payload,
            isFetching: false
        }
    case Constants.DELETE_LRDETAIL_FAIL:
        return {
            ...state,
            error: action.payload,
            isFetching: false
        }
        case Constants.LRDETAIL_UPDATE_REQUEST:
          return {
              ...state,
              isFetching: true
          }
      case Constants.LRDETAIL_UPDATE_SUCCESS:
          return {
              ...state,
              // stateDelete: action.payload,
              isFetching: false
          }
      case Constants.LRDETAIL_UPDATE_ERROR:
          return {
              ...state,
              error: action.payload,
              isFetching: false
          }
      case Constants.GET_ALL_DELIVERYSTATUS_REQUEST:
          return {
            ...state,
            isFetching: true,
          };
      case Constants.GET_ALL_DELIVERYSTATUS_SUCCESS:
          return {
            ...state,
            isFetching: false,
            alllistDS: action.payload,
          };
      case Constants.GET_ALL_DELIVERYSTATUS_FAIL:
          return {
            ...state,
            isFetching: false,
            listError: action.error,
          };  
      case Constants.RESET_DETAILS:
          return {
            ...state,
            details: {},
          };            
    case 'SIMPLE_ACTION':
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};
