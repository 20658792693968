import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_BODY_MANUFACTURER_REQUEST',
  'GET_ALL_BODY_MANUFACTURER_SUCCESS',
  'GET_ALL_BODY_MANUFACTURER_FAIL',
  'BODY_MANUFACTURER_REGISTER_REQUEST',
  'BODY_MANUFACTURER_REGISTER_ERROR',
  'BODY_MANUFACTURER_REGISTER_SUCCESS',
]);
