import React from 'react';
import {
    Card,
    Descriptions
} from 'antd';
import { connect } from "react-redux";
import {
    getUserDetails,
} from "../../../../../appRedux/actions";

class User extends React.Component {

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getUserDetails(id);
    }

    render() {
        const { isFetching, details } = this.props;
        return (
            <Card className="gx-card" title="User Information">
                  <Descriptions title="">
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="First Name">{details && details.first_name ? details.first_name: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Last Name">{details && details.last_name ? details.last_name: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Email">{details && details.email ? details.email: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Role">{details && details.Role && details.Role.role ? details.Role.role: ''}</Descriptions.Item>
                </Descriptions>
            </Card>
        );
    }
};

const mapStateToProps = ({ user }) => {
    const { isFetching, details } = user
    return { isFetching, details }
};
export default connect(mapStateToProps, {
    getUserDetails
})(User);