import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Card,
    DatePicker,
    Upload,
    Select
} from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from "axios";
import { API_DOMAIN } from '../../../../../env';
import Pattern from '../../../../../constants/Pattern'

const { Option } = Select

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 5 },
        lg: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        span: 24,
    },
};
const Create = (props) => {
    const { onFinish, alllist, alllistBG, allLicenceTypesList, allListLicenceRto } = props;
    const [form] = Form.useForm();

    const onReset = () => {
        form.resetFields();
    };
    const optionsBlood = [];
    if (alllistBG && alllistBG.list) {
        for (let i = 0; i < alllistBG.list.length; i++) {
            optionsBlood.push({
                key: alllistBG.list[i].id,
                value: alllistBG.list[i].bloodGroupName
            })
        }
    }
    const options = [];
    if (alllist && alllist.list) {
        for (let i = 0; i < alllist.list.length; i++) {
            options.push({
                key: alllist.list[i].id,
                value: alllist.list[i].vehicleNumber
            })
        }
    }
    const optionsLicenceType = [];
    if (allLicenceTypesList && allLicenceTypesList.list) {
        for (let i = 0; i < allLicenceTypesList.list.length; i++) {
            optionsLicenceType.push({
                key: allLicenceTypesList.list[i].id,
                value: allLicenceTypesList.list[i].licenceType
            })
        }
    }
    const optionsrto = [];
    if (allListLicenceRto && allListLicenceRto.list) {
        for (let i = 0; i < allListLicenceRto.list.length; i++) {
            optionsrto.push({
                key: allListLicenceRto.list[i].id,
                value: allListLicenceRto.list[i].rtoName
            })
        }
    }
    const [fileList, setFileList] = useState([]);
    const [fileLicenceList, setFileLicenceList] = useState([]);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const onLicenceChange = ({ fileList: newFileList }) => {
        setFileLicenceList(newFileList);
    };
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const uploadDriverLicence = async options => {
        options.category = 'driverlicence';
        uploadImage(options)
    }
    const uploadDriverPhone = async options => {
        options.category = 'driverphoto';
        uploadImage(options)
    }
    const uploadImage = async options => {
        const { onSuccess, onError, file, onProgress, category } = options;

        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                onProgress({ percent: (event.loaded / event.total) * 100 }, file);
            }
        };
        fmData.append("file", file);
        fmData.append("category", category);
        try {
            const res = await axios.post(
                `${API_DOMAIN}/driver/upload`,
                fmData,
                config
            );

            onSuccess("ok", res);
            const link = res.data.link;
            if(category === 'driverphoto') {
                form.setFieldsValue({ driver_photo: link})
            } else {
                form.setFieldsValue({ licence_copy: link})
            }
            console.log("server res: ", res);
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
    };
    return (
        <Card className="gx-card" title="Driver creation Form">
            <Form
                {...formItemLayout}
                form={form}
                name="driver"
                onFinish={onFinish}
                scrollToFirstError
            >

                <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[
                        { required: true, message: 'Please enter your first name.', whitespace: true },
                        {
                            pattern: new RegExp(Pattern.NAME),
                            message: "Please enter valid first name."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[
                        { required: true, message: 'Please enter your last name..', whitespace: true },
                        {
                            pattern: new RegExp(Pattern.NAME),
                            message: "Please enter valid last name."
                        },
                    ]}    >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Contact Number"
                    name="contact_number"
                    rules={[
                        { required: true, message: 'Please enter contact number.', whitespace: true },
                        {
                            pattern: new RegExp(Pattern.PHONE_NUMBER),
                            message: "Please enter valid contact number."
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Alternate Contact Number"
                    name="alternate_contact_number"
                    rules={[
                        { required: false, message: 'Please enter alternate contact number.' },
                        {
                            pattern: new RegExp(Pattern.PHONE_NUMBER),
                            message: "Please enter valid alternate contact number."
                        },
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Date of Birth"
                    name="date_of_birth"
                    rules={[{ required: false, message: 'Please input your date of birth.' }]}
                >
                    <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Aadhar Number"
                    name="aadhar_number"
                    rules={[
                        { required: false, message: 'Please enter aadhar number.' },
                        {
                            pattern: new RegExp(Pattern.ADHAR_NO),
                            message: "Please enter valid aadhar number(ex.9999 4105 7058)."
                        },
                    ]}
                >
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="PAN Number"
                    name="PAN_number"
                    rules={[
                        // { required: false, message: 'Please enter PAN number.',whitespace:true },
                        {
                            pattern: new RegExp(Pattern.PAN_NO),
                            message: "Please enter valid PAN number(ex.ALWPG5809L)."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Date Of Joining"
                    name="date_of_joining"
                    rules={[{ required: true, message: 'Please input your date of joining.' }]}
                >
                    <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                        { required: false, message: 'Please input your address.' },
                    ]}
                 >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email Address"
                    name="email_address"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail.',
                        },
                        {
                            required: false,
                            message: 'Please input your E-mail.',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Blood Group"
                    name="blood_group_id"
                    rules={[{ required: false, message: 'Please select blood group.', whitespace: true }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select blood group"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {optionsBlood.map(d => <Option key={d.key}>{d.value}</Option>)}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Emergency Contact Name"
                    name="emergency_contact_name"
                    rules={[
                        { required: false, message: 'Please enter emergency contact name.' },
                        {
                            pattern: new RegExp(Pattern.ALPHABET_SPACE),
                            message: "Please enter valid emergency contact name."
                        },
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Emergency Contact Number"
                    name="emergency_contact_number"
                    rules={[
                        { required: false, message: 'Please enter emergency contact number.' },
                        {
                            pattern: new RegExp(Pattern.PHONE_NUMBER),
                            message: "Please enter valid emergency contact number."
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Driver Photo"
                    name="driver_photo"

                >
                    <ImgCrop rotate>
                        <Upload
                            name={'file'}
                            customRequest={uploadDriverPhone}
                            listType="picture-card"
                            onChange={onChange}
                            fileList={fileList}
                            onPreview={onPreview}
                            accept={"image/*"}
                        >
                            {fileList.length < 1 && '+ Upload'}
                        </Upload>
                    </ImgCrop>
                </Form.Item>

                <Form.Item
                    label="License Number"
                    name="licence_number"
                    rules={[
                        { required: true, message: 'Please enter license number.', whitespace: true },
                        // {
                        //     pattern: new RegExp(Pattern.LICENSE_NUMBER),
                        //     message: "Please enter valid license number."
                        // },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="License RTO"
                    name="licence_rto_id"
                    rules={[{ required: true, message: 'Please Select licence RTO.', whitespace: true }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select licence RTO"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {optionsrto.map(d => <Option key={d.key}>{d.value}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Licence Type"
                    name="licence_type_id"
                    rules={[{ required: true, message: 'Please Select licence type.', whitespace: true }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select licence type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {optionsLicenceType.map(d => <Option key={d.key}>{d.value}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Licence Issue Date"
                    name="licence_issue_date"
                    rules={[{ required: true, message: 'Please input your licence issue date.' }]}
                >
                    <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Licence Expiry Date"
                    name="licence_expiry_date"
                    rules={[{ required: true, message: 'Please input your licence expiry date.' }]}
                >
                    <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Licence Copy"
                    name="licence_copy"
                // rules={[{ required: false, message: 'Please input your Driver photo.'}]}
                >
                    <ImgCrop rotate>
                        <Upload
                            name={'licenceFile'}
                            customRequest={uploadDriverLicence}
                            listType="picture-card"
                            onChange={onLicenceChange}
                            fileList={fileLicenceList}
                            onPreview={onPreview}
                            accept={"image/*"}
                        >
                            {fileLicenceList.length < 1 && '+ Upload'}
                        </Upload>
                    </ImgCrop>
                </Form.Item>
                <Form.Item
                    label="Salary"
                    name="salary"
                    rules={[
                        { required: false, message: 'Please enter salary.'},
                        // {
                        //     pattern: new RegExp(Pattern.AMOUNT),
                        //     message: "Please enter valid salary."
                        // }
                    ]}
                >
                    <Input min="0" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Vehicle Number"
                    name="vehical_type_id"
                    rules={[{ required: false, message: 'Please Select vehicle number.', whitespace: true }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select vehicle number"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {options.map(d => <Option key={d.key}>{d.value}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="dearnessAllowance"
                    label="Dearness Allowance"
                    rules={[
                        { required: true, message: 'Please enter dearness allowance.'},
                        {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid dearness allowance."
                        },
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item {...tailFormItemLayout} className="gx-text-center">
                    <Button type="primary" htmlType="submit">
                        Create
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
export default Create;