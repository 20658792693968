import Constants from '../../constants/Invoice';

const INITIAL_STATE = {
  data: [],
  alllist: [],
  reports: [],
  consignees: [],
  consignors: [],
  details: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
  alllistDS: [],
  invoiceData: null,
  invoicePreviewData: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Constants.GET_ALL_INVOICE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_INVOICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_INVOICE_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_INVOICE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_INVOICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_INVOICE_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.INVOICE_REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.INVOICE_REGISTER_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.INVOICE_REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        invoiceData: action.payload.data,
      };
    case Constants.DELETE_INVOICE_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case Constants.DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceData: action.payload,
        isFetching: false
      }
    case Constants.DELETE_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }
    case Constants.INVOICE_PREVIEW_REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.INVOICE_PREVIEW_REGISTER_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.INVOICE_PREVIEW_REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        invoicePreviewData: action.payload.data,
      };
    case Constants.DELETE_INVOICE_PREVIEW_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case Constants.DELETE_INVOICE_PREVIEW_SUCCESS:
      return {
        ...state,
        invoicePreviewData: action.payload,
        isFetching: false
      }
    case Constants.DELETE_INVOICE_PREVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }
    case Constants.INVOICE_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case Constants.INVOICE_UPDATE_SUCCESS:
      return {
        ...state,
        // stateDelete: action.payload,
        isFetching: false
      }
    case Constants.INVOICE_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }
    case Constants.GET_REPORT_INVOICE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_REPORT_INVOICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reports: action.payload,
      };
    case Constants.GET_REPORT_INVOICE_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case Constants.GET_CONSIGNEE_INVOICE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_CONSIGNEE_INVOICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        consignees: action.payload,
      };
    case Constants.GET_CONSIGNEE_INVOICE_FAIL:
      return {
        ...state,
        isFetching: false,
      };
      case Constants.GET_CONSIGNOR_INVOICE_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case Constants.GET_CONSIGNOR_INVOICE_SUCCESS:
        return {
          ...state,
          isFetching: false,
          consignors: action.payload,
        };
      case Constants.GET_CONSIGNOR_INVOICE_FAIL:
        return {
          ...state,
          isFetching: false,
        };
    default:
      return state;
  }
};
