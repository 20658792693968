import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    createDriver,
    getAllBloodGroups,
    getAllVehicles,
    getAllLicenceTypes,
    getAllLicenceRTO,
    upload
} from "../../../../../appRedux/actions";
import Create from './create';

class CreateContainer extends React.Component {

    onFinish = values => {
        console.log("values>>>>", values);
        const { history,alllistBG: { list },alllist,allLicenceTypesList,allListLicenceRto} = this.props
        const handleSuccess = () => {
            message.success('Driver created successfully');
            history.push('/main/master/driver/drivers');
        }
        const handleFailure = () => {
            message.error('Driver creation failed, please try again');
        }
        list.map( item => {
            if(item.bloodGroupName === values.blood_group_id) {
                console.log("item",item,"values",values)
                values.blood_group_id = item.id
            }
        })
        alllist.list.map( item => {
            if(item.vehicleNumber === values.vehical_type_id) {
                console.log("item",item,"values",values)
                values.vehical_type_id = item.id
            }
        })
        allLicenceTypesList.list.map( item => {
            console.log("item",item,"values",values)
            if(item.licenceType === values.licence_type_id) {                
                values.licence_type_id = item.id
            }
        })
        allListLicenceRto.list.map( item => {
            console.log("item",item,"values",values)
            if(item.rtoName === values.licence_rto_id) {                
                values.licence_rto_id = item.id
            }
        })
        this.props.createDriver(handleSuccess, handleFailure, values)
    }    
    componentDidMount() {
        this.props.getAllBloodGroups();
        this.props.getAllVehicles();
        this.props.getAllLicenceTypes();
        this.props.getAllLicenceRTO();
      }
    render() {
        const { isFetching , alllist, alllistBG,allLicenceTypesList,allListLicenceRto} = this.props;
        return (
            <Create onFinish={this.onFinish} isFetching={isFetching} alllist={alllist} alllistBG={alllistBG} allLicenceTypesList={allLicenceTypesList} allListLicenceRto={allListLicenceRto}/>
        );
    }
};

const mapStateToProps = ({ driver,vehicle }) => {
    const { isFetching,alllistBG,allLicenceTypesList,allListLicenceRto } = driver
    const { alllist } = vehicle
return { isFetching,
    alllist,
    alllistBG,
    allLicenceTypesList,
    allListLicenceRto}
};
export default connect(mapStateToProps, {
    createDriver,
    getAllBloodGroups,
    getAllVehicles,
    getAllLicenceTypes,
    getAllLicenceRTO,
    upload
})(CreateContainer);