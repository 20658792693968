import React from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Table,
  Tooltip,
  Divider,
  Popconfirm,
  message,
  Calendar,
  DatePicker,
  Modal,
  Descriptions,
  Form,
  Input,
  Popover
} from "antd";
import Pattern from 'constants/Pattern';
import { SnippetsFilled, EditFilled, DeleteFilled } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { DateRange } from "react-date-range";
import TaskList from "components/dashboard/CRM/TaskList";
import SiteVisit from "components/dashboard/CRM/SiteVisit";
import TaskByStatus from "components/dashboard/CRM/TaskByStatus";
import Overview from "components/dashboard/CRM/Overview";
import Auxiliary from "util/Auxiliary";
import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";
import { taskList } from "./CRM/data";
import {
  getAllUsers,
  deleteUser,
  getStatusCount,
  getStatusCountBy,
  getAllDrivers,
  getAllLrDetails,
  getMonthlyRevenue,
  getMonthlyRevenueVehicle,
  getAllVehicles,
  getLrDetails,
  settlementDriver
} from "../../../appRedux/actions";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const formItemLayout = {
  labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 6 },
      lg: { span: 6 },
  },
  wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 18 },
      lg: { span: 18 },
  },
};
const monthlyRevenueData = [
  { name: "Jan", Target: 3000000, Revenue: 0, month: 1 },
  { name: "Feb", Target: 3000000, Revenue: 0, month: 2 },
  { name: "Mar", Target: 3000000, Revenue: 0, month: 3 },
  { name: "Apr", Target: 3000000, Revenue: 0, month: 4 },
  { name: "May", Target: 3000000, Revenue: 0, month: 5 },
  { name: "Jun", Target: 3000000, Revenue: 0, month: 6 },
  { name: "Jul", Target: 3000000, Revenue: 0, month: 7 },
  { name: "Aug", Target: 3000000, Revenue: 0, month: 8 },
  { name: "Sep", Target: 3000000, Revenue: 0, month: 9 },
  { name: "Oct", Target: 3000000, Revenue: 0, month: 10 },
  { name: "Nov", Target: 3000000, Revenue: 0, month: 11 },
  { name: "Dec", Target: 3000000, Revenue: 0, month: 12 },
];
const { RangePicker } = DatePicker;
const columns = [
  {
    title: "LR Number",
    dataIndex: "number",
    width: 100,
    render: (text, record, index) => {
      const content = (
        <div style={{ padding: '10px' }}>
          <Row style={{ marginBottom: '10px' }}>
            <Col><strong>Consignor Contact:</strong> {record['consignor.contact_number']}</Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col><strong>Vehicle number:</strong> {record['Vehical.vehicleNumber']}</Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col><strong>Driver name:</strong> {`${record['Driver.first_name']} ${record['Driver.last_name']}`}</Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col><strong>Driver number:</strong> {record['Driver.contactNumber']}</Col>
          </Row>
        </div>
      );
      return <Popover content={content} title={'Info'}><Button type="text"><span className="gx-link">{text}</span></Button></Popover>
    }
  },
  {
    title: "Date",
    dataIndex: "date",
    width: 150,
    render: (text) => (
      <span className="gx-link">{moment(text).format("DD-MM-YYYY")}</span>
    ),
  },
  {
    title: "Consignee Name",
    dataIndex: "consignee.name",
    width: 200,
  },
  {
    title: "Destination",
    dataIndex: "destination_city.name",
  },
  {
    title: "Status",
    dataIndex: "Deliverystatus.status",
  },
];
const driverColumns = [
  {
    title: "First Name",
    dataIndex: "first_name",
    width: 150,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    width: 150,
  },
  {
    title: "Phone number",
    dataIndex: "contact_number",
    width: 200,
  },
  {
    title: "Amount",
    dataIndex: "balance",
    render: text => <span className="gx-link">{text ? text : 0}</span>,
  },
];

let settlementFormRef = null;
class Dashboard extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    visible: false,
    driverVisible: false,
    tableConfig: {},
    selectedDriver: {},
    selectedDates: [
      {
        startDate: moment().startOf("month").toDate(),
        endDate: moment().toDate(),
        key: "selection",
      },
    ],
  };

  lrcolumns = [
    {
      title: 'ID',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (value, item, index) => <span className="gx-link">{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
      
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNo',
      key: 'number',
      render: text => <span className="gx-link">{text}</span>,
      
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: text => <span className="gx-link">{text}</span>,
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
      render: text => <span className="gx-link">{text}</span>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: text => <span className="gx-link">{text}</span>,
    },
    {
      title: 'E-Way Bill',
      dataIndex: 'eway',
      key: 'eway',
      render: text => <span className="gx-link">{text}</span>,
    },
    {
      title: 'Validity',
      dataIndex: 'validity',
      key: 'validity',
      render: text => <span className="gx-link">{moment(text).format('DD-MM-YYYY')}</span>,
    }];

  componentDidMount() {
    const startDate = moment().startOf("month");
    const startDateC = moment().startOf("year");
    const date = {
      startDate: moment().startOf("month"),
      endDate: moment(),
    };
    const endDate = moment();
    this.props.getAllUsers();
    this.props.getStatusCount(startDateC, endDate);
    this.props.getStatusCountBy(startDate, endDate);
    this.props.getAllDrivers();
    this.props.getAllLrDetails(date);
    this.props.getMonthlyRevenue();
    this.props.getAllVehicles();
    this.props.getMonthlyRevenueVehicle(startDate, endDate);
  }

  onStatusByClick = (event) => {
    let startDate = null;
    let endDate = null;
    switch (event) {
      case "day":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      default:
        startDate = moment().startOf(event);
        endDate = moment();
        break;
    }
    this.props.getStatusCountBy(startDate, endDate);
  };

  onRevenueClick = (event) => {
    let startDate = null;
    let endDate = null;
    switch (event) {
      case "day":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      default:
        startDate = moment().startOf(event);
        endDate = moment();
        break;
    }
    this.props.getMonthlyRevenueVehicle(startDate, endDate);
  };

  onApply = () => {
    const { selectedDates } = this.state;
    const date = {
      startDate: moment(selectedDates[0].startDate).startOf("day"),
      endDate: moment(selectedDates[0].endDate).endOf("day"),
    };
    this.props.getAllLrDetails(date);
  };

  onLRRowClicked = (event, record, rowIndex) => {
    this.props.getLrDetails(record.id);
    this.setVisible(true);
  };

  onDriverRowClicked = (event, record, rowIndex) => {
    this.setState({ selectedDriver: record });
    this.setDriverVisible(true);
  };

  setVisible = (visible) => {
    this.setState({ visible });
  }

  setDriverVisible = (driverVisible) => {
    this.setState({ driverVisible });
    if(settlementFormRef) {
      settlementFormRef.resetFields();
    }
  }

  confirmSettlement = (values) => {
    const { selectedDriver } = this.state;
    this.setDriverVisible(false);
    const data = {
      id: selectedDriver.id,
      amount: parseFloat(values.settlementAmount),
      remark: values.settlementRemark,
    }
    const handleSuccess = () => {
      this.props.getAllDrivers();
      message.success('Driver settlement updated successfully');
    }
    const handleError = () => {
      this.props.getAllDrivers();
      message.error('Driver settlement updation failed, please try again');
    }
    console.log("data>>>>", data);
    this.props.settlementDriver(handleSuccess, handleError, data)
  }

  setPage = (page, pageSize) => {
    this.setState({page, pageSize})
  }
  settlementForm = (ref) => {
    settlementFormRef = ref;
  }

  render() {
    const { tableConfig, selectedDates, visible, selectedDriver, driverVisible } = this.state;
    const {
      isFetching,
      alllist: { list },
      statusCount,
      statusCountBy,
      alllistLR,
      alllistDriver,
      monthlyRevenue,
      monthlyRevenueVehicle,
      alllistVehicle,
      details,
    } = this.props;
    let mrdata = [];
    if (monthlyRevenue && monthlyRevenue.data) {
      mrdata = monthlyRevenueData.map((monthlyRevenueDataItem) => {
        const monthlyRevenueObj = _.find(monthlyRevenue.data, [
          "name",
          monthlyRevenueDataItem.month,
        ]);
        if (monthlyRevenueObj) {
          monthlyRevenueDataItem.Revenue = monthlyRevenueObj.Revenue;
        }
        return monthlyRevenueDataItem;
      });
    }
    let cardCount = {};
    if (statusCount && statusCount.count && statusCount.count.length) {
      statusCount.count.map((statusCountObj) => {
        cardCount[statusCountObj.status] = statusCountObj.count;
      });
    }
    let newDetails = null;
    if (details) {
      newDetails = { ...details };
      if (details.date) {
        newDetails.date = moment(details.date).format("DD/MM/YYYY");
      }
    }
    return (
      <Auxiliary>
        <Modal
          title={`LR Details for #${newDetails && newDetails.number}`}
          centered
          visible={visible}
          onOk={() => this.setVisible(false)}
          onCancel={() => this.setVisible(false)}
          width={'80%'}
        >
            <Card className="gx-card" title="LR Details Information">
                  <Descriptions title="">
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Location Type">{newDetails && newDetails.locationType ? newDetails.locationType: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="LR Vehicle Type">{newDetails && newDetails.lrVehicleType ? newDetails.lrVehicleType: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="LR Type">{newDetails && newDetails.lrType ? newDetails.lrType: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="LR Number">{newDetails && newDetails.number ? newDetails.number: ''}</Descriptions.Item>
                    {newDetails && newDetails.locationType && newDetails.locationType == 'BR' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Branch Name">{newDetails && newDetails['Branch.name'] ? newDetails['Branch.name']: ''}</Descriptions.Item> : null }
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Date">{newDetails && newDetails.date && newDetails.date ? newDetails.date: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Booking Type">{newDetails && newDetails.bookingType && newDetails.bookingType ? newDetails.bookingType: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Weight(KG)">{newDetails && newDetails.weight ? newDetails.weight: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Package Number">{newDetails && newDetails.packageNumber ? newDetails.packageNumber: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Freight Amount">{newDetails && newDetails.amount ? newDetails.amount: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Origin State">{newDetails ? newDetails['origin_state.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Origin City">{newDetails ? newDetails['origin_city.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Destination State">{newDetails ? newDetails['destination_state.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Destination City">{newDetails && newDetails['destination_city.name']  ? newDetails['destination_city.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignor Name">{newDetails  ? newDetails['consignor.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignor Address">{newDetails ? newDetails['consignor.address']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignor Contact Number">{newDetails ? newDetails['consignor.contact_number']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignee Name">{newDetails ? newDetails['consignee.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignee Address">{newDetails  ? newDetails['consignee.address']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Consignee Contact Number">{newDetails ? newDetails['consignee.contact_number']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Additional Delivery Point Address">{newDetails ? newDetails['additionalAddress']: ''}</Descriptions.Item>
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Own' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Vehicle Number">{newDetails ? newDetails['Vehical.vehicleNumber']: ''}</Descriptions.Item> : null }
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Own' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Driver Name">{newDetails? `${newDetails['Driver.first_name']} ${newDetails['Driver.last_name']}`: ''}</Descriptions.Item> : null }
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Hire' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Vehicle Number">{newDetails ? newDetails['vehicleNumber']: ''}</Descriptions.Item> : null }
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Hire' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Driver name">{newDetails ? newDetails['driverName']: ''}</Descriptions.Item> : null }
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Hire' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Driver Number">{newDetails ? newDetails['contactNumber']: ''}</Descriptions.Item> : null }
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Insurance of Goods">{newDetails && newDetails.insuranceOfGoods && newDetails.insuranceOfGoods ? newDetails.insuranceOfGoods: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Mode of Payment">{newDetails && newDetails.modeOfPayment && newDetails.modeOfPayment ? newDetails.modeOfPayment: ''}</Descriptions.Item>
                    {newDetails && newDetails.lrVehicleType && newDetails.lrVehicleType == 'Own' ? <Descriptions.Item labelStyle={{fontWeight:500}} label="Driver Number">{newDetails ? newDetails['Driver.contactNumber']: ''}</Descriptions.Item> : null }
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Status">{newDetails ? newDetails['Deliverystatus.status']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Broker Name">{newDetails  ? newDetails['Broker.name']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Broker Address">{newDetails  ? newDetails['Broker.address']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Broker Contact">{newDetails  ? newDetails['Broker.contactNumber']: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="E-Way Bill">{newDetails && newDetails.eway ? newDetails.eway: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Remarks">{newDetails && newDetails.remarks ? newDetails.remarks: ''}</Descriptions.Item>
                </Descriptions>
                <Table
                    pagination={{
                    onChange(current, pageSize) {
                        this.setPage(current, pageSize);
                    }
                    }}
                bordered={true} size={'small'} className="gx-table-responsive" loading={isFetching} columns={this.lrcolumns} dataSource={newDetails.invoices} />
            </Card>
        </Modal>
        <Modal
          title={`${selectedDriver ? selectedDriver.first_name+' '+selectedDriver.last_name : '' }`}
          centered
          visible={driverVisible}
          onCancel={() => this.setDriverVisible(false)}
          footer={null}
          width={'30%'}
        >
            <Card className="gx-card" title="Driver Settlement">
                <Form
                    ref={ref => this.settlementForm(ref)}
                    {...formItemLayout}
                    name="settlement"
                    onFinish={this.confirmSettlement}
                    scrollToFirstError
                >
                    <Form.Item
                        name="settlementBalance"
                        label="Balance"
                    >
                        <label>{selectedDriver.balance}</label>
                    </Form.Item>
                    <Form.Item
                        name="settlementRemark"
                        label="Remark"
                        rules={[
                            { required: true, message: 'Please enter remark.' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="settlementAmount"
                        label="Amount"
                        rules={[
                            { required: true, message: 'Please enter Rate.' },
                            {
                                pattern: new RegExp(Pattern.AMOUNT),
                                message: "Please enter valid amount."
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item className="gx-text-center">
                        <Button type="primary" htmlType="submit">
                            Settle
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <IconWithTextCard
                  cardColor="package-picked"
                  icon="orders"
                  title={
                    cardCount && cardCount["Package Picked"]
                      ? cardCount["Package Picked"]
                      : "0"
                  }
                  subTitle="Package Picked"
                />
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <IconWithTextCard
                  cardColor="dispatched"
                  icon="sent"
                  title={
                    cardCount && cardCount["Dispatched"]
                      ? cardCount["Dispatched"]
                      : "0"
                  }
                  subTitle="Dispatched"
                />
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <IconWithTextCard
                  cardColor="in-transit"
                  icon="map-drawing"
                  title={
                    cardCount && cardCount["In-Transit"]
                      ? cardCount["In-Transit"]
                      : "0"
                  }
                  subTitle="In-Transit"
                />
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <IconWithTextCard
                  cardColor="delivered"
                  icon="feedback"
                  title={
                    cardCount && cardCount["Delivered"]
                      ? cardCount["Delivered"]
                      : "0"
                  }
                  subTitle="Delivered"
                />
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <IconWithTextCard
                  cardColor="invoiced"
                  icon="pricing-table"
                  title={
                    cardCount && cardCount["Invoiced"]
                      ? cardCount["Invoiced"]
                      : "0"
                  }
                  subTitle="Invoiced"
                />
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <TaskByStatus
                  statusCountBy={statusCountBy}
                  onStatusByClick={this.onStatusByClick}
                />
              </Col>
              <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                <SiteVisit mrdata={mrdata} />
              </Col>
              <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                <Card title="Driver Advance">
                  <Table
                    className="gx-table-responsive dashboard"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          this.onDriverRowClicked(event, record, rowIndex);
                        }, // click row
                      };
                    }}
                    columns={driverColumns}
                    dataSource={
                      alllistDriver && alllistDriver.list
                        ? alllistDriver.list
                        : []
                    }
                    pagination={{ pageSize: 50 }}
                    scroll={{ y: 205 }}
                  />
                </Card>
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Overview
                  monthlyRevenueVehicle={monthlyRevenueVehicle}
                  alllistVehicle={alllistVehicle}
                  onRevenueClick={this.onRevenueClick}
                />
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card title="LR Details">
                  <Row>
                    <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                      <Table
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: (event) => {
                              this.onLRRowClicked(event, record, rowIndex);
                            }, // click row
                          };
                        }}
                        className="gx-table-responsive dashboard"
                        columns={columns}
                        dataSource={
                          alllistLR && alllistLR.list ? alllistLR.list : []
                        }
                        pagination={{ pageSize: 50 }}
                        scroll={{ y: 205 }}
                      />
                    </Col>
                    <Col
                      xl={8}
                      lg={8}
                      md={8}
                      sm={24}
                      xs={24}
                      className="gx-text-right"
                    >
                      <DateRange
                        onChange={(item) => {
                          this.setState({ selectedDates: [item.selection] });
                        }}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={selectedDates}
                      />
                      <Button onClick={this.onApply} type="primary">
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ user, dashboard, lrdetails, driver, vehicle }) => {
  const { alllist, isFetching } = user;
  const {
    alllist: alllistVehicle,
    isFetching: { isFetchingVehicle },
  } = vehicle;
  const {
    statusCount,
    statusCountBy,
    monthlyRevenue,
    monthlyRevenueVehicle,
  } = dashboard;
  const { alllist: alllistLR, isFetching: isFetchingLR, details } = lrdetails;
  const { alllist: alllistDriver, isFetching: isFetchingDriver } = driver;
  return {
    alllist,
    isFetching,
    statusCount,
    statusCountBy,
    alllistLR,
    alllistDriver,
    monthlyRevenue,
    monthlyRevenueVehicle,
    alllistVehicle,
    details
  };
};
export default connect(mapStateToProps, {
  getAllUsers,
  deleteUser,
  getStatusCount,
  getStatusCountBy,
  getAllDrivers,
  getAllLrDetails,
  getMonthlyRevenue,
  getMonthlyRevenueVehicle,
  getAllVehicles,
  getLrDetails,
  settlementDriver
})(Dashboard);
