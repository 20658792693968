import React from "react";
import { Button, Card, Row, Col, Table, Tooltip, Divider, Popconfirm, message, Modal, Input, Space, DatePicker, Form, Radio } from "antd";
import { FilePdfFilled, DeleteFilled, SearchOutlined, EditFilled } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';
import {
  getAllInvoices,
  deleteInvoice,
  getInvoice,
  createInvoice,
  createInvoicePreview,
  deleteInvoicePreview
} from "../../../../appRedux/actions";
import { S3_PATH_INVOICES } from '../../../../env';
import DataExport from "../../../components/feedback/Export/DataExport";
import Report from "./Report";
import Pattern from "../../../../constants/Pattern";
import * as invoiceutil from "../../../../preview/invoice";
import _ from "lodash";
import { hasActionPermission } from "../../../../util/rolesHelper";
import { ACTION_CREATE, ACTION_DELETE, ACTION_EDIT, ACTION_VIEW, FEATURE_INVOICE } from "../../../../constants/Roles";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 10 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 12 },
    lg: { span: 12 },
  },
};
class LrDetails extends React.Component {
  state = {
    tableConfig: {
    },
    page: 1,
    pageSize: 50,
    visible: false,
    selectedRecord: null,
    deleteRemark: '',
    dataExportVisible: false,
    invoiceDate: moment(),
    type: "RCM",
    tax: "5",
    isPreview: false,
    invoiceVisible: false,
    details: {},
    invoiceData: [],
    selectedFinacialYear: "20-21",
    customerId: 0,
    reportVisible: false
  };
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.details && nextProps.details.id && prevState.details.id !== nextProps.details.id) {
      console.log("getDerivedStateFromProps nextProps>>>>", nextProps);
      const invoiceData = [...nextProps.details.Invoicelrs];
      invoiceData.forEach((element, elementIndex) => {
        invoiceData[elementIndex].lr_id = element.lr_details_id
      });
      return {
        details: {...nextProps.details},
        invoiceDate: moment(nextProps.details.invoiceDate),
        type: nextProps.details.type,
        tax: `${Math.round(nextProps.details.tax)}`,
        invoiceData,
        customerId: nextProps.details.customer_id,
        selectedFinacialYear: nextProps.details.finacialYear
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  componentDidMount() {
    this.props.getAllInvoices();
  }

  setPage = (page, pageSize) => {
    this.setState({page, pageSize})
  }

  confirm = (id) => {
    const { history } = this.props
    const { deleteRemark } = this.state;
    const data = {
      deleteRemarks: deleteRemark
    }
    const handleSuccess = () => {
        message.success('Invoice deleted successfully');
        this.props.getAllInvoices();
        this.setState({visible: false, selectedRecord: null, deleteRemark: ''})
    }
    const handleFailure = () => {
        message.error('Invoice deletion failed, please try again');
    }
    this.props.deleteInvoice(handleSuccess, id, data)
  }
  setVisible = (visible, selectedRecord) => {
    this.setState({visible, selectedRecord})
  }
  onDeleteRemarkChange = (event) => {
    this.setState({deleteRemark: event.target.value})
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  dataExportModel = (dataExportVisible) => {
    this.setState({dataExportVisible})
  }
  reportModel = (reportVisible) => {
    this.setState({reportVisible})
  }
  invoiceConfirm = (id) => {
    const {
      customerId,
      invoiceData,
      selectedRowKeys,
      selectedFinacialYear,
      invoiceDate,
      type,
      tax,
    } = this.state;
    const handleSuccess = () => {
      this.setState({ invoiceVisible: false, isPreview: true });
    };
    const handleFailure = () => {
      message.error("Invoice updation failed, please try again");
    };
    let data = {};
    if (invoiceData && invoiceData.length) {
      data = {
        invoiceDate: invoiceDate,
        customerId: customerId,
        sacCode: "9967",
        placeOfSupply: 168,
        stateCode: 27,
        lr_ids: invoiceData,
        finacialYear: selectedFinacialYear,
        type: type,
        tax: type === "RCM" ? "" : tax,
      };
    } else {
      const {
        alllist: { list },
      } = this.props;
      const invoiceDataNew = selectedRowKeys.map((selectedRowKey) => {
        let lrObj = _.find(list, ["id", selectedRowKey]);
        return {
          freight: lrObj.amount ? lrObj.amount : 0,
          detention: "0",
          expressCharge: "0",
          loading: "0",
          lr_id: selectedRowKey,
          other: "0",
          pointDelivery: "0",
          unloading: "0",
        };
      });
      data = {
        invoiceDate: invoiceDate,
        customerId: customerId,
        sacCode: "9967",
        placeOfSupply: 168,
        stateCode: 27,
        lr_ids: invoiceDataNew,
        finacialYear: selectedFinacialYear,
      };
    }
    this.props.createInvoicePreview(handleSuccess, handleFailure, data);
  };

  onInvoiceConfirm = () => {
    const {
      customerId,
      invoiceData,
      selectedRowKeys,
      selectedFinacialYear,
      invoiceDate,
      type,
      tax,
    } = this.state;
    const { details } = this.props;
    const deleteHandleSuccess = () => {
      const handleSuccess = () => {
        message.success("Invoice updated successfully");
        this.setState({ invoiceVisible: false, invoiceData: [], isPreview: false });
        this.props.getAllInvoices();
      };
      const handleFailure = () => {
        message.error("Invoice updation failed, please try again");
      };
      let data = {};
      if (invoiceData && invoiceData.length) {
        data = {
          invoiceDate: invoiceDate,
          customerId: customerId,
          sacCode: "9967",
          placeOfSupply: 168,
          stateCode: 27,
          lr_ids: invoiceData,
          finacialYear: selectedFinacialYear,
          type: type,
          tax: type === "RCM" ? "" : tax,
        };
      } else {
        const {
          alllist: { list },
        } = this.props;
        const invoiceDataNew = selectedRowKeys.map((selectedRowKey) => {
          let lrObj = _.find(list, ["id", selectedRowKey]);
          return {
            freight: lrObj.amount ? lrObj.amount : 0,
            detention: "0",
            expressCharge: "0",
            loading: "0",
            lr_id: selectedRowKey,
            other: "0",
            pointDelivery: "0",
            unloading: "0",
          };
        });
        data = {
          invoiceDate: invoiceDate,
          customerId: customerId,
          sacCode: "9967",
          placeOfSupply: 168,
          stateCode: 27,
          lr_ids: invoiceDataNew,
          finacialYear: selectedFinacialYear,
          invoiceNo: details.invoiceNo
        };
      }
      this.props.createInvoice(handleSuccess, handleFailure, data);
    }
    this.props.deleteInvoice(deleteHandleSuccess, details.id);
  };

  handlePreviewCancel = () => {
    const { invoicePreviewData } = this.props;
    this.props.deleteInvoicePreview(() => {}, invoicePreviewData.id);
    this.setState({ isPreview: false });
  };

  handleCancel = () => {
    this.setState({ invoiceVisible: false });
  }

  onFieldsChange = (fields, lrId) => {
    const { invoiceData } = this.state;
    let invoiceDataObj = _.remove(invoiceData, ["lr_id", lrId]);
    invoiceDataObj =
      invoiceDataObj && invoiceDataObj.length
        ? invoiceDataObj[0]
        : { lr_id: lrId };
    fields.map((field) => {
      invoiceDataObj[field.name[0]] = field.value;
    });

    invoiceData.push(invoiceDataObj);
    this.setState({ invoiceData });
  };
  onEdit = (id) => {
    this.props.getInvoice(id);
    this.setState({invoiceVisible: true});
  }
  onInvoiceDateChange = (invoiceDate) => {
    this.setState({ invoiceDate });
  };
  onTypeChange = (event) => {
    this.setState({ type: event.target.value });
  };
  onTaxChange = (event) => {
    this.setState({ tax: event.target.value });
  };
  render() {
    const { setPage } = this;
    const { tableConfig,
      pageSize,
      visible,
      selectedRecord,
      deleteRemark,
      dataExportVisible,
      type,
      tax,
      isPreview,
      invoiceDate,
      invoiceVisible,
      invoiceData,
      reportVisible
    } = this.state;
    const { authUser, isFetching, alllist: { list }, details, invoicePreviewData} = this.props;
    let userAccess = [];
    if(authUser && authUser.details && authUser.details.Role && authUser.details.Role) {
      userAccess = JSON.parse(authUser.details.Role.access);
    }
    const columns = [
      {
        title: 'ID',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        render: (value, item, index) => <span className="gx-link">{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
        
      },
      {
        title: 'Invoice Number',
        dataIndex: 'invoiceNo',
        key: 'invoiceNo',
        width: 150,
        render: (text, record) => <span className="gx-link">EELPL/{record.finacialYear}/{String(text).padStart(4, '0')}</span>,
        sorter: (a, b) => a.invoiceNo - b.invoiceNo,
        ...this.getColumnSearchProps("invoiceNo"),
        
      },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      width: 150,
      render: text => <span className="gx-link">{moment(text).format('DD-MM-YYYY HH:mm:ss')}</span>,
      sorter: (a, b) => a["invoiceDate"] ? a["invoiceDate"].localeCompare(b["invoiceDate"]) : a["invoiceDate"],
    },
    {
      title: 'Customer Name',
      dataIndex: 'Customer.name',
      key: 'Customer.name',
      width: 150,
      render: text => <span className="gx-link">{text}</span>,
      ...this.getColumnSearchProps("Customer.name"),
      sorter: (a, b) => a["Customer.name"].localeCompare(b["Customer.name"]),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: text => <span className="gx-link">{text}</span>,
      sorter: (a, b) => a.amount - b.amount,
    }, {
      title: 'Action',
      key: 'action',
      width: 150,
      align:'center',
      render: (text, record) => {
        const { authUser } = this.props;
  
        let userAccess = [];
        if(authUser && authUser.details && authUser.details.Role && authUser.details.Role) {
          userAccess = JSON.parse(authUser.details.Role.access);
        }
        return (
          <span style={{textAlign:'center'}}>
            {record.invoiceUrl ? <a target='_blank' href={`${S3_PATH_INVOICES}${record.invoiceUrl}`}><Button className="gx-margin-none" type="text" size={"small"} icon={<FilePdfFilled />} /></a> : null}
          <Divider type="vertical"/>
           { hasActionPermission(userAccess, FEATURE_INVOICE, ACTION_DELETE) && !record.deleted_at  ?  <Tooltip title="Delete">
            <Popconfirm title="Are you sure you want to delete this invoice?" onConfirm={() => this.setVisible(true, record.id)} onCancel={() => this.cancel()} okText="Yes"
                        cancelText="No">
              <Button className="gx-margin-none" type="text" size={"small"} icon={<DeleteFilled />} />
            </Popconfirm>
          </Tooltip>:null }
          <Divider type="vertical"/>
          {
            hasActionPermission(userAccess, FEATURE_INVOICE, ACTION_EDIT) && (
              <Button onClick={() => this.onEdit(record.id)} className="gx-margin-none" type="text" size={"small"} icon={<EditFilled />} />
            )
          }
          </span>
        )
      },
    }];
    return (
      <Card title="Invoices">
      {invoicePreviewData && invoicePreviewData.id ? (
        <Modal
          title="Invoice Preview"
          visible={isPreview}
          onOk={() => this.onInvoiceConfirm()}
          okText="Confirm"
          onCancel={() => this.handlePreviewCancel()}
          width={"75%"}
          centered
        >
          <div
            dangerouslySetInnerHTML={{
              __html: invoiceutil.invoicehtml(invoicePreviewData),
            }}
          ></div>
        </Modal>
      ) : null}
      {dataExportVisible ? <DataExport
        dataExportModel={this.dataExportModel}
        visible={dataExportVisible}
        columns={columns}
        data={list}
        fileName={`Invoices_${Date()}`}
      /> : null}
      {reportVisible ? <Report
        reportModel={this.reportModel}
        visible={reportVisible}
      /> : null}
      <Modal
          title={`Confirm Delete`}
          centered
          visible={visible}
          onOk={() => this.confirm(selectedRecord)}
          onCancel={() => this.setVisible(false, null)}
          width={'400px'}
        >
            <TextArea rows={4} value={deleteRemark} onChange={this.onDeleteRemarkChange} />
        </Modal>
        <Row>
          <Col span={24}>
           { hasActionPermission(userAccess, FEATURE_INVOICE, ACTION_CREATE) ?  <Link to="/main/lrdetails/invoice">
              <Button
                type="primary"
                className="gx-float-right"
                style={{ marginLeft: "10px"}}
              >
                Generate Invoice
              </Button>
            </Link>:null}
            <Button
              type="primary"
              className="gx-float-right"
              style={{ marginRight: "10px" }}
              onClick={() => this.dataExportModel(true)}
            >
              Export Data
            </Button>
            <Button
              type="primary"
              className="gx-float-right"
              style={{ marginRight: "10px" }}
              onClick={() => this.reportModel(true)}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
        { hasActionPermission(userAccess, FEATURE_INVOICE, ACTION_VIEW) ?  <Table
            pagination={{
              onChange(current, pageSize) {
                setPage(current, pageSize);
              },
              pageSize
            }}
          bordered={true} size={'small'} className="gx-table-responsive" loading={ isFetching } columns={columns} dataSource={list} />:null}
        <Modal
          title="Edit Invoice Details"
          visible={invoiceVisible}
          onOk={this.invoiceConfirm}
          okText="Confirm"
          onCancel={this.handleCancel}
          width={"95%"}
          centered
          destroyOnClose={true}
        >
          <Row>
            <Col span={24} className="gx-ml-3 gx-mb-3">
              <Row>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <DatePicker
                    style={{ width: "200px" }}
                    format={"DD/MM/YYYY"}
                    value={invoiceDate}
                    onChange={this.onInvoiceDateChange}
                  />
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Radio.Group value={type} onChange={this.onTypeChange}>
                    <Radio value="RCM">RCM</Radio>
                    <Radio value="Taxable">Taxable</Radio>
                  </Radio.Group>
                </Col>
                {type === "Taxable" ? (
                  <Col span={4} onChange={this.onTaxChange}>
                    <Radio.Group value={tax}>
                      <Radio value="5">5%</Radio>
                      <Radio value="12">12%</Radio>
                    </Radio.Group>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          <Row>
            {invoiceData.map((item) => {
              return (
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Card
                    className="gx-card"
                    title={`LR Number ${item.LRdetail.number}`}
                  >
                    <Form
                      {...formItemLayout}
                      name="country"
                      onFinish={this.invoiceConfirm}
                      scrollToFirstError
                      onFieldsChange={(_, allFields) => {
                        this.onFieldsChange(allFields, item.lr_details_id);
                      }}
                      initialValues={item}
                    >
                      <Form.Item
                        name="freight"
                        label="Freight"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid Freight.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="detention"
                        label="Detention"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid detention.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="pointDelivery"
                        label="2 Point Delivery"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid 2 point delivery.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="expressCharge"
                        label="Express Charge"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid express charge.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="loading"
                        label="Loading"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid loading.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="unloading"
                        label="Unloading"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid unloading.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="other"
                        label="Green Tax / Other"
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid green tax / other.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name="remark" label="Remark">
                        <TextArea rows={1} />
                      </Form.Item>
                    </Form>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = ({ invoice,user }) => {
  const { alllist, isFetching, details, invoicePreviewData } = invoice
  const { authUser } = user

  return { alllist, isFetching, authUser, details, invoicePreviewData}

};
export default connect(mapStateToProps, {
  getAllInvoices,
  deleteInvoice,
  getInvoice,
  createInvoice,
  createInvoicePreview,
  deleteInvoicePreview
})(LrDetails);