import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_VEHICLES_REQUEST',
  'GET_ALL_VEHICLES_SUCCESS',
  'GET_ALL_VEHICLES_FAIL',
  'GET_VEHICLE_DETAILS_REQUEST',
  'GET_VEHICLE_DETAILS_SUCCESS',
  'GET_VEHICLE_DETAILS_FAIL',
  'VEHICLE_REGISTER_REQUEST',
  'VEHICLE_REGISTER_ERROR',
  'VEHICLE_REGISTER_SUCCESS',
  'DELETE_VEHICLE_REQUEST',
  'DELETE_VEHICLE_SUCCESS',
  'DELETE_VEHICLE_FAIL',
  'VEHICLE_UPDATE_REQUEST',
  'VEHICLE_UPDATE_ERROR',
  'VEHICLE_UPDATE_SUCCESS',
  'GET_ALL_PURCHASEAGENCYS_REQUEST',
  'GET_ALL_PURCHASEAGENCYS_SUCCESS',
  'GET_ALL_PURCHASEAGENCYS_FAIL',
  'GET_ALL_FUELTANKS_REQUEST',
  'GET_ALL_FUELTANKS_SUCCESS',
  'GET_ALL_FUELTANKS_FAIL',
  'GET_ALL_VEHICLETYPES_REQUEST',
  'GET_ALL_VEHICLETYPES_SUCCESS',
  'GET_ALL_VEHICLETYPES_FAIL',
  'RESET_DETAILS'
]);
