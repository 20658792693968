import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    updateCountry,
    getCountryDetails,
    reset
} from "../../../../../appRedux/actions";
import Edit from './edit';

class EditContainer extends React.Component {

    onFinish = values => {
        const { match: { params: { id } }, history } = this.props
        const handleSuccess = () => {
            message.success('Country updated successfully');
            history.push('/main/master/country/countries');
        }
        const handleFailure = () => {
            message.error('Country updation failed, please try again');
        }
        values.id = id;
        this.props.updateCountry(handleSuccess, handleFailure, values)
        console.log("values",values,"id",id)
        this.props.getCountryDetails(id);
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getCountryDetails(id);
    }
    componentWillUnmount() {
        this.props.reset();
    }
    render() {
        const { isFetching, details } = this.props;
        console.log("details",details)
        return (
            <Edit onFinish={this.onFinish} isFetching={isFetching} details={details} />
        );
    }
};

const mapStateToProps = ({ country }) => {
    const { isFetching, details } = country
    return { isFetching, details }
};
export default connect(mapStateToProps, {
    updateCountry,
    getCountryDetails,
    reset
})(EditContainer);