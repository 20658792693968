export default {
    NAME: "^[ a-zA-Z]{2,30}$",
    ALPHABET_SPACE: "^[a-zA-Z ]*$",
    ALPHABET_SPACE_DOT: "^[a-zA-Z. ]*$",
    PHONE_NUMBER: "^(\\+[0-9]{1,3})?[\\s.-]?[0-9]{3,14}[\\s.-]?[0-9]{3,14}([\\s.-]?[0-9]{3,14})?(?:x.+)?$",
    PIN_CODE: "^[1-9][0-9]{2}\\s?[0-9]{3}$",
    ADHAR_NO: "^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$",
    PAN_NO:"^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$",
    VEHICLE_NUMBER: "^[A-Za-z]{2}[ -]?[0-9]{1,2}[ -]?(?:[A-Za-z])?(?:[A-Za-z]*)?[ -]?[0-9]{4}$",
    PASSWORD:"^[\\w+@%#]{6,}$",
    //FULLNAME: "^[a-zA-Z}\\s?]{2,30}$",
    FULLNAME: "^[ a-zA-Z'.,-]{2,150}$",
    AMOUNT:"^[-]?[0-9]{0,12}.?[0-9]{1,2}$",
    GST: "^\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$"    
}
