import Constants from "../../constants/Diver";
import { API_DOMAIN } from '../../env';

export const getAllDrivers = (paranoid) => function (dispatch) {
    dispatch({
      type: Constants.GET_ALL_DRIVERS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/drivers?paranoid=${paranoid}`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_ALL_DRIVERS_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_ALL_DRIVERS_SUCCESS,
            payload: body,
          });
        }
      });
  };
  
  export const createDriver = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.DRIVER_REGISTER_REQUEST
        });
        fetch(`${API_DOMAIN}/driver/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.DRIVER_REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.DRIVER_REGISTER_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
  }
  
  export const getDriverDetails = (id) => function (dispatch) {
    dispatch({
      type: Constants.GET_DRIVER_DETAILS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/driver/details/${id}`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_DRIVER_DETAILS_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_DRIVER_DETAILS_SUCCESS,
            payload: body,
          });
        }
      });
  };
  
  export const deleteDriver = (handleSuccess,id) =>{
    console.log("id",id)
    return function (dispatch) {
        dispatch({
            type: Constants.DELETE_DRIVER_REQUEST
        });
        return fetch(`${API_DOMAIN}/driver/delete/${id}`,{
            method: "DELETE", 
            mode: "cors", 
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrer: "no-referrer",
        }).then(() => {
                    dispatch({
                        type: Constants.DELETE_DRIVER_SUCCESS,
                        payload:''
                    });
                    handleSuccess();
            });
    }
  };
  
  export const updateDriver = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.DRIVER_UPDATE_REQUEST
        });
        fetch(`${API_DOMAIN}/driver/update/${data.id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.DRIVER_UPDATE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.DRIVER_UPDATE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
  }
  
  export const settlementDriver = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.DRIVER_SETTLEMENT_REQUEST
        });
        fetch(`${API_DOMAIN}/driver/settlement/${data.id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.DRIVER_SETTLEMENT_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.DRIVER_SETTLEMENT_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
  }
  export const getAllBloodGroups = () => function (dispatch) {
    dispatch({
      type: Constants.GET_ALL_BLOODGROUPS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/bloodgroups`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_ALL_BLOODGROUPS_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_ALL_BLOODGROUPS_SUCCESS,
            payload: body,
          });
        }
      });
  };
  export const getAllLicenceTypes = () => function (dispatch) {
    dispatch({
      type: Constants.GET_ALL_LICENCETYPES_REQUEST,
    });
    return fetch(`${API_DOMAIN}/licencetypes`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_ALL_LICENCETYPES_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_ALL_LICENCETYPES_SUCCESS,
            payload: body,
          });
        }
      });
  };
  export const upload = (handleSuccess,handleFailure, formData) => {
    return function (dispatch) {
        dispatch({
            type: Constants.UPLOAD_REQUEST
		});
            return fetch(`${API_DOMAIN}/driver/upload`, {
              method: 'POST',
              body: formData,
            })
            .then((response) => response.json().then((body) => ({ response, body })))
            .then(({ response, body }) => {
              if (!response.ok) {
                    dispatch({
                        type: Constants.UPLOAD_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure()
                } else {
                    dispatch({
                        type: Constants.UPLOAD_REQUEST_SUCCESS,
						payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}
export const getAllLicenceRTO = () => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_LICENCERTO_REQUEST,
  });
  return fetch(`${API_DOMAIN}/licencertos`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_LICENCERTO_FAIL,
          payload: body.error,
        });
        console.log("response",response)
      } else {
        dispatch({
          type: Constants.GET_ALL_LICENCERTO_SUCCESS,
          payload: body,
        });
      }
    });
};

export const getAllSettlements = (id) => function (dispatch) {
    dispatch({
      type: Constants.GET_ALL_SETTLEMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/driver/settlements/${id}`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_ALL_SETTLEMENTS_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_ALL_SETTLEMENTS_SUCCESS,
            payload: body,
          });
        }
      });
  };

export const reset = () => function (dispatch) {
  dispatch({
    type: Constants.RESET_DETAILS,
  });
};