import React from 'react';
import {
    Card,
    Descriptions
} from 'antd';
import { connect } from "react-redux";
import moment from 'moment';
import {
    getBranchDetails,
} from "../../../../../appRedux/actions";

class Branch extends React.Component {

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getBranchDetails(id);
    }

    render() {
        const { isFetching, details } = this.props;
        return (
            <Card className="gx-card" title="Branch Information">
                  <Descriptions title="">
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Name">{details && details.name ? details.name : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Incharge">{details && details.incharge ? details.incharge :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Address">{details && details.address ? details.address : ''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Pin code">{details && details.pinCode ? details.pinCode : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Contact_number">{details && details.contactNumber ? details.contactNumber : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Alternate contact number">{details && details.alternateContactNumber ? details.alternateContactNumber : ''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Email address">{details && details.emailAddress ? details.emailAddress : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Opening Time">{details && details.openingTime ? moment(details.openingTime).format('LT') : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Closing Time">{details && details.closingTime ? moment(details.closingTime).format('LT') : ''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Remarks">{details && details.remarks ? details.remarks : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="City">{details && details.City && details.City.name ? details.City.name : ''}</Descriptions.Item>
                           
                </Descriptions>
            </Card>
        );
    }
};

const mapStateToProps = ({ branch }) => {
    const { isFetching, details } = branch
    return { isFetching, details }
};
export default connect(mapStateToProps, {
    getBranchDetails
})(Branch);