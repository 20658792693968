import Constants from '../../constants/BodyManufacturer';
import { API_DOMAIN } from '../../env';

export const getAllBodyManufacturer = () => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_BODY_MANUFACTURER_REQUEST,
  });
  return fetch(`${API_DOMAIN}/bodymanufacturers`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_BODY_MANUFACTURER_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_BODY_MANUFACTURER_SUCCESS,
          payload: body,
        });
      }
    });
};

export const createBodyManufacturer = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.BODY_MANUFACTURER_REGISTER_REQUEST
      });
      fetch(`${API_DOMAIN}/bodymanufacturer/create`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.BODY_MANUFACTURER_REGISTER_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  dispatch({
                      type: Constants.BODY_MANUFACTURER_REGISTER_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}

