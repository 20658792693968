import React from "react";
import {Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import Settings from "./settings";
import Master from "./master"
import Transit from "./transit"
import LrDetails from "./lrdetails"
import Invoice from "./invoice"
import Trip from "./trip"


const Main = ({match}) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
    <Route path={`${match.url}/settings`} component={Settings}/>
    <Route path={`${match.url}/master`} component={Master}/>
    <Route path={`${match.url}/transit`} component={Transit}/>
    <Route path={`${match.url}/lrdetails`} component={LrDetails}/>
    <Route path={`${match.url}/invoice`} component={Invoice}/>
    <Route path={`${match.url}/trip`} component={Trip}/>
  </Switch>
);

export default Main;
