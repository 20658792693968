import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    updateBranch,
    getBranchDetails,
    getAllCities,
    reset
} from "../../../../../appRedux/actions";
import Edit from './edit';

class EditContainer extends React.Component {

    onFinish = values => {
        const { match: { params: { id } }, history, alllist: { list }  } = this.props;
        
        const handleSuccess = () => {
            message.success('Branch updated successfully');
            history.push('/main/master/branch/branches');
        }
        const handleFailure = () => {
            message.error('Branch updation failed, please try again');
        }
        values.id = id;
        this.props.updateBranch(handleSuccess, handleFailure, values)
    }

    componentDidMount() {
        const { match: { params: { id } } ,details} = this.props;
        console.log("id",id)
        this.props.getBranchDetails(id);
        this.props.getAllCities();       
    }
    componentWillUnmount() {
        this.props.reset();
    }
    render() {
        const { isFetching, details, alllist ,match: { params: { id } }} = this.props;
        console.log("details",details,"id",id)
        return (
            <Edit onFinish={this.onFinish} isFetching={isFetching} details={details} alllist={alllist} />
        );
    }
};

const mapStateToProps = ({ branch, city }) => {
    const { alllist } = city
    const { isFetching, details } = branch
    return { isFetching, details, alllist }
};
export default connect(mapStateToProps, {
    updateBranch,
    getBranchDetails,
    getAllCities,
    reset
})(EditContainer);