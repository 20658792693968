import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    updateVehicle,
    getVehicleDetails,
    getAllVehicaltypes,
    getAllFueltanks,
    getAllPurchaseagencys,
    getAllFinancer,
    getAllBodyManufacturer,
    getAllManufacturer,
    reset
} from "../../../../../appRedux/actions";
import Edit from './edit';

class EditContainer extends React.Component {

    onFinish = values => {
        const { match: { params: { id } }, history,alllistPAlist,alllistFTlist,alllistVTlist, allFinanceList,alllManufacturer,alllBodyManufacturer } = this.props
        const handleSuccess = () => {
            message.success('Vehicle updated successfully');
            history.push('/main/master/vehicle/vehicles');
        }
        const handleFailure = () => {
            message.error('Vehicle updation failed, please try again');
        }
        values.id = id;

        // list.map( item => {
        //     if(item.bloodGroupName === values.blood_group_id) {
        //         values.blood_group_id = item.blood_group_id
        //     }
        // })
        // alllist.list.map( item => {
        //     if(item.vehicleNumber === values.vehicleNumber) {
        //         values.vehical_id = item.id
        //     }
        // })
        // allLicenceTypesList.list.map( item => {
        //     if(item.licenceType === values.licence_type_id) {
        //         values.licence_type_id = item.id
        //     }
        // })
        

        if (alllistFTlist && alllistFTlist.list) {
            alllistFTlist.list.map( item => {
                if(item.capacity === values.fuel_tank_id) {
                    values.fuel_tank_id = item.id
                }
            })
        }
        if (alllistPAlist && alllistPAlist.list) {
            alllistPAlist.list.map( item => {
                if(item.agencyName === values.purchase_agency_id) {
                    values.purchase_agency_id = item.id
                }
            })
        }
        if (alllistVTlist && alllistVTlist.list) {
            alllistVTlist.list.map( item => {
                if(item.vehicalType === values.vehical_type_id) {
                    values.vehical_type_id = item.id
                }
            })
        }
        if (allFinanceList && allFinanceList.list) {
            allFinanceList.list.map( item => {
                if(item.financerName === values.vehicle_financer_id) {
                    values.vehicle_financer_id = item.id
                }
            })
        }
        if (alllManufacturer && alllManufacturer.list) {
            alllManufacturer.list.map( item => {
                if(item.manufacturer === values.vehicle_manufacturer) {
                    values.vehicle_manufacturer = item.id
                }
            })
        }
        if (alllBodyManufacturer && alllBodyManufacturer.list) {
            alllBodyManufacturer.list.map( item => {
                if(item.bodyManufacturer === values.body_manufacture_id) {
                    values.body_manufacture_id = item.id
                }
            })
        }
        this.props.updateVehicle(handleSuccess, handleFailure, values)
        console.log("values",values,"id",id)
        this.props.getVehicleDetails(id);
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getVehicleDetails(id);
        this.props.getAllVehicaltypes();
        this.props.getAllFueltanks();
        this.props.getAllPurchaseagencys();
        this.props.getAllFinancer();
        this.props.getAllBodyManufacturer();
        this.props.getAllManufacturer();
    }
    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const { isFetching, details ,alllistPAlist,alllistFTlist,alllistVTlist,allFinanceList,alllBodyManufacturer,alllManufacturer } = this.props;
        console.log("details",details,"this.props;",this.props)
        return (
            <Edit onFinish={this.onFinish} isFetching={isFetching} details={details} alllistPAlist={alllistPAlist} alllistFTlist={alllistFTlist} alllistVTlist={alllistVTlist} 
            allFinanceList={allFinanceList}
            alllManufacturer={alllManufacturer}
            alllBodyManufacturer={alllBodyManufacturer}
            />
        );
    }
};

const mapStateToProps = ({  vehicle,financer,manufacturer,bodyManufacturer }) => {
    const { isFetching, details,alllistPAlist,alllistFTlist,alllistVTlist } = vehicle
    const {allFinanceList} = financer
    const {alllManufacturer} = manufacturer
    const {alllBodyManufacturer} = bodyManufacturer
    return { isFetching, details,alllistPAlist,alllistFTlist,alllistVTlist,allFinanceList,alllBodyManufacturer,alllManufacturer }

};
export default connect(mapStateToProps, {
    updateVehicle,
    getVehicleDetails,
    getAllVehicaltypes,
    getAllFueltanks,
    getAllPurchaseagencys,
    getAllFinancer,
    getAllBodyManufacturer,
    getAllManufacturer,
    reset
})(EditContainer);