import Constants from '../../constants/Branch';
import { API_DOMAIN } from '../../env';

export const getAllBranches = () => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_BRANCHES_REQUEST,
  });
  return fetch(`${API_DOMAIN}/branches`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_BRANCHES_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_BRANCHES_SUCCESS,
          payload: body,
        });
      }
    });
};

export const createBranch = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.BRANCH_REGISTER_REQUEST
      });
      fetch(`${API_DOMAIN}/branch`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.BRANCH_REGISTER_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  dispatch({
                      type: Constants.BRANCH_REGISTER_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}

export const getBranchDetails = (id) => function (dispatch) {
  dispatch({
    type: Constants.GET_BRANCH_DETAILS_REQUEST,
  });
  return fetch(`${API_DOMAIN}/branches/details/${id}`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_BRANCH_DETAILS_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_BRANCH_DETAILS_SUCCESS,
          payload: body,
        });
      }
    });
};

export const deleteBranch = (handleSuccess,id) =>{
  console.log("id",id)
  return function (dispatch) {
      dispatch({
          type: Constants.DELETE_BRANCH_REQUEST
      });
      return fetch(`${API_DOMAIN}/branches/delete/${id}`,{
          method: "DELETE", 
          mode: "cors", 
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
              "Content-Type": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow",
          referrer: "no-referrer",
      }).then(() => {
                  dispatch({
                      type: Constants.DELETE_BRANCH_SUCCESS,
                      payload:''
                  });
                  handleSuccess();
          });
  }
};

export const updateBranch = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.BRANCH_UPDATE_REQUEST
      });
      fetch(`${API_DOMAIN}/branches/update/${data.id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json'
          }
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.BRANCH_UPDATE_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  dispatch({
                      type: Constants.BRANCH_UPDATE_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}

export const reset = () => function (dispatch) {
  dispatch({
    type: Constants.RESET_DETAILS,
  });
};

