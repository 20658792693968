import React from "react";
import {Route, Switch} from "react-router-dom";
import Country from "./country";
import Driver from "./driver";
import Vehicle from "./vehicle";
import Customer from "./customer";
import Branch from "./branch";
import City from "./city";
import State from "./state";
import Broker from "./broker"
const Master = ({match}) => (
  <Switch>
    <Route path={`${match.url}/country`} component={Country}/>
    <Route path={`${match.url}/city`} component={City}/>
    <Route path={`${match.url}/state`} component={State}/>
    <Route path={`${match.url}/driver`} component={Driver}/>
    <Route path={`${match.url}/vehicle`} component={Vehicle}/>
    <Route path={`${match.url}/customer`} component={Customer}/>
    <Route path={`${match.url}/driver`} component={Driver}/>
    <Route path={`${match.url}/branch`} component={Branch}/>
    <Route path={`${match.url}/broker`} component={Broker}/>
  </Switch>
);

export default Master;
