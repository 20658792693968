import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import User from "./User";
import Country from "./Country";
import Driver from "./Driver";
import Vehicle from "./Vehicle";
import Customer from "./Customer";
import Branch from "./Branch";
import City from './cities';
import State from './state';
import lrdetails from './lrdetails';
import broker from './Broker';
import Transit from './Transit';
import Financer from './Financer'
import BodyManufacturer from './BodyManufacturer'
import Manufacturer from './Manufacturer'
import Invoice from './Invoice'
import Trip from './Trip'
import Dashboard from './Dashboard'



const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  user: User,
  country: Country,
  driver: Driver,
  vehicle: Vehicle,
  customer : Customer,
  branch: Branch,
  city:City,
  state:State,
  lrdetails:lrdetails,
  broker:broker,
  transit:Transit,
  financer:Financer,
  manufacturer:Manufacturer,
  bodyManufacturer:BodyManufacturer,
  invoice:Invoice,
  trip:Trip,
  dashboard:Dashboard

});

export default createRootReducer
