import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_TRANSIT_REQUEST',
  'GET_ALL_TRANSIT_SUCCESS',
  'GET_ALL_TRANSIT_FAIL',
  'GET_TRANSIT_DETAILS_REQUEST',
  'GET_TRANSIT_DETAILS_SUCCESS',
  'GET_TRANSIT_DETAILS_FAIL',
  'GET_LR_TRANSIT_DETAILS_REQUEST',
  'GET_LR_TRANSIT_DETAILS_SUCCESS',
  'GET_LR_TRANSIT_DETAILS_FAIL',
  'CREATE_TRANSIT_REQUEST',
  'CREATE_TRANSIT_ERROR',
  'CREATE_TRANSIT_SUCCESS',
  'DELETE_TRANSIT_REQUEST',
  'DELETE_TRANSIT_SUCCESS',
  'DELETE_TRANSIT_FAIL',
  'UPDATE_TRANSIT_REQUEST',
  'UPDATE_TRANSIT_ERROR',
  'UPDATE_TRANSIT_SUCCESS',
  'GET_ALL_DELIVERY_STATUS_REQUEST',
  'GET_ALL_DELIVERY_STATUS_FAIL',
  'GET_ALL_DELIVERY_STATUS_SUCCESS',
  'RESET_DETAILS'
]);
