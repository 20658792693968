import React from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Widget from "components/Widget";
import data from "../../../routes/extensions/charts/recharts/bar/Components/data";

const SiteVisit = ({ mrdata }) => (
  <Widget title={
    <h2 className="h4 gx-text-capitalize gx-mb-0">Monthly Revenue</h2>
  } styleName="gx-text-center">
    <div className="gx-py-1">
      <ResponsiveContainer width="100%" height={235}>
        <BarChart data={mrdata}
                  margin={{top: 10, right: 0, left: 10, bottom: 0}}>
          <XAxis dataKey="name"/>
          <YAxis/>
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip/>
          <Legend/>
          <Bar dataKey="Target" fill="#003366"/>
          <Bar dataKey="Revenue" fill="#FE9E15"/>
        </BarChart>
      </ResponsiveContainer>
    </div>
  </Widget>
);

export default SiteVisit;

