import React from 'react';
import {
    Card,
    Descriptions
} from 'antd';
import { connect } from "react-redux";
import {
    getBrokerDetails,
    getAllCities
} from "../../../../../appRedux/actions";

class Broker extends React.Component {

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getBrokerDetails(id);
        this.props.getAllCities()
    }
    

    render() {
        const { isFetching, details,alllist } = this.props;
    let newDetails = null;
    if(details) {
        newDetails = {...details};
        if(alllist && alllist.list && newDetails.city_id) {
            alllist.list.map( item => {
                if(item.id === newDetails.city_id) {
                    newDetails.city_id = item.name
                }
            })
        }
    }
        return (
            <Card className="gx-card" title="Broker Information">
                  <Descriptions title="">
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Name">{newDetails && newDetails.name ? newDetails.name : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Address">{newDetails && newDetails.address ? newDetails.address : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Contact Number">{newDetails && newDetails.contactNumber ? newDetails.contactNumber : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Email Address">{newDetails && newDetails.emailAddress ? newDetails.emailAddress : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Contact Name">{newDetails && newDetails.contactName ? newDetails.contactName : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="PAN Number">{newDetails && newDetails.pan ? newDetails.pan : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="GST Number">{newDetails && newDetails.GSTNumber ? newDetails.GSTNumber : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Pin Code">{newDetails && newDetails.pinCode ? newDetails.pinCode : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Remarks">{newDetails && newDetails.remarks ? newDetails.remarks : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="City">{newDetails && newDetails.city_id ? newDetails.city_id : ''}</Descriptions.Item>
                
                </Descriptions>
            </Card>
        );
    }
};

const mapStateToProps = ({ broker,city }) => {
    const { isFetching, details } = broker
    const { alllist } = city
    return { isFetching, details,alllist }
};
export default connect(mapStateToProps, {
    getBrokerDetails,
    getAllCities
})(Broker);