import React from "react";
import { Row, Col, Select } from 'antd';
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";

import Widget from "components/Widget";

const { Option } = Select;
const data = [{name: 'Package Picked', value: 28}, {name: 'Dispatched', value: 20},
  {name: 'In-Transit', value: 8}, {name: 'Delivered', value: 15}, {name: 'Invoiced', value: 30}];

const COLORS = {'Package Picked': '#13c2c2', 'Dispatched': '#F1C40F', 'In-Transit': '#99adc1', 'Delivered': '#E67451', 'Invoiced': '#2ECC71'};


const TaskByStatus = ({ statusCountBy, onStatusByClick }) => {
  let statusCountByL = [];
  let colors = [];
  let statusCountByCount = 0;
  if(statusCountBy && statusCountBy.count) {
    statusCountByL = statusCountBy.count.map( item => {
      statusCountByCount = statusCountByCount + item.count;
      colors.push(COLORS[item.status])
      return {
        name: item.status,
        value: item.count
      }
    })
  }
  return (
    <Widget title={
      <h2 className="h4 gx-text-capitalize gx-mb-0">
        LR Status</h2>
    } styleName="gx-text-center">
      <div className="gx-py-1">
        <ResponsiveContainer width="100%" height={180}>
          <PieChart>
            <Tooltip/>
            <text
              x="50%" className="h1"
              y="50%" textAnchor="middle" dominantBaseline="middle">
              {statusCountByCount}
            </text>
            <Pie
              data={statusCountByL}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={90}
              fill="#8884d8"
            >
              {
                statusCountByL.map((entry, index) => <Cell key={index} fill={colors[index]}/>)
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <Row>
          <Col span={4}></Col>
          <Col span={16}>
            <Select onChange={(event) => onStatusByClick(event)} defaultValue="month" style={{ width: '100%', marginTop: '20px' }}>
              <Option value="day">Today's status</Option>
              <Option value="week">Current week status</Option>
              <Option value="month">Current month status</Option>
              <Option value="year">Current year status</Option>
            </Select>
          </Col>
          <Col span={4}></Col>
        </Row>
      </div>
    </Widget>
  );
};
export default TaskByStatus;
