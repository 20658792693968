import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    createCustomer,
    getAllCities
} from "../../../../../appRedux/actions";
import Create from './create';

class CreateContainer extends React.Component {

    onFinish = values => {
        const { history ,alllist: { list }, modalAction } = this.props
        console.log("list",list)
        const handleSuccess = () => {
            message.success('Customer created successfully');
            if(modalAction) {
                modalAction();
            } else {
                history.push('/main/master/customer/customers');
            }
        }
        const handleFailure = () => {
            message.error('Customer creation failed, please try again');
        }
        this.props.createCustomer(handleSuccess, handleFailure, values)
    }
    componentDidMount() {
        this.props.getAllCities();
      }
    render() {
        const { isFetching,alllist } = this.props;
        console.log("alllist",alllist)
        return (
            <Create onFinish={this.onFinish} isFetching={isFetching} alllist={alllist}/>
        );
    }
};

const mapStateToProps = ({ customer,city}) => {
    const { isFetching } = customer
    const { alllist } = city
    return { isFetching ,alllist }
};
export default connect(mapStateToProps, {
    createCustomer,
    getAllCities
})(CreateContainer);