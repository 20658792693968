import React from "react";
import { Button, Card, Row, Col, Tag, Table, Tooltip, Divider, Popconfirm, message, Select, Modal, Form, Input, Space } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment'
import _ from 'lodash';
import {
  getAllLrDetails,
  getAllVehicles,
  createInvoice,
  clearAllLrDetails,
  createTripUrl,
  priviewTripUrl
} from "../../../../appRedux/actions";
import * as triputil from '../../../../preview/trip';
const { Option } = Select

const finacialYear = [{
  key: '19-20',
  value: '19-20'
}, {
  key: '20-21',
  value: '20-21'
}, {
  key: '21-22',
  value: '21-22'
}, {
  key: '22-23',
  value: '22-23'
}, {
  key: '23-24',
  value: '23-24'
}, {
  key: '24-25',
  value: '24-25'
}];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 10 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 12 },
    lg: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    span: 24,
  },
};
class LrDetails extends React.Component {
  state = {
    tableConfig: {
    },
    selectedRowKeys: [],
    selectedFinacialYear: '24-25',
    customerId: 0,
    visible: false,
    invoiceData: [],
    page: 1,
    pageSize: 20,
    isPreview: false
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.props.getAllVehicles();
    this.props.clearAllLrDetails();
  }
  confirm = (id) => {
    const { customerId, selectedRowKeys, selectedFinacialYear } = this.state;
    const { history } = this.props;
    const handleSuccess = () => {
      message.success('Trip Details created successfully');
      history.push('/main/trip/trips');
    }
    const handleFailure = () => {
      message.error('Trip Details creation failed, please try again');
    }
    const data = {
      "tripDate": new Date(),
      "vehicleId": customerId,
      "lr_ids": selectedRowKeys,
      "finacialYear": selectedFinacialYear
    }
    this.props.createTripUrl(handleSuccess, handleFailure, data)
  }

  preview = (id) => {
    const { customerId, selectedRowKeys, selectedFinacialYear } = this.state;
    const { history } = this.props;
    const handleSuccess = () => {
      // message.success('Trip Details created successfully');
      // history.push('/main/trip/trips');
      this.setState({ isPreview: true });
    }
    const handleFailure = () => {
      message.error('Trip Details preview failed, please try again');
    }
    const data = {
      "tripDate": new Date(),
      "vehicleId": customerId,
      "lr_ids": selectedRowKeys,
      "finacialYear": selectedFinacialYear
    }
    this.props.priviewTripUrl(handleSuccess, handleFailure, data)
  }

  customerSelect(value) {
    this.setState({ customerId: value });
    const data = {
      status: [4, 5],
      trip: true,
      vehicleIds: [parseInt(value)],
    }
    this.props.getAllLrDetails(data);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onGenerateClick = () => {
    this.setState({ visible: true })
  }

  handleCancel = () => {
    this.setState({ isPreview: false })
  }

  onFieldsChange = (fields, lrId) => {
    const { invoiceData } = this.state;
    let invoiceDataObj = _.remove(invoiceData, ['lr_id', lrId]);
    invoiceDataObj = invoiceDataObj && invoiceDataObj.length ? invoiceDataObj[0] : { 'lr_id': lrId };
    fields.map((field) => {
      invoiceDataObj[field.name[0]] = field.value
    })
    invoiceData.push(invoiceDataObj);
    this.setState({ invoiceData })
  }

  setPage = (page, pageSize) => {
    this.setState({ page, pageSize })
  }

  finacialYearSelect(value) {
    this.setState({ selectedFinacialYear: value });
  }
  render() {
    const { setPage } = this;
    const { tableConfig, selectedRowKeys, visible, selectedFinacialYear, isPreview, pageSize } = this.state;
    const { isFetching, alllist: { list }, allCustomer, tripPreview } = this.props;
    const Customeroptions = [];
    if (allCustomer && allCustomer.list) {
      for (let i = 0; i < allCustomer.list.length; i++) {
        Customeroptions.push({
          key: allCustomer.list[i].id,
          value: allCustomer.list[i].vehicleNumber
        })
      }
    }
    const rowSelection = {
      selectedRowKeys,
      onChange: (event) => this.onSelectChange(event),
    };

    const columns = [
      {
        title: 'ID',
        dataIndex: 'index',
        key: 'index',
        render: (value, item, index) => <span className="gx-link">{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,

      },
      {
        title: 'LR Number',
        dataIndex: 'number',
        key: 'number',
        render: text => <span className="gx-link">{text}</span>,
        sorter: (a, b) => a.number - b.number,
        ...this.getColumnSearchProps("number"),
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: text => <span className="gx-link">{moment(text).format('DD-MM-YYYY')}</span>,
        sorter: (a, b) => a["date"].localeCompare(b["date"]),
      },
      {
        title: 'Consignee Name',
        dataIndex: 'consignee.name',
        key: 'consignee.name',
        render: text => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("consignee.name"),
        sorter: (a, b) => a["consignee.name"].localeCompare(b["consignee.name"]),
      },
      {
        title: 'Destination',
        dataIndex: 'destination_city.name',
        key: 'destination_city.name',
        render: text => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("destination_city.name"),
        sorter: (a, b) =>
          a["destination_city.name"].localeCompare(b["destination_city.name"]),
      },
      {
        title: 'Status',
        dataIndex: 'Deliverystatus.status',
        key: 'Deliverystatus.status',
        render: text => <Tag color="#2db7f5">{text}</Tag>,
        ...this.getColumnSearchProps("Deliverystatus.status"),
        sorter: (a, b) =>
          a["Deliverystatus.status"].localeCompare(b["Deliverystatus.status"]),
      }];
    return (
      <Card title="LR Details">
        <Row>
          <Col span={24}>
            <Select
              className="gx-float-left"
              showSearch
              value={selectedFinacialYear}
              style={{ width: '100px', marginRight: '20px', marginBottom: '10px' }}
              placeholder="Select Finacial Year"
              optionFilterProp="children"
              onSelect={(event) => this.finacialYearSelect(event)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {finacialYear.map(d => <Option key={d.key}>{d.value}</Option>)}
            </Select>
            <Select
              className="gx-float-left"
              showSearch
              style={{ width: '250px' }}
              placeholder="Select Vehicle"
              optionFilterProp="children"
              onSelect={(event) => this.customerSelect(event)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Customeroptions.map(d => <Option key={d.key}>{d.value}</Option>)}
            </Select>
          </Col>
        </Row>
        <Table
          pagination={{
            onChange(current, pageSize) {
              setPage(current, pageSize);
            },
            pageSize
          }}
          rowSelection={rowSelection}
          rowKey={'id'}
          bordered={true} size={'small'} className="gx-table-responsive" loading={isFetching} columns={columns} dataSource={list} />
        <Row>
          <Col span={24}>
            <Button
              type="primary"
              className="gx-float-left"
              onClick={() => this.preview()}
            >
              Generate Trip Details
            </Button>
          </Col>
        </Row>
        {tripPreview && tripPreview.data ? <Modal
          title="Trip Preview"
          visible={isPreview}
          onOk={this.confirm}
          okText="Confirm"
          onCancel={this.handleCancel}
          width={'75%'}
          centered
        >
          <div dangerouslySetInnerHTML={{ __html: triputil.triphtml(tripPreview) }}></div>
        </Modal> : null}
      </Card>
    );
  }
}

const mapStateToProps = ({ lrdetails, vehicle, trip }) => {
  const { alllist, isFetching } = lrdetails
  const { tripPreview } = trip
  return { alllist, isFetching, allCustomer: vehicle.alllist, tripPreview }
};
export default connect(mapStateToProps, {
  getAllLrDetails,
  getAllVehicles,
  createInvoice,
  clearAllLrDetails,
  createTripUrl,
  priviewTripUrl
})(LrDetails);