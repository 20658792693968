import React from "react";
import { Button, Card, Row, Col, Table, Tooltip, Divider, Popconfirm, message, Input, Modal, Space } from "antd";
import { FilePdfFilled, DeleteFilled, SearchOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import Highlighter from "react-highlight-words";
import {connect} from "react-redux";
import moment from 'moment';
import {
  getAllTripUrl,
  deleteTripUrl
} from "../../../../appRedux/actions";
import { S3_PATH_TRIPS } from '../../../../env';
import DataExport from "../../../components/feedback/Export/DataExport";
import { hasActionPermission } from "../../../../util/rolesHelper";
import { ACTION_DELETE, ACTION_VIEW, FEATURE_TRIP } from "../../../../constants/Roles";
const { TextArea } = Input;

class Trips extends React.Component {
  state = {
    tableConfig: {
    },
    page: 1,
    pageSize: 50,
    visible: false,
    selectedRecord: null,
    deleteRemark: '',
    dataExportVisible: false
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  componentDidMount() {
    this.props.getAllTripUrl();
  }

  setPage = (page, pageSize) => {
    this.setState({page, pageSize})
  }
  confirm = (id) => {
    const { history } = this.props
    const { deleteRemark } = this.state;
    const data = {
      deleteRemarks: deleteRemark
    }
    const handleSuccess = () => {
        message.success('Trip deleted successfully');
        this.props.getAllTripUrl();
        this.setState({visible: false, selectedRecord: null, deleteRemark: ''})
    }
    const handleFailure = () => {
        message.error('Trip deletion failed, please try again');
    }
    this.props.deleteTripUrl(handleSuccess, id, data)
  }
  setVisible = (visible, selectedRecord) => {
    this.setState({visible, selectedRecord})
  }
  onDeleteRemarkChange = (event) => {
    this.setState({deleteRemark: event.target.value})
  }
  dataExportModel = (dataExportVisible) => {
    this.setState({dataExportVisible})
  }
  render() {
    const { setPage } = this;
    const { tableConfig, pageSize, visible, selectedRecord, deleteRemark, dataExportVisible  } = this.state;
    let { isFetching, alllisturl: { list },authUser } = this.props;
    let userAccess = [];
    if(authUser && authUser.details && authUser.details.Role && authUser.details.Role) {
      userAccess = JSON.parse(authUser.details.Role.access);
    }    
    if(list && list.length>0)
    {
      list =list.map(l=>{        
        return({
        ...l,
        TripBalance :parseFloat(l['diesel'])+parseFloat(l['advance'])-parseFloat(l['expenses']),
        });
      })
    }
    const columns = [ 
      {
        title: 'ID',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        render: (value, item, index) => <span className="gx-link">{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
        
      },
      {
        title: 'Trip Number',
        dataIndex: 'tripNo',
        key: 'tripNo',
        width: 150,
        render: (text, record) => <span className="gx-link">TRIP/{record.finacialYear}/{String(text).padStart(4, '0')}</span>,
        sorter: (a, b) => a.tripNo - b.tripNo,
        ...this.getColumnSearchProps("tripNo"),
        
      },
    {
      title: 'Driver Name',
      dataIndex: 'Driver.first_name',
      key: 'Driver.first_name',
      render: (text, record) => <span className="gx-link">{text} {record['Driver.last_name']}</span>,
      ...this.getColumnSearchProps("Driver.first_name"),
      sorter: (a, b) => {
        console.log("a, b<<<<", a, b)
        return a["Driver.first_name"] ? a["Driver.first_name"].localeCompare(b["Driver.first_name"]) : a["Driver.first_name"];
      },
    },
    {
      title: 'Trip Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: text => <span className="gx-link">{moment(text).format('DD-MM-YYYY')}</span>,
      sorter: (a, b) => a["startDate"].localeCompare(b["startDate"]),
    },
    {
      title: 'Trip End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: text => <span className="gx-link">{moment(text).format('DD-MM-YYYY')}</span>,
      sorter: (a, b) => a["endDate"].localeCompare(b["endDate"]),
    },
    {
      title: 'Trip Diesel Advance',
      dataIndex: 'diesel',
      key: 'diesel',
      render: text => <span className="gx-link">{text}</span>,
      sorter: (a, b) => a.diesel - b.diesel,
      ...this.getColumnSearchProps("diesel"),
    },
    {
      title: 'Trip Advance',
      dataIndex: 'advance',
      key: 'advance',
      render: text => <span className="gx-link">{text}</span>,
      sorter: (a, b) => a.advance - b.advance,
      ...this.getColumnSearchProps("advance"),
    },
    {
      title: 'Trip Expenses',
      dataIndex: 'expenses',
      key: 'expenses',
      render: text => <span className="gx-link">{text}</span>,
      sorter: (a, b) => a.expenses - b.expenses,
      ...this.getColumnSearchProps("expenses"),
    },
    {
      title: 'Generated Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => <span className="gx-link">{moment(text).format('DD-MM-YYYY HH:mm:ss')}</span>,
      sorter: (a, b) => a["createdAt"].localeCompare(b["createdAt"]),
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      align:'center',
      render: (text, record) => {
        return (
          <span style={{textAlign:'center'}}>
            {record.url ? <a target='_blank' href={`${S3_PATH_TRIPS}${record.url}`}><Button className="gx-margin-none" type="text" size={"small"} icon={<FilePdfFilled />} /></a> : null}
            {
              hasActionPermission(userAccess, FEATURE_TRIP, ACTION_DELETE) && (
                <Tooltip title="Delete">
                  <Popconfirm title="Are you sure you want to delete this trip?" onConfirm={() => this.setVisible(true, record.id)} onCancel={() => this.cancel()} okText="Yes"
                              cancelText="No">
                    <Button className="gx-margin-none" type="text" size={"small"} icon={<DeleteFilled />} />
                  </Popconfirm>
                </Tooltip>
              )
            }
          </span>
        )
      },
    },
    {
      title: 'Vehicle Number',
      dataIndex: 'LRdetails.Trips.Vehical.vehicleNumber',
      key: 'LRdetails.Trips.Vehical.vehicleNumber',
      hidden: true,
      render: text => <span className="gx-link">{text}</span>,     
      ...this.getColumnSearchProps("LRdetails.Trips.Vehical.vehicleNumber"),
    },
    {
      title: 'Balance of the trip',
      dataIndex: 'TripBalance',
      key: 'TripBalance',
      hidden: true,      
      render:(text)=> <span className="gx-link">{text}</span>,
    },
  ];
    return (
      <Card title="Trips">
      {dataExportVisible ? <DataExport
        dataExportModel={this.dataExportModel}
        visible={dataExportVisible}
        columns={columns}
        data={list}
        fileName={`Trips_${Date()}`}
      /> : null}
      <Modal
          title={`Confirm Delete`}
          centered
          visible={visible}
          onOk={() => this.confirm(selectedRecord)}
          onCancel={() => this.setVisible(false, null)}
          width={'400px'}
        >
            <TextArea rows={4} value={deleteRemark} onChange={this.onDeleteRemarkChange} />
        </Modal>
      <Row>
        <Col span={24}>
          <Link to="/main/lrdetails/trip">
            <Button
              type="primary"
              className="gx-float-right"
              style={{ marginLeft: "10px" }}
            >
              Generate Trip Details
            </Button>
          </Link>
          <Button
            type="primary"
            className="gx-float-right"
            style={{ marginRight: "10px" }}
            onClick={() => this.dataExportModel(true)}
          >
            Export Data
          </Button>
        </Col>
      </Row>
         { hasActionPermission(userAccess, FEATURE_TRIP, ACTION_VIEW) ?  <Table
            pagination={{
              onChange(current, pageSize) {
                setPage(current, pageSize);
              },
              pageSize
            }}
            bordered={true} size={'small'} className="gx-table-responsive" loading={ isFetching } 
            //columns={columns} 
            columns={columns.filter(item => !item.hidden)}  
            dataSource={list} />:null}
      </Card>
    );
  }
}

const mapStateToProps = ({ trip,user }) => {
  const { alllisturl, isFetching } = trip
  const { authUser } = user

  return { alllisturl, isFetching,authUser }
};
export default connect(mapStateToProps, {
  getAllTripUrl,
  deleteTripUrl
})(Trips);