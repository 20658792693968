import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_COUNTRIES_REQUEST',
  'GET_ALL_COUNTRIES_SUCCESS',
  'GET_ALL_COUNTRIES_FAIL',
  'GET_COUNTRY_DETAILS_REQUEST',
  'GET_COUNTRY_DETAILS_SUCCESS',
  'GET_COUNTRY_DETAILS_FAIL',
  'COUNTRY_REGISTER_REQUEST',
  'COUNTRY_REGISTER_ERROR',
  'COUNTRY_REGISTER_SUCCESS',
  'DELETE_COUNTRY_REQUEST',
  'DELETE_COUNTRY_SUCCESS',
  'DELETE_COUNTRY_FAIL',
  'COUNTRY_UPDATE_REQUEST',
  'COUNTRY_UPDATE_ERROR',
  'COUNTRY_UPDATE_SUCCESS',
  'RESET_DETAILS'
]);
