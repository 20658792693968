import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_DRIVERS_REQUEST',
  'GET_ALL_DRIVERS_SUCCESS',
  'GET_ALL_DRIVERS_FAIL',
  'GET_DRIVER_DETAILS_REQUEST',
  'GET_DRIVER_DETAILS_SUCCESS',
  'GET_DRIVER_DETAILS_FAIL',
  'DRIVER_REGISTER_REQUEST',
  'DRIVER_REGISTER_ERROR',
  'DRIVER_REGISTER_SUCCESS',
  'DELETE_DRIVER_REQUEST',
  'DELETE_DRIVER_SUCCESS',
  'DELETE_DRIVER_FAIL',
  'DRIVER_UPDATE_REQUEST',
  'DRIVER_UPDATE_ERROR',
  'DRIVER_UPDATE_SUCCESS',
  'GET_ALL_BLOODGROUPS_REQUEST',
  'GET_ALL_BLOODGROUPS_SUCCESS',
  'GET_ALL_BLOODGROUPS_FAIL',
  'GET_ALL_LICENCETYPES_REQUEST',
  'GET_ALL_LICENCETYPES_SUCCESS',
  'GET_ALL_LICENCETYPES_FAIL',
  'UPLOAD_REQUEST_FAIL',
  'UPLOAD_REQUEST_SUCCESS',
  'UPLOAD_REQUEST',
  'GET_ALL_LICENCERTO_REQUEST',
  'GET_ALL_LICENCERTO_SUCCESS',
  'GET_ALL_LICENCERTO_FAIL',
  'RESET_DETAILS',
  'DRIVER_SETTLEMENT_REQUEST',
  'DRIVER_SETTLEMENT_ERROR',
  'DRIVER_SETTLEMENT_SUCCESS',
  'GET_ALL_SETTLEMENTS_REQUEST',
  'GET_ALL_SETTLEMENTS_SUCCESS',
  'GET_ALL_SETTLEMENTS_FAIL',
]);
