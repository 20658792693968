import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_BRANCHES_REQUEST',
  'GET_ALL_BRANCHES_SUCCESS',
  'GET_ALL_BRANCHES_FAIL',
  'GET_BRANCH_DETAILS_REQUEST',
  'GET_BRANCH_DETAILS_SUCCESS',
  'GET_BRANCH_DETAILS_FAIL',
  'BRANCH_REGISTER_REQUEST',
  'BRANCH_REGISTER_ERROR',
  'BRANCH_REGISTER_SUCCESS',
  'DELETE_BRANCH_REQUEST',
  'DELETE_BRANCH_SUCCESS',
  'DELETE_BRANCH_FAIL',
  'BRANCH_UPDATE_REQUEST',
  'BRANCH_UPDATE_ERROR',
  'BRANCH_UPDATE_SUCCESS',
  'RESET_DETAILS'
]);
