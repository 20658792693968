import React from 'react';
import {
    message
} from 'antd';
import reqwest from 'reqwest';
import { API_DOMAIN } from '../../../../../env';
import { connect } from "react-redux";
import {
    updateDriver,
    getDriverDetails,
    getAllBloodGroups,
    getAllVehicles,
    getAllLicenceTypes,
    upload,
    getAllLicenceRTO,
    reset
} from "../../../../../appRedux/actions";
import Edit from './edit';

class EditContainer extends React.Component {

    onFinish = values => {
        const { match: { params: { id } }, history,alllistBG: { list },alllist,allLicenceTypesList,allListLicenceRto } = this.props
        
        console.log("EditContainer values",values,"props",this.props)
        const handleSuccess = () => {
            message.success('Driver updated successfully');
            history.push('/main/master/driver/drivers');
        }
        const handleFailure = () => {
            message.error('Driver updation failed, please try again');
        }
        list.map( item => {
            if(item.bloodGroupName === values.blood_group_id) {
                values.blood_group_id = item.blood_group_id
            }
        })
        alllist.list.map( item => {
            if(item.vehicleNumber === values.vehical_id) {
                values.vehical_id = item.id
            }
        })
        allLicenceTypesList.list.map( item => {
            if(item.licenceType === values.licence_type_id) {
                values.licence_type_id = item.id
            }
        })
        allListLicenceRto.list.map( item => {
            if(item.rtoName === values.licence_rto_id) {
                values.licence_rto_id = item.id
            }
        })
        values.id = id;
        this.props.updateDriver(handleSuccess, handleFailure, values)
        this.props.getDriverDetails(id);
    }
    
      handleUpload = (fileList) => {
        const formData = new FormData();
        // fileList.forEach(file => {
          formData.append('files[]', fileList);
        // });
    
        this.setState({
          uploading: true,
        });
    
        // You can use any AJAX library you like
        reqwest({
          url: `${API_DOMAIN}/driver/upload`,
          method: 'post',
          processData: false,
          data: formData,
          success: () => {
            this.setState({
              fileList: [],
              uploading: false,
            });
            message.success('upload successfully.');
          },
          error: () => {
            this.setState({
              uploading: false,
            });
            message.error('upload failed.');
          },
        });
      };

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getDriverDetails(id);
        this.props.getAllBloodGroups();
        this.props.getAllVehicles();
        this.props.getAllLicenceTypes()
        this.props.getAllLicenceRTO()
    }
    componentWillUnmount() {
        this.props.reset();
    }
    render() {
        const { isFetching, details ,alllistBG,alllist,allLicenceTypesList,allListLicenceRto} = this.props;
        console.log("details",details,"this.props;",this.props)
        return (
            <Edit onFinish={this.onFinish} isFetching={isFetching} allListLicenceRto={allListLicenceRto} details={details} alllistBG={alllistBG} alllist={alllist} allLicenceTypesList={allLicenceTypesList} uploadFile={this.handleUpload}/>
        );
    }
};

const mapStateToProps = ({ driver,vehicle }) => {
    const { isFetching, details,alllistBG,allLicenceTypesList ,allListLicenceRto} = driver
    const { alllist } = vehicle
    return { isFetching, details,alllistBG,alllist ,allLicenceTypesList,allListLicenceRto}
};
export default connect(mapStateToProps, {
    updateDriver,
    getDriverDetails,
    getAllBloodGroups,
    getAllVehicles,
    getAllLicenceTypes,
    upload,
    getAllLicenceRTO,
    reset
})(EditContainer);