import React from "react";
import {Route, Switch} from "react-router-dom";
import Trips from "./trips";

const Country = ({match}) => (
  <Switch>
    <Route path={`${match.url}/trips`} component={Trips}/>
  </Switch>
);

export default Country;
