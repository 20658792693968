import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import User from "./user";
import Role from "./role";

const Settings = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/user`}/>
    <Route path={`${match.url}/user`} component={User}/>
    <Route path={`${match.url}/role`} component={Role}/>
  </Switch>
);

export default Settings;
