import React from "react";
import { Modal, Checkbox, DatePicker, Alert } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const CheckboxGroup = Checkbox.Group;

const DataExport = ({visible, columns, dataExportModel, data, fileName, showDateRangePicker}) => {
    const list = []
    columns.map((column, columnIndex) => {
        if(column.key !== 'index' && column.key !== 'action') {
            list.push({ label: column.title, value: columnIndex })
        }
        return null;
    })
    const [checkedList, setCheckedList] = React.useState([]);
    const [dateLowerLimit, setDateLowerLimit] = React.useState();
    const [dateUpperLimit, setDateUpperLimit] = React.useState();
    const [showDataNotAvailableAlert, setShowDataNotAvailableAlert] = React.useState(false);
    const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType })
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }
    const exportToJson = selecteddata => {
        var json = selecteddata;
        var fields = Object.keys(json[0])
        var replacer = function(key, value) { return value === null ? '' : value } 
        var csv = json.map(function(row){
        return fields.map(function(fieldName){
            return JSON.stringify(row[fieldName], replacer);
        }).join(',')
        })
        csv.unshift(fields.join(',')) // add header column
        csv = csv.join('\r\n');
        downloadFile({
          data: csv,
          fileName: fileName ? `${fileName}.csv` : 'data.csv',
          fileType: 'text/csv',
        })
    }
    const handleOk = (e) => {
        const keys = checkedList.map(checkedItem => {
            return {
                title: columns[checkedItem].title,
                key: columns[checkedItem].dataIndex
            }
        });

        let filteredData;

        if(dateLowerLimit && dateUpperLimit) {
            filteredData = data.filter(item => {
                // convert lr date to ist
                let lrDate = new Date(item.date);
                // convert lr date to moment
                let lrDateMoment = moment(lrDate);

                if(dateLowerLimit.isBefore(lrDateMoment) && dateUpperLimit.isAfter(lrDateMoment)) return true;

                return false;
            });
        }

        if(!filteredData || filteredData.length === 0) {
            setShowDataNotAvailableAlert(true);
            return;
        }

        setShowDataNotAvailableAlert(false);

        const selecteddata = filteredData
        .map((item) => {
            const obj = {};
            keys.forEach((key) => {
                obj[key.title] = item[key.key];
            });


            return obj;
        });
        exportToJson(selecteddata);
        dataExportModel(false);
    };
    const handleCancel = (e) => {
        dataExportModel(false);
    };
    return (
        <Modal
            title="Data Export"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            className="fx-data-export"
        >
            {
                showDateRangePicker && (
                    <RangePicker
                        onChange={(e) => {
                            setDateLowerLimit(e[0]);
                            setDateUpperLimit(e[1]);
                        }} 
                        style={{marginBottom: "20px"}}
                    />
                )
            }
            <CheckboxGroup options={list} value={checkedList} onChange={setCheckedList} />
            {
                showDataNotAvailableAlert && (
                    <Alert message="Data not available for selected range" type="warning" style={{marginTop: "20px"}} />
                )
            }
        </Modal>
    );
}

export default DataExport;
