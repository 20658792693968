import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Card,
    Select
} from 'antd';
const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 5 },
        lg: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        span: 24,
    },
};
const Create = (props) => {
    const { onFinish, alllist } = props;
    const [form] = Form.useForm();

    // this state has been introduced to avoid race conditions
    // while creating cities
    const [submitting, setSubmitting] = useState(false);

    const onReset = () => {
        form.resetFields();
    };
    const options = [];
    if (alllist && alllist.list) {
        for (let i = 0; i < alllist.list.length; i++) {
            options.push({
                key: alllist.list[i].id,
                value: alllist.list[i].name
            })
        }
    }
    return (
        <Card className="gx-card" title="City creation Form">
            <Form
                {...formItemLayout}
                form={form}
                name="country"
                onFinish={(e) => {
                    if(submitting) {
                        return;
                    }
                    setSubmitting(true);
                    onFinish(e);
                    return;
                }}
                scrollToFirstError
            >

                <Form.Item
                    name="state_id"
                    label="State"
                    rules={[{ required: true, message: 'Please select state.', whitespace: true }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select state"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        { options.map(d => <Option key={d.key}>{d.value}</Option>) }
                    </Select>
                </Form.Item>

                <Form.Item
                    name="name"
                    label="City name"
                    rules={[
                        { required: true, message: 'Please enter city name.', whitespace: true },
                        {
                            pattern: new RegExp("^[a-zA-Z ]*$"),
                            message: "Please enter valid city name."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item {...tailFormItemLayout} className="gx-text-center">
                    <Button type="primary" htmlType="submit" disabled={submitting}>
                        Create
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
export default Create;