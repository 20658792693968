import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Card
} from 'antd';


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 5 },
        lg: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
      span: 24,
    },
};
const Edit = (props) => {
    const { onFinish, details } = props;
    console.log("details",details)
    const [form] = Form.useForm();

    const onReset = () => {
      form.resetFields();
    };
    return (
        <Card className="gx-card" title="Country Edit Form">
            {details && details.id ? <Form
                {...formItemLayout}
                form={form}
                name="country"
                onFinish={onFinish}
                initialValues={details}
                scrollToFirstError
            >

                <Form.Item
                    name="name"
                    label="Country Name"
                    rules={[
                        { required: true, message: 'Please enter country name.', whitespace: true },
                        {
                            pattern: new RegExp("^[a-zA-Z ]*$"),
                            message: "Please enter valid country name."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item {...tailFormItemLayout} className="gx-text-center">
                    <Button type="primary" htmlType="submit">
                        Update
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                </Form.Item>
            </Form> : null }
        </Card>
    );
};
export default Edit;