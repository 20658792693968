import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    createCity,
    getAllStates
} from "../../../../../appRedux/actions";
import Create from './create';

class CreateContainer extends React.Component {

    onFinish = (values, setSubmitting) => {
        const { history, alllist: { list }, modalAction } = this.props
        const handleSuccess = () => {
            if(setSubmitting) {
                setSubmitting(false);
            }
            message.success('City created successfully');
            if(modalAction) {
                modalAction();
            } else {
                history.push('/main/master/city/cities');
            }
        }
        const handleFailure = () => {
            if(setSubmitting) {
                setSubmitting(false);
            }
            message.error('City creation failed, please try again');
        }
        list.map( item => {
            if(item.name === values.state_id) {
                values.state_id = item.id
            }
        })
        this.props.createCity(handleSuccess, handleFailure, values)
    }
    componentDidMount() {
      this.props.getAllStates();
    }
    render() {
        const { isFetching, alllist } = this.props;
        return (
            <Create onFinish={this.onFinish} isFetching={isFetching} alllist={alllist} />
        );
    }
};

const mapStateToProps = ({ city, state }) => {
    const { alllist } = state
    const { isFetching } = city
    return { isFetching, alllist }
};
export default connect(mapStateToProps, {
    createCity,
    getAllStates
})(CreateContainer);