import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Card,
    Select,
} from 'antd';
import Pattern from '../../../../../constants/Pattern'

const { Option } = Select
const { TextArea } = Input;


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 5 },
        lg: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
      span: 24,
    },
};
const Create = (props) => {
    const { onFinish ,alllist} = props;
    const [form] = Form.useForm();

    const onReset = () => {
      form.resetFields();
    };
    const options = [];
    if (alllist && alllist.list) {
        for (let i = 0; i < alllist.list.length; i++) {
            options.push({
                key: alllist.list[i].id,
                value: alllist.list[i].name
            })
        }
    }
    console.log("options",options)
    return (
        <Card className="gx-card" title="Broker creation Form">
            <Form
                {...formItemLayout}
                form={form}
                name="broker"
                onFinish={onFinish}
                scrollToFirstError
            >

                <Form.Item
                label="Name"
                name="name"         
                rules={[
                    { required: true, message: 'Please enter name.',whitespace:true },
                    {
                        pattern: new RegExp(Pattern.FULLNAME),
                        message: "Please enter valid name."
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="Address"
                name="address"
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="City"
                name="cityId"         
                rules={[{ required: true, message: 'Please select city.' , whitespace: true  }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        { options.map(d => <Option key={d.key}>{d.value}</Option>) }
                    </Select>
                </Form.Item>
                <Form.Item
                label="Pin Code"
                name="pinCode"         
                rules={[
                    { required: false, message: 'Please enter pin code.',whitespace:true },
                    {
                        pattern: new RegExp(Pattern.PIN_CODE),
                        message: "Please enter valid  pin code."
                    },
                ]}
                >
                <Input min="0" style={{ width: '100%' }}/>
                </Form.Item>
                <Form.Item
                label="Contact Number"
                name="contactNumber"         
                rules={[
                    { required: true, message: 'Please enter phone number.',whitespace:true },
                    {
                        pattern: new RegExp(Pattern.PHONE_NUMBER),
                        message: "Please enter valid phone code."
                    },
                ]}
                >
                <Input min="0" style={{ width: '100%' }}/>
                </Form.Item>
                <Form.Item
                label="Contact Name"
                name="contactName"         
                rules={[
                    { required: false, message: 'Please enter contact name.',whitespace:true },
                    {
                        pattern: new RegExp(Pattern.FULLNAME),
                        message: "Please enter valid contact Name."
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="PAN Number"
                name="pan"
                rules={[
                    { required: false, message: 'Please input your PAN number.' , whitespace: true  },
                    {
                        pattern: new RegExp(Pattern.PAN_NO),
                        message: "Please enter valid PAN number(ex.ALWPG5809L)."
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="GST Number"
                name="GSTNumber"
                rules={[
                    { required: false, message: 'Please input your GST number.' , whitespace: true  },
                    {
                        pattern: new RegExp(Pattern.GST),
                        message: "Please enter valid GST number."
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="Email Address"
                name="emailAddress"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail.',
                    },
                    {
                        required: false,
                        message: 'Please input your E-mail.',
                    }]}
                    >
                <Input />
                </Form.Item>
                <Form.Item
                    label="Remarks"
                    name="remarks"
                    rules={[{ required: false, message: 'Please enter remarks.', whitespace: true, }]}
                >
                    <TextArea rows={3} />
                </Form.Item>
              

                <Form.Item {...tailFormItemLayout} className="gx-text-center">
                    <Button type="primary" htmlType="submit">
                        Create
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
export default Create;