import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { hasActionPermission } from "../../util/rolesHelper";
import {
  ACTION_VIEW,
  FEATURE_CITY,
  FEATURE_ROLE,
  FEATURE_TRIP,
  FEATURE_USER,
  FEATURE_STATE,
  FEATURE_BRANCH,
  FEATURE_DRIVER,
  FEATURE_BROKER,
  FEATURE_VEHICLE,
  FEATURE_TRANSIT,
  FEATURE_COUNTRY,
  FEATURE_INVOICE,
  FEATURE_CUSTOMER,
  FEATURE_DASHBOARD,
  FEATURE_LR_DETAILS,
} from "../../constants/Roles";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({
  authUser,
  sidebarCollapsed,
  setSidebarCollapsed,
}) => {
  let userAccess = [];
  if (
    authUser &&
    authUser.details &&
    authUser.details.Role &&
    authUser.details.Role
  ) {
    userAccess = JSON.parse(authUser.details.Role.access);
  }
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);

  const getNoHeaderClass = (navStyle) => {
    // if (
    //   navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
    //   navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    // ) {
    //   return "gx-no-header-notifications";
    // }
    // return "";

    //To show side menu logo
    return "gx-no-header-notifications";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const showMaster = hasActionPermission(userAccess, FEATURE_COUNTRY, ACTION_VIEW) ||
    hasActionPermission(userAccess, FEATURE_STATE, ACTION_VIEW) ||
    hasActionPermission(userAccess, FEATURE_CITY, ACTION_VIEW) ||
    hasActionPermission(userAccess, FEATURE_BRANCH, ACTION_VIEW) ||
    hasActionPermission(userAccess, FEATURE_CUSTOMER, ACTION_VIEW) ||
    hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_VIEW) ||
    hasActionPermission(userAccess, FEATURE_DRIVER, ACTION_VIEW) ||
    hasActionPermission(userAccess, FEATURE_BROKER, ACTION_VIEW);
  
  const showSettings = hasActionPermission(userAccess, FEATURE_USER, ACTION_VIEW) ||
    hasActionPermission(userAccess, FEATURE_ROLE, ACTION_VIEW);
  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          {/* <UserProfile/>
          <AppsNavigation/> */}
          <Link to="/" className="gx-site-logo">
            <img
              style={{ marginLeft: "5%" }}
              alt="logo2"
              width="90%"
              src="/assets/images/logo.jpg"
            />
          </Link>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <MenuItemGroup key="main" className="gx-menu-group" title={""}>
              {hasActionPermission(userAccess, FEATURE_DASHBOARD, ACTION_VIEW) ? (
                <Menu.Item key="main/dashboard/dashboard">
                  <Link to="/main/dashboard/dashboard">
                    <i className="icon icon-dasbhoard" />
                    <span>
                      <IntlMessages id="sidebar.dashboard" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}

              {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_VIEW) ? (
                <Menu.Item key="main/lrdetails/lrdetails">
                  <Link to="/main/lrdetails/lrdetails">
                    <i className="icon icon-product-list" />
                    <span>
                      <IntlMessages id="sidebar.lrdetails" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}

              {hasActionPermission(userAccess, FEATURE_TRANSIT, ACTION_VIEW) ? (
                <Menu.Item key="main/transit/transit">
                  <Link to="/main/transit/transit">
                    <i className="icon icon-map-simple" />
                    <span>
                      <IntlMessages id="sidebar.transit" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}

              {hasActionPermission(userAccess, FEATURE_TRIP, ACTION_VIEW) ? (
                <Menu.Item key="main/trip/trips">
                  <Link to="/main/trip/trips">
                    <i className="icon icon-table-data" />
                    <span>
                      <IntlMessages id="sidebar.trips" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}

              {hasActionPermission(userAccess, FEATURE_INVOICE, ACTION_VIEW) ? (
                <Menu.Item key="main/invoice/invoices">
                  <Link to="/main/invoice/invoices">
                    <i className="icon icon-pricing-table" />
                    <span>
                      <IntlMessages id="sidebar.invoices" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}

              {
                showSettings && (
                  <SubMenu
                    key="settings"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-setting" />
                        <span>
                          <IntlMessages id="sidebar.settings" />
                        </span>
                      </span>
                    }
                  >
                    {hasActionPermission(userAccess, FEATURE_USER, ACTION_VIEW) ? (
                      <Menu.Item key="main/settings/user/users">
                        <Link to="/main/settings/user/users">
                          <i className="icon icon-auth-screen" />
                          <span>
                            <IntlMessages id="sidebar.settings.users" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {hasActionPermission(userAccess, FEATURE_ROLE, ACTION_VIEW) ? (
                      <Menu.Item key="main/settings/role/roles">
                        <Link to="/main/settings/role/roles">
                          <i className="icon icon-auth-screen" />
                          <span>
                            <IntlMessages id="sidebar.settings.roles" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                )
              }

              {
                showMaster && (
                  <SubMenu
                    key="master"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-table" />
                        <span>
                          <IntlMessages id="sidebar.master" />
                        </span>
                      </span>
                    }
                  >
                    {hasActionPermission(userAccess, FEATURE_COUNTRY, ACTION_VIEW) ? (
                      <Menu.Item key="main/master/country/countries">
                        <Link to="/main/master/country/countries">
                          <i className="icon icon-location" />
                          <span>
                            <IntlMessages id="sidebar.master.countries" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {hasActionPermission(userAccess, FEATURE_STATE, ACTION_VIEW) ? (
                      <Menu.Item key="main/master/state/states">
                        <Link to="/main/master/state/states">
                          <i className="icon icon-location" />
                          <span>
                            <IntlMessages id="sidebar.master.states" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {hasActionPermission(userAccess, FEATURE_CITY, ACTION_VIEW) ? (
                      <Menu.Item key="main/master/city/cities">
                        <Link to="/main/master/city/cities">
                          <i className="icon icon-location" />
                          <span>
                            <IntlMessages id="sidebar.master.cities" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {hasActionPermission(userAccess, FEATURE_BRANCH, ACTION_VIEW) ? (
                      <Menu.Item key="main/master/branch/branches">
                        <Link to="/main/master/branch/branches">
                          <i className="icon icon-company" />
                          <span>
                            <IntlMessages id="sidebar.master.branches" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {hasActionPermission(userAccess, FEATURE_CUSTOMER, ACTION_VIEW) ? (
                      <Menu.Item key="main/master/customer/customers">
                        <Link to="/main/master/customer/customers">
                          <i className="icon icon-user" />
                          <span>
                            <IntlMessages id="sidebar.master.customers" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_VIEW) ? (
                      <Menu.Item key="main/master/vehicle/vehicles">
                        <Link to="/main/master/vehicle/vehicles">
                          <i className="icon icon-transfer" />
                          <span>
                            <IntlMessages id="sidebar.master.vehicles" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {hasActionPermission(userAccess, FEATURE_DRIVER, ACTION_VIEW) ? (
                      <Menu.Item key="main/master/driver/drivers">
                        <Link to="/main/master/driver/drivers">
                          <i className="icon icon-profile" />
                          <span>
                            <IntlMessages id="sidebar.master.drivers" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {hasActionPermission(userAccess, FEATURE_BROKER, ACTION_VIEW) ? (
                      <Menu.Item key="main/master/broker/brokers">
                        <Link to="/main/master/broker/brokers">
                          <i className="icon icon-profile" />
                          <span>
                            <IntlMessages id="sidebar.master.brokers" />
                          </span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                )
              }
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
