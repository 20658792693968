import React from "react";
import { Button, Card, Row, Col, Table, Form, Input, Checkbox, message } from "antd";
import { SnippetsFilled, EditFilled, DeleteFilled } from '@ant-design/icons';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';
import {
  getAllInvoices,
  deleteLrDetails,
  updateRole,
  getRoleDetails
} from "../../../../../appRedux/actions";

class LrDetails extends React.Component {
  state = {
    tableConfig: {
    },
    listSet: false,
    detailsRole: {},
    list: [{
      key: 0,
      feature: 'Dashboard',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 1,
      feature: 'Transit',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 2,
      feature: 'LR Details',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 3,
      feature: 'Invoice',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 4,
      feature: 'Trip',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 5,
      feature: 'User',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 6,
      feature: 'Role',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 7,
      feature: 'Country',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 8,
      feature: 'State',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 9,
      feature: 'City',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 10,
      feature: 'Branch',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 11,
      feature: 'Customer',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 12,
      feature: 'Vehicle',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 13,
      feature: 'Driver',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    },{
      key: 14,
      feature: 'Broker',
      view: 0,
      create: 0,
      edit: 0,
      delete: 0
    }],
  };
  columns = [
  {
    title: 'Feature',
    dataIndex: 'feature',
    key: 'feature',
    render: text => <span className="gx-link">{text}</span>,
  },
  {
    title: 'View',
    dataIndex: 'view',
    key: 'view',
    width: 150,
    render: (text, record) => <Checkbox checked={!!text} onChange={() => this.onChange(record, 'view')}></Checkbox>,
  },
  {
    title: 'Create',
    dataIndex: 'create',
    key: 'create',
    width: 150,
    render: (text, record) => <Checkbox checked={!!text} onChange={() => this.onChange(record, 'create')}></Checkbox>,
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    key: 'edit',
    width: 150,
    render: (text, record) => <Checkbox checked={!!text} onChange={() => this.onChange(record, 'edit')}></Checkbox>,
  },
  {
    title: 'Delete',
    dataIndex: 'delete',
    key: 'delete',
    width: 150,
    render: (text, record) => <Checkbox checked={!!text} onChange={() => this.onChange(record, 'delete')}></Checkbox>,
  }];

  componentDidMount() {
    const { match: { params: { id } } } = this.props
    this.props.getRoleDetails(id);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.listSet && nextProps.detailsRole && nextProps.detailsRole.access && prevState.detailsRole !== nextProps.detailsRole) {
      return {
        list: JSON.parse(nextProps.detailsRole.access),
        listSet: true,
        detailsRole: nextProps.detailsRole
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  onFinish = (values) => {
  }

  onFinish = values => {
      const { history, match: { params: { id } } } = this.props
      const { list } = this.state;
      const handleSuccess = () => {
          message.success('Role updated successfully');
          history.push('/main/settings/role/roles');
      }
      const handleFailure = () => {
          message.error('Role updation failed, please try again');
      }
      values.id = id;
      values.access = JSON.stringify(list);
      this.props.updateRole(handleSuccess, handleFailure, values)
  }

  onChange = (record, key) => {
    let { list } = this.state;
    let listObj = list[record.key];
    if(listObj[key]) {
      listObj[key] = 0
    } else {
      listObj[key] = 1
    }
    list[record.key] = {...listObj};
    console.log("listObj>>>", listObj);
    this.setState({ 
      list: [...list]
    });
  }

  render() {
    const { tableConfig, list, detailsRole } = this.state;
    const { isFetching } = this.props;
    return (
      <Card title="Role">
        <Row>
          <Col span={24}>
          {detailsRole && detailsRole.role ? <Form
            name="role"
            layout="inline"
            onFinish={this.onFinish}
            initialValues={detailsRole}
          >
            <Form.Item name="role" label="Role name"
                rules={[
                    { required: true, message: 'Please enter role name.', whitespace:true }
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form> : null }
          </Col>
        </Row>
        <Table pagination={false} bordered={false} size={'middle'} className="gx-table-responsive" loading={ isFetching } columns={this.columns} dataSource={list} />
      </Card>
    );
  }
}

const mapStateToProps = ({ user }) => {
  const { alllist, isFetching, detailsRole } = user
  return { alllist, isFetching, detailsRole }
};
export default connect(mapStateToProps, {
  getAllInvoices,
  deleteLrDetails,
  updateRole,
  getRoleDetails
})(LrDetails);