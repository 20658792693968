import React from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Tooltip,
  Table,
  Divider,
  Tag,
  Popconfirm,
  message,
  Input,
  Badge,
  Menu,
  Dropdown,
  Popover,
  Space,
  Modal,
} from "antd";
import {
  SnippetsFilled,
  EditFilled,
  DeleteFilled,
  ReloadOutlined,
  FilePdfFilled,
  DownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import {
  getAllLrDetails,
  deleteLrDetails,
  updateLrDetails,
} from "../../../../appRedux/actions";
import { S3_PATH_LR } from "../../../../env";
import DataExport from "../../../components/feedback/Export/DataExport";
import { hasActionPermission } from "../../../../util/rolesHelper";
import { ACTION_CREATE, ACTION_DELETE, ACTION_EDIT, ACTION_VIEW, FEATURE_LR_DETAILS } from "../../../../constants/Roles";

const { Search } = Input;
const { TextArea } = Input;

class LrDetails extends React.Component {
  state = {
    tableConfig: {},
    page: 1,
    pageSize: 50,
    filteredInfo: null,
    sortedInfo: null,
    visible: false,
    selectedRecord: null,
    deleteRemark: "",
    filteredData: [],
    dataExportVisible: false
  };

  componentDidMount() {
    const data = {
      paranoid: true,
    };
    this.props.getAllLrDetails(data);
  }
  onSearch = (searchText) => {
    const data = {
      searchText: searchText,
      paranoid: true,
    };
    this.props.getAllLrDetails(data);
  };
  cancel = () => { };
  confirm = (id) => {
    const { deleteRemark } = this.state;
    const data = {
      deleteRemarks: deleteRemark,
    };
    const handleSuccess = () => {
      message.success("LR details deleted successfully");
      const data = {
        paranoid: true,
      };
      this.props.getAllLrDetails(data);
      this.setState({ visible: false, selectedRecord: null, deleteRemark: "" });
    };
    const handleFailure = () => {
      message.error("LR details deletion failed, please try again");
    };
    this.props.deleteLrDetails(handleSuccess, handleFailure, id, "", data);
  };
  reactivate = (id) => {
    const data = {
      id: id,
      deleted_at: null,
      deletedAt: null,
    };
    const handleSuccess = () => {
      message.success("LR details reactivated successfully");
      const data = {
        paranoid: true,
      };
      this.props.getAllLrDetails(data);
    };
    const handleFailure = () => {
      message.error("LR details reactivation failed, please try again");
    };
    this.props.updateLrDetails(handleSuccess, handleFailure, data);
  };

  setPage = (page, pageSize) => {
    this.setState({ page, pageSize });
  };
  handleChange = (pagination, filters, sorter, extra) => {
    console.log("Various parameters", pagination, filters, sorter, extra);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      filteredData: extra.currentDataSource,
    });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  setVisible = (visible, selectedRecord) => {
    this.setState({ visible, selectedRecord });
  };
  onDeleteRemarkChange = (event) => {
    this.setState({ deleteRemark: event.target.value });
  };
  dataExportModel = (dataExportVisible) => {
    this.setState({dataExportVisible})
  }
  render() {
    const { setPage } = this;
    const { tableConfig, pageSize, visible, selectedRecord, deleteRemark, dataExportVisible } =
      this.state;
    const {
      isFetching,
      alllist: { list },
      authUser,
    } = this.props;
    let userAccess = [];
    if (
      authUser &&
      authUser.details &&
      authUser.details.Role &&
      authUser.details.Role
    ) {
      userAccess = JSON.parse(authUser.details.Role.access);
    }
    let newList = list;
    const columns = [
      {
        title: "ID",
        dataIndex: "index",
        key: "index",
        width: 50,
        render: (value, record, index) => (
          <span className="gx-link">
            {(this.state.page - 1) * this.state.pageSize + index + 1}
          </span>
        ),
      },
      {
        title: "LR Number",
        dataIndex: "number",
        key: "number",
        render: (text, record, index) => {
          const content = (
            <div style={{ padding: "10px" }}>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <strong>Consignor Contact:</strong>{" "}
                  {record["consignor.contact_number"]}
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <strong>Vehicle number:</strong>{" "}
                  {record["vehicleNumber"]
                    ? record["vehicleNumber"]
                    : record["Vehical.vehicleNumber"]}
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <strong>Driver name:</strong>{" "}
                  {record["driverName"]
                    ? record["driverName"]
                    : `${record["Driver.first_name"]} ${record["Driver.last_name"]}`}
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <strong>Driver number:</strong>{" "}
                  {record["contactNumber"]
                    ? record["contactNumber"]
                    : record["Driver.contactNumber"]}
                </Col>
              </Row>
            </div>
          );
          return (
            <Popover content={content} title={"Info"}>
              <Button type="text">
                <span className="gx-link">{text}</span>
              </Button>
            </Popover>
          );
        },
        sorter: (a, b) => a.number - b.number,
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (text) => (
          <span className="gx-link">{moment(text).format("DD-MM-YYYY")}</span>
        ),
        sorter: (a, b) => a["date"].localeCompare(b["date"]),
      },
      {
        title: "Consignee Name",
        dataIndex: "consignee.name",
        key: "consignee.name",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("consignee.name"),
        sorter: (a, b) =>
          a["consignee.name"].localeCompare(b["consignee.name"]),
      },
      {
        title: "Consignor Name",
        dataIndex: "consignor.name",
        key: "consignor.name",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("consignor.name"),
        sorter: (a, b) =>
          a["consignor.name"].localeCompare(b["consignor.name"]),
      },
      {
        title: "Origin",
        dataIndex: "origin_city.name",
        key: "origin_city.name",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("origin_city.name"),
        sorter: (a, b) =>
          a["origin_city.name"].localeCompare(b["origin_city.name"]),
      },
      {
        title: "Destination",
        dataIndex: "destination_city.name",
        key: "destination_city.name",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("destination.name"),
        sorter: (a, b) =>
          a["destination.name"].localeCompare(b["destination.name"]),
      },
      {
        title: "Status",
        dataIndex: "Deliverystatus.status",
        key: "Deliverystatus.status",
        render: (text, record) => (
          <>
            <Tag color="#2db7f5">{text}</Tag>
            {record.trip_id ? <Tag color="warning">Trip</Tag> : null}
          </>
        ),
        filters: [
          { text: "Package Picked", value: "Package Picked" },
          { text: "Dispatched", value: "Dispatched" },
          { text: "In-Transit", value: "In-Transit" },
          { text: "Delivered", value: "Delivered" },
          { text: "Invoiced", value: "Invoiced" },
        ],
        ...this.getColumnSearchProps("Deliverystatus.status"),
        sorter: (a, b) =>
          a["Deliverystatus.status"].localeCompare(b["Deliverystatus.status"]),
      },
      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (text) => (
          <span className="gx-link">{moment(text).format("DD-MM-YYYY")}</span>
        ),
        sorter: (a, b) => a["date"].localeCompare(b["date"]),
      },
      {
        title: "Action",
        key: "action",
        width: 150,
        align: "center",
        render: (text, record) => {
          const { authUser } = this.props;
          let userAccess = [];
          if (
            authUser &&
            authUser.details &&
            authUser.details.Role &&
            authUser.details.Role
          ) {
            userAccess = JSON.parse(authUser.details.Role.access);
          }
          const menu = (
            <Menu>
              <Menu.Item key="0">
                <a
                  target="_blank"
                  href={`${S3_PATH_LR}${record.number}_consignor.pdf`}
                >
                  Consignor Copy
                </a>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="1">
                <a
                  target="_blank"
                  href={`${S3_PATH_LR}${record.number}_consignee.pdf`}
                >
                  Consignee Copy
                </a>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="1">
                <a
                  target="_blank"
                  href={`${S3_PATH_LR}${record.number}_driver.pdf`}
                >
                  Driver Copy
                </a>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="1">
                <a
                  target="_blank"
                  href={`${S3_PATH_LR}${record.number}_accounts.pdf`}
                >
                  Accounts Copy
                </a>
              </Menu.Item>
            </Menu>
          );
          return (
            <span style={{ textAlign: "center" }}>
              {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_DELETE) &&
                record.deleted_at ? (
                <Tooltip title="Reactivate">
                  <Popconfirm
                    title="Are you sure you want to reactivate this LR Details?"
                    onConfirm={() => this.reactivate(record.id)}
                    onCancel={() => this.cancel()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className="gx-margin-none"
                      type="text"
                      size={"small"}
                      icon={<ReloadOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
              ) : (
                <>
                  {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_VIEW) ? (
                    <Tooltip title="View">
                      <Link to={`/main/lrdetails/view/${record.id}`}>
                        <Button
                          className="gx-margin-none"
                          type="text"
                          size={"small"}
                          icon={<SnippetsFilled />}
                        />
                      </Link>
                    </Tooltip>
                  ) : null}
                  {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_EDIT) ? (
                    <Tooltip title="Edit">
                      <Link to={`/main/lrdetails/edit/${record.id}`}>
                        <Button
                          className="gx-margin-none"
                          type="text"
                          size={"small"}
                          icon={<EditFilled />}
                        />
                      </Link>
                    </Tooltip>
                  ) : null}

                  {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_DELETE) &&
                    !record.deleted_at &&
                    (record.status_id == 1 || record.status_id == 2) ? (
                    <>
                      <Tooltip title="Delete">
                        <Popconfirm
                          title="Are you sure you want to delete this LR Details?"
                          onConfirm={() => this.setVisible(true, record.id)}
                          onCancel={() => this.cancel()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            className="gx-margin-none"
                            type="text"
                            size={"small"}
                            icon={<DeleteFilled />}
                          />
                        </Popconfirm>
                      </Tooltip>{" "}
                    </>
                  ) : null}
                  <Dropdown overlay={menu} trigger={["click", "hover"]}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <FilePdfFilled /> <DownOutlined />
                    </a>
                  </Dropdown>
                </>
              )}
            </span>
          );
        },
      },
      {
        title: "Freight Amount",
        dataIndex: "amount",
        hidden: true,        
        key: "amount",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("amount"),        
      },
      {
        title: "Broker Name",
        dataIndex: "Broker.name",
        hidden: true,        
        key: "Broker.name",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("Broker.name"),        
      },
      {
        title: "Own Vehicle Number",
        dataIndex: "Vehical.vehicleNumber",
        hidden: true,        
        key: "Vehical.vehicleNumber",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("Vehical.vehicleNumber"),        
      },
      {
        title: "Hire Vehicle Number",
        dataIndex: "vehicleNumber",
        hidden: true,        
        key: "vehicleNumber",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("vehicleNumber"),        
      },
    ];
    return (
      <Card title="LR Details">
        {dataExportVisible ? <DataExport
          dataExportModel={this.dataExportModel}
          visible={dataExportVisible}
          columns={columns}
          data={newList}
          fileName={`LRDetails_${Date()}`}
          showDateRangePicker={true}
        /> : null}
        <Modal
          title={`Confirm Delete`}
          centered
          visible={visible}
          onOk={() => this.confirm(selectedRecord)}
          onCancel={() => this.setVisible(false, null)}
          width={"400px"}
        >
          <TextArea
            rows={4}
            value={deleteRemark}
            onChange={this.onDeleteRemarkChange}
          />
        </Modal>
        <Row>
          <Col span={24}>
            <Link to="/main/lrdetails/invoice">
              <Button
                type="primary"
                className="gx-float-right"
                style={{ marginLeft: "10px" }}
              >
                Generate Invoice
              </Button>
            </Link>
            <Link to="/main/lrdetails/trip">
              <Button
                type="primary"
                className="gx-float-right"
                style={{ marginLeft: "10px" }}
              >
                Generate Trip Details
              </Button>
            </Link>
            {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_CREATE) ? (
              <Link to="/main/lrdetails/create">
                <Button type="primary" className="gx-float-right">
                  Add LR Details
                </Button>
              </Link>
            ) : null}
            <Button
              type="primary"
              className="gx-float-right"
              style={{ marginRight: "10px" }}
              onClick={() => this.dataExportModel(true)}
            >
              Export Data
            </Button>
          </Col>
        </Row>
        {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_VIEW) ? (
          <Table
            pagination={{
              onChange(current, pageSize) {
                setPage(current, pageSize);
              },
              pageSize,
            }}
            onChange={this.handleChange}
            bordered={true}
            size={"small"}
            className="gx-table-responsive"
            loading={isFetching}
            //columns={columns}
            columns={columns.filter(item => !item.hidden)}
            dataSource={newList}
            exportable
            exportableProps={{ showColumnPicker: true }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = ({ lrdetails, user }) => {
  const { alllist, isFetching } = lrdetails;
  const { authUser } = user;
  return { alllist, isFetching, authUser };
};
export default connect(mapStateToProps, {
  getAllLrDetails,
  deleteLrDetails,
  updateLrDetails,
})(LrDetails);
