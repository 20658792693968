import Constants from '../../constants/Financer';

const INITIAL_STATE = {
  data: [],
  allFinanceList: [],
  details: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {    
    case Constants.GET_ALL_FINANCER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_FINANCER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        allFinanceList: action.payload,
      };
    case Constants.GET_ALL_FINANCER_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
   
    case Constants.FINANCER_REGISTER_REQUEST:
        return { 
            ...state, 
            isFetching: true 
        };
    case Constants.FINANCER_REGISTER_ERROR:
        return {
              ...state,
              isFetching: false 
        };
    case Constants.FINANCER_REGISTER_SUCCESS:
        return {
              ...state, 
              isFetching: false 
        };
   
    default:
      return state;
  }
};
