import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_STATUS_COUNT_REQUEST',
  'GET_ALL_STATUS_COUNT_SUCCESS',
  'GET_ALL_STATUS_COUNT_FAIL',
  'GET_ALL_STATUS_COUNT_BY_REQUEST',
  'GET_ALL_STATUS_COUNT_BY_SUCCESS',
  'GET_ALL_STATUS_COUNT_BY_FAIL',
  'GET_MONTHLY_REVENUE_REQUEST',
  'GET_MONTHLY_REVENUE_SUCCESS',
  'GET_MONTHLY_REVENUE_FAIL',
  'GET_MONTHLY_REVENUE_VEHICLE_REQUEST',
  'GET_MONTHLY_REVENUE_VEHICLE_SUCCESS',
  'GET_MONTHLY_REVENUE_VEHICLE_FAIL',
]);
