import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    createVehicle,
    getAllVehicaltypes,
    getAllFueltanks,
    getAllPurchaseagencys,
    getAllFinancer,
    getAllBodyManufacturer,
    getAllManufacturer
} from "../../../../../appRedux/actions";
import Create from './create';

class CreateContainer extends React.Component {

    onFinish = values => {
        console.log("values",values)
        const { history,alllistPAlist,alllistFTlist,alllistVTlist, modalAction } = this.props
        const handleSuccess = () => {
            message.success('Vehicle created successfully');
            if(modalAction) {
                modalAction();
            } else {
                history.push('/main/master/vehicle/vehicles');
            }
        }
        const handleFailure = () => {
            message.error('Vehicle creation failed, please try again');
        }

        if (alllistFTlist && alllistFTlist.list) {
            alllistFTlist.list.map( item => {
                if(item.capacity === values.fuel_tank_id) {
                    values.fuel_tank_id = item.id
                }
            })
        }
        if (alllistPAlist && alllistPAlist.list) {
            alllistPAlist.list.map( item => {
                if(item.agencyName === values.purchase_agency_id) {
                    values.purchase_agency_id = item.id
                }
            })
        }
        if (alllistVTlist && alllistVTlist.list) {
            alllistVTlist.list.map( item => {
                if(item.vehicalType === values.vehical_type_id) {
                    values.vehical_type_id = item.id
                }
            })
        }

        this.props.createVehicle(handleSuccess, handleFailure, values)
    }
    componentDidMount() {
        this.props.getAllVehicaltypes();
        this.props.getAllFueltanks();
        this.props.getAllFinancer();
        this.props.getAllPurchaseagencys();
        this.props.getAllBodyManufacturer();
        this.props.getAllManufacturer();

        
    }
    
    render() {
        const { isFetching,alllistPAlist,alllistFTlist,alllistVTlist,allFinanceList,alllManufacturer,alllBodyManufacturer } = this.props;
        console.log("this.props;",allFinanceList,alllManufacturer,alllBodyManufacturer)
        return (
            <Create onFinish={this.onFinish} isFetching={isFetching}  alllistPAlist={alllistPAlist} alllistFTlist={alllistFTlist} alllistVTlist={alllistVTlist}
            allFinanceList={allFinanceList}
            alllManufacturer={alllManufacturer}
            alllBodyManufacturer={alllBodyManufacturer}
            />
        );
    }
};

const mapStateToProps = ({ vehicle,financer,manufacturer,bodyManufacturer }) => {
    const { isFetching,alllistPAlist,alllistFTlist,alllistVTlist } = vehicle
    const {allFinanceList} = financer
    const {alllManufacturer} = manufacturer
    const {alllBodyManufacturer} = bodyManufacturer

    return { isFetching,alllistPAlist,alllistFTlist,alllistVTlist,allFinanceList,alllBodyManufacturer,alllManufacturer }
};
export default connect(mapStateToProps, {
    createVehicle,
    getAllVehicaltypes,
    getAllFueltanks,
    getAllPurchaseagencys,
    getAllFinancer,
    getAllBodyManufacturer,
    getAllManufacturer
})(CreateContainer);