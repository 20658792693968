export const FEATURE_TRIP = "Trip";
export const FEATURE_USER = "User";
export const FEATURE_ROLE = "Role";
export const FEATURE_CITY = "City";
export const FEATURE_STATE = "State";
export const FEATURE_BRANCH = "Branch";
export const FEATURE_BROKER = "Broker";
export const FEATURE_DRIVER = "Driver";
export const FEATURE_INVOICE = "Invoice";
export const FEATURE_COUNTRY = "Country";
export const FEATURE_VEHICLE = "Vehicle";
export const FEATURE_TRANSIT = "Transit";
export const FEATURE_CUSTOMER = "Customer";
export const FEATURE_DASHBOARD = "Dashboard";
export const FEATURE_LR_DETAILS = "LR Details";

export const ACTION_EDIT = "edit";
export const ACTION_VIEW = "view";
export const ACTION_CREATE = "create";
export const ACTION_DELETE = "delete";