import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_LRDETAIL_REQUEST',
  'GET_ALL_LRDETAIL_SUCCESS',
  'GET_ALL_LRDETAIL_FAIL',
  'GET_LRDETAIL_REQUEST',
  'GET_LRDETAIL_SUCCESS',
  'GET_LRDETAIL_FAIL',
  'LRDETAIL_REGISTER_REQUEST',
  'LRDETAIL_REGISTER_ERROR',
  'LRDETAIL_REGISTER_SUCCESS',
  'DELETE_LRDETAIL_REQUEST',
  'DELETE_LRDETAIL_SUCCESS',
  'DELETE_LRDETAIL_FAIL',
  'LRDETAIL_UPDATE_REQUEST',
  'LRDETAIL_UPDATE_ERROR',
  'LRDETAIL_UPDATE_SUCCESS',
  'GET_ALL_DELIVERYSTATUS_REQUEST',
  'GET_ALL_DELIVERYSTATUS_SUCCESS',
  'GET_ALL_DELIVERYSTATUS_FAIL',
  'CLEAR_ALL_LRDETAIL',
  'RESET_DETAILS',
  'GET_LRDETAIL_DUPLICATE_REQUEST',
  'GET_LRDETAIL_DUPLICATE_SUCCESS',
  'GET_LRDETAIL_DUPLICATE_FAIL',
  'GET_LRDETAIL_NUMBER_REQUEST',
  'GET_LRDETAIL_NUMBER_SUCCESS',
  'GET_LRDETAIL_NUMBER_FAIL',
]);
