import React, { useState } from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import _ from 'lodash';
import moment from 'moment'
import {
    getLRNumber,
    getAllLrDetails,
    createLrDetails,
    getAllCities,
    getAllStates,
    getAllBrokers,
    getAllCustomers,
    getAllVehicles,
    getAllDrivers,
    getAllDeleveryStatus,
    createTrip,
    checkDuplicate,
    getAllBranches,
    deleteLrDetails
} from "../../../../appRedux/actions";
import Create from './create';
import Grid from './grid';

class CreateContainer extends React.Component {
    state = {
        branchValue: '',
        vehicleValue: '',
        brokerValue: '',
        isPreview: false,
        view: 'list'
    };

    onFinish = values => {
        const { history, alllistCity, alllistState,alllistBroker,alllistDS,alllistCustomer,alllistVehicle,alllistDriver } = this.props
        const handleSuccess = (response) => {
            message.success('LR Details created successfully');
            this.setState({ isPreview: true });
        }
        const handleFailure = () => {
            message.error('LR Details creation failed, please try again');
        }

        let originCityObj = _.find(alllistCity.list, ['id', parseInt(values.originCityId)]);
        values.originStateId = originCityObj.state_id
        let destinationCityObj = _.find(alllistCity.list, ['id', parseInt(values.destinationCityId)]);
        values.destinationStateId = destinationCityObj.state_id

        alllistDS.list.map( item => {
            if(item.status === values.statusId) {
                values.statusId = item.id
            }
        })
        alllistDriver.list.map( item => {
            let value= `${item.first_name} ${item.last_name}`
            if(value === values.driverId) {
                values.driverId = item.id
            }
        })
        alllistVehicle.list.map( item => {
            if(item.vehicleNumber === values.vehicalId) {
                values.vehicalId = item.id
            }
        })
        alllistBroker.list.map( item => {
            if(item.name === values.brokerId) {
                values.brokerId = item.id
            }
        })
        alllistCustomer.list.map( item => {
            if(item.name === values.consignorId) {
                values.consignorId = item.id
            }
        })
        alllistCustomer.list.map( item => {
            if(item.name === values.consigneeId) {
                values.consigneeId = item.id
            }
        })
        if(values.lrVehicleType == 'Own') {
            values.vehicleNumber = ''
            values.driverName = ''
            values.contactNumber = ''
        } else {
            values.vehicalId = ''
            values.driverId = ''
        }
        values.date = values.date.startOf('day')
        values.dateText = (moment(values.date.startOf('day')).format("DD/MM/YYYY")).toString()
        this.props.createLrDetails(handleSuccess, handleFailure, values)
    }
    componentDidMount() {
      this.props.getAllLrDetails();
      this.props.getLRNumber();
      this.props.getAllCities();
      this.props.getAllStates();
      this.props.getAllBrokers();
      this.props.getAllCustomers(); 
      this.props.getAllVehicles();
      this.props.getAllDrivers();
      this.props.getAllDeleveryStatus();
      this.props.getAllBranches();
    }
    onBlur = (event) => {
        if(event.target.value) {
            this.props.checkDuplicate(event.target.value);
        }
    }
    oFieldsChange = (fields) => {
        if(fields && fields.length && fields[0].value == "BR") {
            this.setState({branchValue: fields[0].value});
        } else if(fields && fields.length && fields[0].value !== "BR") {
            this.setState({branchValue: fields[0].value});
        }
        if(fields && fields.length && fields[1].value == "Hire") {
            this.setState({vehicleValue: "Hire"});
        } else if(fields && fields.length && fields[1].value == "Own") {
            this.setState({vehicleValue: "Own"});
        }
        if(fields && fields.length && fields[2].value == "Broker") {
            this.setState({brokerValue: "Broker"});
        } else if(fields && fields.length && fields[2].value == "Self") {
            this.setState({brokerValue: "Self"});
        }
    }

    onConfirm = () => {
        const { lrDetailsData } = this.props;
        const data = {
            startDate: new Date(),
            vehicleId: lrDetailsData.vehical_id,
            driverId:  lrDetailsData.driver_id,
            lrDetailsId:  lrDetailsData.id,
        }
        this.props.createTrip(() => {},() => {}, data)
        this.props.history.push('/main/lrdetails/lrdetails');
        this.setState({ isPreview: false })
    }
  
    handleCancel = () => {
        const { lrDetailsData } = this.props;
        this.props.deleteLrDetails(()=> {}, ()=> {}, lrDetailsData.id, true)
        this.setState({ isPreview: false })
    }
    setView = (view) => {
        this.setState({view})
    }
    render() {
        const { isFetching, alllistState,alllistCity, alllistBroker,alllistCustomer, alllistVehicle,alllistDriver,alllistDS, duplicateLR, lrNumber, alllistBranch, lrDetailsData } = this.props;
        const { branchValue, vehicleValue, isPreview, brokerValue, view } = this.state;
        if(view === 'list') {
            return (
                <Create
                    onBlur={this.onBlur}
                    duplicateLR={duplicateLR}
                    onFinish={this.onFinish}
                    isFetching={isFetching}
                    alllistDS={ alllistDS }
                    alllistCity={alllistCity}
                    alllistState={alllistState}
                    alllistBroker={alllistBroker}
                    alllistCustomer={alllistCustomer}
                    alllistVehicle={alllistVehicle}
                    alllistDriver={alllistDriver}
                    alllistBranch={alllistBranch}
                    lrNumber={lrNumber}
                    oFieldsChange={this.oFieldsChange}
                    branchValue={branchValue}
                    brokerValue={brokerValue}
                    vehicleValue={vehicleValue}
                    lrDetailsData={lrDetailsData}
                    isPreview={isPreview}
                    onConfirm={this.onConfirm}
                    handleCancel={this.handleCancel}
                    getAllCities={this.props.getAllCities}
                    getAllStates={this.props.getAllStates}
                    getAllBrokers={this.props.getAllBrokers}
                    getAllCustomers={this.props.getAllCustomers}
                    getAllVehicles={this.props.getAllVehicles}
                    getAllDrivers={this.props.getAllDrivers}
                    getAllBranches={this.props.getAllBranches}
                    setView={this.setView}
                />
            );
        } else {
            return (
                <Grid
                    onBlur={this.onBlur}
                    duplicateLR={duplicateLR}
                    onFinish={this.onFinish}
                    isFetching={isFetching}
                    alllistDS={ alllistDS }
                    alllistCity={alllistCity}
                    alllistState={alllistState}
                    alllistBroker={alllistBroker}
                    alllistCustomer={alllistCustomer}
                    alllistVehicle={alllistVehicle}
                    alllistDriver={alllistDriver}
                    alllistBranch={alllistBranch}
                    lrNumber={lrNumber}
                    oFieldsChange={this.oFieldsChange}
                    branchValue={branchValue}
                    brokerValue={brokerValue}
                    vehicleValue={vehicleValue}
                    lrDetailsData={lrDetailsData}
                    isPreview={isPreview}
                    onConfirm={this.onConfirm}
                    handleCancel={this.handleCancel}
                    getAllCities={this.props.getAllCities}
                    getAllStates={this.props.getAllStates}
                    getAllBrokers={this.props.getAllBrokers}
                    getAllCustomers={this.props.getAllCustomers}
                    getAllVehicles={this.props.getAllVehicles}
                    getAllDrivers={this.props.getAllDrivers}
                    getAllBranches={this.props.getAllBranches}
                    setView={this.setView}
                />
            );
        }
    }
};

const mapStateToProps = ({ lrdetails, city ,state,broker,customer,vehicle,driver, branch}) => {
    const { isFetching ,alllistDS, duplicateLR, lrNumber, lrDetailsData } = lrdetails
    return { 
        isFetching,  
        alllistDS,      
        alllistState: state.alllist,
        alllistCity:city.alllist,
        alllistBroker:broker.alllist,
        alllistCustomer:customer.alllist, 
        alllistVehicle:vehicle.alllist,
        alllistDriver:driver.alllist,
        alllistBranch:branch.alllist,
        duplicateLR,
        lrNumber,
        lrDetailsData
    };
}
export default connect(mapStateToProps, {
    getLRNumber,
    createLrDetails,
    getAllLrDetails,
    getAllCities,
    getAllStates,
    getAllBrokers,
    getAllCustomers,
    getAllVehicles,
    getAllDrivers,
    getAllDeleveryStatus,
    createTrip,
    checkDuplicate,
    getAllBranches,
    deleteLrDetails
})(CreateContainer);