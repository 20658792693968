import Constants from "constants/User";
import { API_DOMAIN } from '../../env';


export const signin = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.USER_SIGNIN_REQUEST
      });
      fetch(`${API_DOMAIN}/auth`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.USER_SIGNIN_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  sessionStorage.setItem('el_user', JSON.stringify(body));
                  dispatch({
                      type: Constants.USER_SIGNIN_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}
export const userSignOut = () => {
  sessionStorage.clear();
  return {
    type: Constants.USER_SIGNOUT_SUCCESS
  };
};

export const setInitUrl = (url) => {
  return {
    type: Constants.INIT_URL,
    payload: url
  };
};

export const getAllUsers = () => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_USERS_REQUEST,
  });
  return fetch(`${API_DOMAIN}/users`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_USERS_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_USERS_SUCCESS,
          payload: body,
        });
      }
    });
};

export const createUser = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.USER_REGISTER_REQUEST
      });
      fetch(`${API_DOMAIN}/user`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.USER_REGISTER_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  dispatch({
                      type: Constants.USER_REGISTER_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}

export const getUserDetails = (id) => function (dispatch) {
  dispatch({
    type: Constants.GET_USER_DETAILS_REQUEST,
  });
  return fetch(`${API_DOMAIN}/user/details/${id}`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_USER_DETAILS_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_USER_DETAILS_SUCCESS,
          payload: body,
        });
      }
    });
};

export const deleteUser = (handleSuccess, handleFailure, id) =>{
  console.log("id",id)
  return function (dispatch) {
      dispatch({
          type: Constants.DELETE_USER_REQUEST
      });
      return fetch(`${API_DOMAIN}/user/delete/${id}`,{
          method: "DELETE", 
          mode: "cors", 
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
              "Content-Type": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow",
          referrer: "no-referrer",
      }).then(() => {
                  dispatch({
                      type: Constants.DELETE_USER_SUCCESS,
                      payload:''
                  });
                  handleSuccess();
          });
  }
};

export const updateUser = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.USER_UPDATE_REQUEST
      });
      fetch(`${API_DOMAIN}/user/update/${data.id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json'
          }
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.USER_UPDATE_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  dispatch({
                      type: Constants.USER_UPDATE_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}

export const createRole = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.ROLE_REGISTER_REQUEST
      });
      fetch(`${API_DOMAIN}/role/create`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.ROLE_REGISTER_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  dispatch({
                      type: Constants.ROLE_REGISTER_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}

export const getAllRoles = () => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_ROLES_REQUEST,
  });
  return fetch(`${API_DOMAIN}/roles`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_ROLES_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_ROLES_SUCCESS,
          payload: body,
        });
      }
    });
};

export const updateRole = (handleSuccess, handleFailure, data) => {
  return (dispatch) => {
      dispatch({
          type: Constants.ROLE_UPDATE_REQUEST
      });
      fetch(`${API_DOMAIN}/role/update/${data.id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json'
          }
      })
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
              if (!response.ok) {
                  dispatch({
                      type: Constants.ROLE_UPDATE_ERROR,
                      payload: body.errors
                  });
                  handleFailure(response, body);
              } else {
                  dispatch({
                      type: Constants.ROLE_UPDATE_SUCCESS,
                      payload: body
                  });
                  handleSuccess(body);
              }
          });
  }
}

export const deleteRole = (handleSuccess, handleFailure, id) =>{
  console.log("id",id)
  return function (dispatch) {
      dispatch({
          type: Constants.DELETE_ROLE_REQUEST
      });
      return fetch(`${API_DOMAIN}/role/delete/${id}`,{
          method: "DELETE", 
          mode: "cors", 
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
              "Content-Type": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow",
          referrer: "no-referrer",
      }).then(() => {
                  dispatch({
                      type: Constants.DELETE_ROLE_SUCCESS,
                      payload:''
                  });
                  handleSuccess();
          });
  }
};
export const getRoleDetails = (id) => function (dispatch) {
  dispatch({
    type: Constants.GET_ROLE_DETAILS_REQUEST,
  });
  return fetch(`${API_DOMAIN}/role/details/${id}`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ROLE_DETAILS_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ROLE_DETAILS_SUCCESS,
          payload: body,
        });
      }
    });
};


export const reset = () => function (dispatch) {
  dispatch({
    type: Constants.RESET_DETAILS,
  });
};
