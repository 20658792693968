import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_BROKERS_REQUEST',
  'GET_ALL_BROKERS_SUCCESS',
  'GET_ALL_BROKERS_FAIL',
  'GET_BROKER_DETAILS_REQUEST',
  'GET_BROKER_DETAILS_SUCCESS',
  'GET_BROKER_DETAILS_FAIL',
  'BROKER_REGISTER_REQUEST',
  'BROKER_REGISTER_ERROR',
  'BROKER_REGISTER_SUCCESS',
  'DELETE_BROKER_REQUEST',
  'DELETE_BROKER_SUCCESS',
  'DELETE_BROKER_FAIL',
  'BROKER_UPDATE_REQUEST',
  'BROKER_UPDATE_ERROR',
  'BROKER_UPDATE_SUCCESS',
  'RESET_DETAILS'
]);
