import Constants from '../../constants/Diver';

const INITIAL_STATE = {
  data: [],
  alllist: [],
  alllistBG: [],
  allSettlementlist: [],
  allLicenceTypesList: [],
  details: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
  driverData: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Constants.GET_ALL_DRIVERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_DRIVERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_DRIVERS_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_DRIVER_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_DRIVER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_DRIVER_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.DRIVER_REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.DRIVER_REGISTER_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.DRIVER_REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.DELETE_DRIVER_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case Constants.DELETE_DRIVER_SUCCESS:
      return {
        ...state,
        driverDelete: action.payload,
        isFetching: false
      }
    case Constants.DELETE_DRIVER_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }
    case Constants.DRIVER_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.DRIVER_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.DRIVER_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.GET_ALL_BLOODGROUPS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_BLOODGROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllistBG: action.payload,
      };
    case Constants.GET_ALL_BLOODGROUPS_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_ALL_LICENCETYPES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_LICENCETYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        allLicenceTypesList: action.payload,
      };
    case Constants.GET_ALL_LICENCETYPES_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_ALL_LICENCERTO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_LICENCERTO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        allListLicenceRto: action.payload,
      };
    case Constants.GET_ALL_LICENCERTO_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case Constants.UPLOAD_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        driverData: action.payload
      };
    case Constants.UPLOAD_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case Constants.RESET_DETAILS:
      return {
        ...state,
        details: {},
      };
    case Constants.DRIVER_SETTLEMENT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.DRIVER_SETTLEMENT_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.DRIVER_SETTLEMENT_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.GET_ALL_SETTLEMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_SETTLEMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        allSettlementlist: action.payload,
      };
    case Constants.GET_ALL_SETTLEMENTS_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    default:
      return state;
  }
};
