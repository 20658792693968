import Constants from "constants/User";

const INITIAL_STATE = {
  data: [],
  alllist: [],
  details: {},
  detailsRole: {},
  roles: [],
  isFetching: false,
  error: undefined,
  listError: undefined,
  authUser: JSON.parse(sessionStorage.getItem('el_user')),
  initURL: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Constants.USER_SIGNIN_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.USER_SIGNIN_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.USER_SIGNIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authUser: action.payload
      };
    case Constants.USER_SIGNOUT_SUCCESS:
      return {
        ...state,
        authUser: null,
        initURL: '/',
        isFetching: false
      };
    case Constants.INIT_URL:
      return {
        ...state,
        initURL: action.payload
      };
    case Constants.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_USERS_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_USER_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.USER_REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.USER_REGISTER_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.USER_REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.DELETE_USER_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case Constants.DELETE_USER_SUCCESS:
      return {
        ...state,
        userDelete: action.payload,
        isFetching: false
      }
    case Constants.DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }
    case Constants.USER_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.USER_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.USER_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.GET_ALL_ROLES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        roles: action.payload,
      };
    case Constants.GET_ALL_ROLES_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.ROLE_REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.ROLE_REGISTER_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.ROLE_REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.RESET_DETAILS:
      return {
        ...state,
        details: {},
      };
    case Constants.DELETE_ROLE_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case Constants.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        roleDelete: action.payload,
        isFetching: false
      }
    case Constants.DELETE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }
    case Constants.ROLE_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case Constants.ROLE_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case Constants.ROLE_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case Constants.GET_ROLE_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ROLE_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailsRole: action.payload,
      };
    case Constants.GET_ROLE_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
