import React from "react";
import {
  Empty,
  Card,
  Row,
  Col,
  Table,
  message,
  Timeline,
  Tag,
  Skeleton,
  Select,
  Form,
  Button,
  Modal,
  Input,
  Tooltip,
  Popconfirm,
  Upload,
  Popover,
  Descriptions,
  Space
} from "antd";
import {
  UploadOutlined,
  QuestionCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  DeleteFilled,
  FileTextFilled,
  SearchOutlined
} from "@ant-design/icons";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";
import _, { map } from "lodash";
import axios from "axios";
import { API_DOMAIN } from "../../../../env";
import {
  getAllLrDetails,
  getLRTransitDetails,
  getAllDeliveryStatus,
  getAllBranches,
  createTransit,
  createTripDiesel,
  createTripAdvance,
  getTripDetails,
  updateTrip,
  getTripDetailsByLR,
  getTripAdvance,
  getTripDiesel,
  deleteAdvanceDetails,
  deleteDieselDetails,
  getAllCities,
  reset,
  getAllTripUploads,
  updateLrDetails,
  deleteTripuploads,
  getLrDetails,
} from "../../../../appRedux/actions";
import TripDetails from "./tripDetails";
import { hasActionPermission } from "../../../../util/rolesHelper";
import { ACTION_CREATE, ACTION_DELETE, ACTION_EDIT, FEATURE_LR_DETAILS, FEATURE_TRANSIT, FEATURE_TRIP } from "../../../../constants/Roles";

const { Option } = Select;
const { TextArea } = Input;
const { Search } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 18 },
    lg: { span: 18 },
  },
};
const formItemLayoutExp = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 18 },
    lg: { span: 18 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    span: 24,
  },
};
let dieselFormRef = null;
let advanceFormRef = null;
class Transit extends React.Component {
  state = {
    tableConfig: {},
    page: 1,
    pageSize: 20,
    record: {},
    tripData: {},
    tripDieselData: { amount: 0 },
    visible: false,
    startVisible: false,
    totalKM: 0,
    deliveryLink: null,
    selectedStatus: null,
    uploading: false,
    uploadingType: false,
    selectedUploadType: "Expenses",
    fileListUploadTypeObj: [
      {
        url: "",
        name: "name",
      },
    ],
  };

  dieselcolumns = [
    {
      title: "Quantity",
      dataIndex: "dieselQuantity",
      key: "dieselQuantity",
      width: 100,
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Rate",
      dataIndex: "dieselRate",
      key: "dieselRate",
      width: 100,
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Amount",
      dataIndex: "dieselAmount",
      key: "dieselAmount",
      width: 100,
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text, record) => {
        const { authUser } = this.props;
        let userAccess = [];
        if (
          authUser &&
          authUser.details &&
          authUser.details.Role &&
          authUser.details.Role
        ) {
          userAccess = JSON.parse(authUser.details.Role.access);
        }
        return (
          <span>
            {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_DELETE) ? (
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete this LR Details?"
                  onConfirm={() => this.deleteDieselConfirm(record.id)}
                  onCancel={() => this.cancel()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    className="gx-margin-none"
                    type="text"
                    size={"small"}
                    icon={<DeleteFilled />}
                  />
                </Popconfirm>
              </Tooltip>
            ) : null}
          </span>
        );
      },
    },
  ];

  advancecolumns = [
    {
      title: "Advance For",
      dataIndex: "advanceFor",
      key: "advanceFor",
      width: 100,
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Advance To",
      dataIndex: "advanceTo",
      key: "advanceTo",
      width: 100,
      render: (text) => (
        <span className="gx-link">{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "advanceAmount",
      key: "advanceAmount",
      width: 100,
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text, record) => {
        const { authUser } = this.props;
        let userAccess = [];
        if (
          authUser &&
          authUser.details &&
          authUser.details.Role &&
          authUser.details.Role
        ) {
          userAccess = JSON.parse(authUser.details.Role.access);
        }
        return (
          <span>
            {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_DELETE) ? (
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete this LR Details?"
                  onConfirm={() => this.deleteAdvanceConfirm(record.id)}
                  onCancel={() => this.cancel()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    className="gx-margin-none"
                    type="text"
                    size={"small"}
                    icon={<DeleteFilled />}
                  />
                </Popconfirm>
              </Tooltip>
            ) : null}
          </span>
        );
      },
    },
  ];

  lrcolumns = [
    {
      title: "ID",
      dataIndex: "index",
      key: "index",
      width: 50,
      render: (value, item, index) => (
        <span className="gx-link">
          {(this.state.page - 1) * this.state.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNo",
      key: "number",
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "E-Way Bill",
      dataIndex: "eway",
      key: "eway",
      render: (text) => <span className="gx-link">{text}</span>,
    },
    {
      title: "Validity",
      dataIndex: "validity",
      key: "validity",
      render: (text) => (
        <span className="gx-link">{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
  ];

  componentDidMount() {
    const data = {
      // status: [1, 2, 3]
    };
    this.props.getAllLrDetails(data);
    this.props.getAllDeliveryStatus();
    this.props.getAllBranches();
    this.props.getAllCities();
  }

  onLRRowClicked = (record) => {
    this.props.getLrDetails(record.id);
    this.setVisible(true);
  };

  setVisible = (lrvisible) => {
    this.setState({ lrvisible });
  };

  componentWillUnmount() {
    this.props.reset();
  }
  onSearch = (searchText) => {
    const data = {
      searchText: searchText,
      // status: [1, 2, 3]
    };
    this.props.getAllLrDetails(data);
  };
  deleteDieselConfirm = (id) => {
    const handleSuccess = () => {
      const { tripData } = this.state;
      message.success("Diesel details deleted successfully");
      this.props.getTripDiesel(tripData.id);
    };
    const handleFailure = () => {
      message.error("Diesel details deletion failed, please try again");
    };
    this.props.deleteDieselDetails(handleSuccess, handleFailure, id);
  };
  deleteAdvanceConfirm = (id) => {
    const handleSuccess = () => {
      const { tripData } = this.state;
      message.success("Advance details deleted successfully");
      this.props.getTripAdvance(tripData.id);
    };
    const handleFailure = () => {
      message.error("Advance details deletion failed, please try again");
    };
    this.props.deleteAdvanceDetails(handleSuccess, handleFailure, id);
  };
  onFinish = (values) => {
    const { record, deliveryLink } = this.state;
    const { deliveryStatuses, allBranches, alllistCity } = this.props;
    const handleSuccess = () => {
      message.success("Transit updated successfully.");
      const data = {
        // status: [1, 2, 3]
      };
      this.props.getAllLrDetails(data);
      this.props.getLRTransitDetails(record.id);
      record.status_id = parseInt(values.statusId);
      record.city_id = parseInt(values.cityId);
      this.setState({ record });
    };
    const handleFailure = () => {
      message.error("Transit updation failed, please try again.");
    };

    deliveryStatuses.list.map((item) => {
      if (item.status === values.statusId) {
        values.statusId = item.id;
      }
    });
    alllistCity.list.map((item) => {
      if (item.name === values.cityId) {
        values.cityId = item.id;
      }
    });
    values.lrDetailsId = record.id;
    values.acknowledgement = deliveryLink;
    this.props.createTransit(handleSuccess, handleFailure, values);
  };
  onRowClicked = (event, record, rowIndex) => {
    this.setState({ record, activeIndex: rowIndex, selectedStatus: null });
    this.props.getLRTransitDetails(record.id);
  };

  openTripDetails = () => {
    const { record } = this.state;
    const handleSuccess = (response) => {
      this.setState({ visible: true, tripData: response });
      this.props.getTripAdvance(response.id);
      this.props.getTripDiesel(response.id);
      this.props.getAllTripUploads(response.id);
    };
    const handleFailure = () => {
      message.error("Trip details fetching failed, please try again.");
    };
    this.props.getTripDetailsByLR(handleSuccess, handleFailure, record.id);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  confirmDiesel = (values) => {
    const { record } = this.state;
    const { tripLrdetails } = this.props;
    const handleSuccess = () => {
      message.success("Trip Diesel details added successfully.");
      this.openTripDetails();
      dieselFormRef.resetFields();
      this.setState({ tripDieselData: { amount: 0 } });
    };
    const handleFailure = () => {
      message.error("Trip Diesel details addition failed, please try again.");
    };
    values.tripId = tripLrdetails.id;
    values.dieselAmount =
      values &&
      parseFloat(values.dieselQuantity) &&
      parseFloat(values.dieselRate)
        ? parseFloat(values.dieselQuantity * values.dieselRate).toFixed(2)
        : 0;
    this.props.createTripDiesel(handleSuccess, handleFailure, values);
  };

  confirmAdvance = (values) => {
    const { record } = this.state;
    const { tripLrdetails } = this.props;
    const handleSuccess = () => {
      message.success("Trip advance added successfully.");
      advanceFormRef.resetFields();
      this.openTripDetails();
    };
    const handleFailure = () => {
      message.error("Trip advance addition failed, please try again.");
    };
    values.tripId = tripLrdetails.id;
    this.props.createTripAdvance(handleSuccess, handleFailure, values);
  };

  confirmTrip = (values) => {
    const { record } = this.state;
    const { tripLrdetails } = this.props;
    const handleSuccess = () => {
      message.success("Trip updated successfully.");
      this.openTripDetails();
    };
    const handleFailure = () => {
      message.error("Trip updation failed, please try again.");
    };
    values.id = tripLrdetails.id;
    this.props.updateTrip(handleSuccess, handleFailure, values);
  };

  onDieselFieldsChange = (fields) => {
    const { tripDieselData } = this.state;
    fields.map((field) => {
      tripDieselData[field.name[0]] = field.value;
    });
    tripDieselData.dieselAmount = (
      tripDieselData.dieselQuantity * tripDieselData.dieselRate
    ).toFixed(2);
    this.setState({ tripDieselData });
  };
  onExpenseFieldsChange = (fields) => {
    if (
      fields[0].value &&
      fields[1].value &&
      parseFloat(fields[1].value) &&
      parseFloat(fields[0].value)
    ) {
      const totalKM = parseFloat(fields[1].value) - parseFloat(fields[0].value);
      this.setState({ totalKM });
    }
  };
  setClassName = (record, index) => {
    // record represents the content of the table row, index represents the row index
    // Add the highlight style of the row when the index is equal
    return index === this.state.activeIndex ? `el-table-row-active` : "";
  };

  setPage = (page, pageSize) => {
    this.setState({ page, pageSize });
  };

  dieselForm = (ref) => {
    dieselFormRef = ref;
  };
  advanceForm = (ref) => {
    advanceFormRef = ref;
  };
  handleUpload = async (options) => {
    const { record, uploading } = this.state;
    this.setState({ uploading: true });
    const { onSuccess, onError, file, onProgress, category } = options;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        onProgress({ percent: (event.loaded / event.total) * 100 }, file);
      },
    };
    fmData.append("file", file);
    fmData.append("lr_number", record.number);
    try {
      const res = await axios.post(
        `${API_DOMAIN}/lrdetail/upload`,
        fmData,
        config
      );

      onSuccess("ok", res);
      const link = res.data.link;
      record.acknowledgement = link;
      this.setState({ deliveryLink: link, record, uploading: false });
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };
  handleUploadType = async (options) => {
    const { record, uploadingType, selectedUploadType } = this.state;
    const { tripLrdetails, tripuploadlist } = this.props;
    this.setState({ uploadingType: true });
    const { onSuccess, onError, file, onProgress, category } = options;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        onProgress({ percent: (event.loaded / event.total) * 100 }, file);
      },
    };
    let maxFilenameValue = 0;
    if (tripuploadlist && tripuploadlist.length) {
      tripuploadlist.map((list) => {
        let filenameArr = list.url.split("_");
        if (filenameArr[0] == selectedUploadType) {
          if (filenameArr[2]) {
            let filenameArrNumber = filenameArr[2].split(".");
            if (
              filenameArrNumber[0] &&
              filenameArrNumber[0] > maxFilenameValue
            ) {
              maxFilenameValue = filenameArrNumber[0];
            }
          }
        }
      });
    }
    maxFilenameValue++;
    fmData.append("file", file);
    fmData.append(
      "filename",
      `${selectedUploadType}_${record.number}_${maxFilenameValue}`
    );
    fmData.append("trip_id", tripLrdetails.id);
    try {
      const res = await axios.post(
        `${API_DOMAIN}/tripurl/upload`,
        fmData,
        config
      );

      onSuccess("ok", res);
      const link = res.data.link;
      this.props.getAllTripUploads(tripLrdetails.id);
      this.setState({ uploadTypeLink: link, record, uploadingType: false });
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };
  onFieldsChange = (fields) => {
    if (fields && fields.length) {
      const selectedStatus =
        fields[0].value == "Delivered" ? 4 : fields[0].value;
      this.setState({ selectedStatus });
    }
  };

  uploadTypeSelect = (value) => {
    this.setState({ selectedUploadType: value });
  };

  onRemoveAck = () => {
    const { record } = this.state;
    const handleSuccess = () => {
      message.success("Ack file deleted successfully");
      record.acknowledgement = null;
      this.setState({ deliveryLink: null, record });
    };
    const handleFailure = () => {
      message.error("Ack file deletion failed, please try again");
    };
    const data = {
      id: record.id,
      acknowledgement: null,
    };
    this.props.updateLrDetails(handleSuccess, handleFailure, data);
  };
  onRemoveTrip = (file) => {
    const handleSuccess = () => {
      message.success("File deleted successfully");
      const { tripLrdetails } = this.props;
      this.props.getAllTripUploads(tripLrdetails.id);
    };
    const handleFailure = () => {
      message.error("File deletion failed, please try again");
    };
    this.props.deleteTripuploads(handleSuccess, handleFailure, file.name);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const { setPage } = this;

    const {
      pageSize,
      tableConfig,
      record,
      visible,
      startVisible,
      tripDieselData,
      totalKM,
      selectedStatus,
      uploading,
      uploadingType,
      fileListUploadTypeObj,
      selectedUploadType,
      lrvisible,
    } = this.state;
    const {
      authUser,
      isFetching,
      alllist: { list },
      lrtransitdetails,
      deliveryStatuses,
      isTransitFetching,
      allBranches,
      alllistCity,
      tripLrdetails,
      tripDieseldetails,
      tripAdvancedetails,
      tripuploadlist,
      details,
    } = this.props;
    let activities = [];
    if (
      lrtransitdetails &&
      lrtransitdetails.list &&
      lrtransitdetails.list.length &&
      deliveryStatuses &&
      deliveryStatuses.list &&
      deliveryStatuses.list.length
    ) {
      activities = deliveryStatuses.list.map((item, itemIndex) => {
        let statusInfo = { id: item.id, status: item.status, list: [] };
        lrtransitdetails.list.map((itemlr, itemIndexlr) => {
          if (item.id == itemlr.status_id) {
            statusInfo.list.push(itemlr);
          }
        });
        return statusInfo;
      });
    }
    const ppStatuses = _.find(activities, { status: "Package Picked" });
    const deliveredStatuses = _.find(activities, { status: "Delivered" });
    const startDate =
      ppStatuses && ppStatuses.list && ppStatuses.list.length
        ? ppStatuses.list[0].created_at
        : null;
    const endDate =
      deliveredStatuses &&
      deliveredStatuses.list &&
      deliveredStatuses.list.length
        ? deliveredStatuses.list[deliveredStatuses.list.length - 1].created_at
        : null;
    const noOfDays =
      startDate && endDate
        ? moment(endDate).diff(moment(startDate), "days") + 1
        : "-";

    let statusMatch = false;
    let newDetails = null;
    if (
      record &&
      record.id &&
      deliveryStatuses &&
      deliveryStatuses.list &&
      alllistCity &&
      alllistCity.list
    ) {
      const deliveryStatusObj = _.find(deliveryStatuses.list, [
        "id",
        record.status_id,
      ]);
      const cityObj = _.find(alllistCity.list, ["id", record.city_id]);
      newDetails = {
        statusId: deliveryStatusObj.status,
        cityId: cityObj ? cityObj.name : "",
      };
    }
    let userAccess = [];
    if (
      authUser &&
      authUser.details &&
      authUser.details.Role &&
      authUser.details.Role
    ) {
      userAccess = JSON.parse(authUser.details.Role.access);
    }
    const RTO = tripLrdetails.RTO ? parseFloat(tripLrdetails.RTO) : 0;
    const bhatta = tripLrdetails.bhatta ? parseFloat(tripLrdetails.bhatta) : 0;
    const border = tripLrdetails.border ? parseFloat(tripLrdetails.border) : 0;
    const diselPass = tripLrdetails.diselPass
      ? parseFloat(tripLrdetails.diselPass)
      : 0;
    const fastTag = tripLrdetails.fastTag
      ? parseFloat(tripLrdetails.fastTag)
      : 0;
    const loading = tripLrdetails.loading
      ? parseFloat(tripLrdetails.loading)
      : 0;
    const maintenance = tripLrdetails.maintenance
      ? parseFloat(tripLrdetails.maintenance)
      : 0;
    const other = tripLrdetails.other ? parseFloat(tripLrdetails.other) : 0;
    const roadTax = tripLrdetails.roadTax
      ? parseFloat(tripLrdetails.roadTax)
      : 0;
    const unloading = tripLrdetails.unloading
      ? parseFloat(tripLrdetails.unloading)
      : 0;
    const parking = tripLrdetails.parking
      ? parseFloat(tripLrdetails.parking)
      : 0;
    const driverAmount = tripLrdetails["Driver.balance"]
      ? parseFloat(tripLrdetails["Driver.balance"])
      : 0;
    const totalExpense =
      RTO +
      bhatta +
      border +
      diselPass +
      fastTag +
      loading +
      maintenance +
      other +
      roadTax +
      unloading +
      parking;
    let totalAdvance = 0;
    if (
      tripDieseldetails &&
      tripDieseldetails.rows &&
      tripDieseldetails.rows.length
    ) {
      tripDieseldetails.rows.map((diesel) => {
        totalAdvance = totalAdvance + parseFloat(diesel.dieselAmount);
      });
    }
    if (
      tripAdvancedetails &&
      tripAdvancedetails.rows &&
      tripAdvancedetails.rows.length
    ) {
      tripAdvancedetails.rows.map((advance) => {
        totalAdvance = totalAdvance + parseFloat(advance.advanceAmount);
      });
    }
    const driverpayrec = totalAdvance - totalExpense;
    const fileListObj = [];
    if (record && record.acknowledgement) {
      fileListObj.push({
        url: record.acknowledgement,
        name: record.acknowledgement.replace(/^.*[\\\/]/, ""),
      });
    }
    let showUpload = false;
    if (selectedStatus == 4) {
      showUpload = true;
    } else if (selectedStatus == null && record && record.status_id == 4) {
      showUpload = true;
    }

    let newDetailslr = null;
    if (details) {
      newDetailslr = { ...details };
      if (details.date) {
        newDetailslr.date = moment(details.date).format("DD/MM/YYYY");
      }
    }

  const columns = [
      {
        title: "ID",
        dataIndex: "index",
        key: "index",
        render: (value, item, index) => (
          <span className="gx-link">
            {(this.state.page - 1) * this.state.pageSize + index + 1}
          </span>
        ),
      },
      {
        title: "LR Number",
        dataIndex: "number",
        key: "number",
        render: (text, record, index) => {
          const content = (
            <div style={{ padding: "10px" }}>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <strong>Consignor Contact:</strong>{" "}
                  {record["consignor.contact_number"]}
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <strong>Vehicle number:</strong>{" "}
                  {record["vehicleNumber"]
                    ? record["vehicleNumber"]
                    : record["Vehical.vehicleNumber"]}
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <strong>Driver name:</strong>{" "}
                  {record["driverName"]
                    ? record["driverName"]
                    : `${record["Driver.first_name"]} ${record["Driver.last_name"]}`}
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <strong>Driver number:</strong>{" "}
                  {record["contactNumber"]
                    ? record["contactNumber"]
                    : record["Driver.contactNumber"]}
                </Col>
              </Row>
            </div>
          );
          return (
            <Popover content={content} title={"Info"}>
              <Button type="text" onClick={() => this.onLRRowClicked(record)}>
                <span className="gx-link">{text}</span>
              </Button>
            </Popover>
          );
        },
        sorter: (a, b) => a.number - b.number,
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (text) => (
          <span className="gx-link">{moment(text).format("DD-MM-YYYY")}</span>
        ),
        sorter: (a, b) => a["date"].localeCompare(b["date"]),
      },
      {
        title: "Consignee Name",
        dataIndex: "consignee.name",
        key: "consignee.name",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("consignee.name"),
        sorter: (a, b) =>
          a["consignee.name"].localeCompare(b["consignee.name"]),
      },
      {
        title: "Destination",
        dataIndex: "destination_city.name",
        key: "destination_city.name",
        render: (text) => <span className="gx-link">{text}</span>,
        ...this.getColumnSearchProps("destination.name"),
        sorter: (a, b) =>
          a["destination.name"].localeCompare(b["destination.name"]),
      },
      {
        title: "Status",
        dataIndex: "Deliverystatus.status",
        key: "Deliverystatus.status",
        render: (text, record) => (
          <>
            <Tag color="#2db7f5">{text}</Tag>
            {record.trip_id ? <Tag color="warning">Trip</Tag> : null}
          </>
        ),
        ...this.getColumnSearchProps("Deliverystatus.status"),
        sorter: (a, b) =>
          a["Deliverystatus.status"].localeCompare(b["Deliverystatus.status"]),
      },
    ];
    return (
      <Row className="fx-transit-container">
        <Modal
          title={`LR Details for #${newDetailslr && newDetailslr.number}`}
          centered
          visible={lrvisible}
          onOk={() => this.setVisible(false)}
          onCancel={() => this.setVisible(false)}
          width={"90%"}
        >
          <Card className="gx-card" title="LR Details Information">
            <Descriptions title="">
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Location Type"
              >
                {newDetailslr && newDetailslr.locationType
                  ? newDetailslr.locationType
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="LR Vehicle Type"
              >
                {newDetailslr && newDetailslr.lrVehicleType
                  ? newDetailslr.lrVehicleType
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="LR Type"
              >
                {newDetailslr && newDetailslr.lrType ? newDetailslr.lrType : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="LR Number"
              >
                {newDetailslr && newDetailslr.number ? newDetailslr.number : ""}
              </Descriptions.Item>
              {newDetailslr &&
              newDetailslr.locationType &&
              newDetailslr.locationType == "BR" ? (
                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label="Branch Name"
                >
                  {newDetailslr && newDetailslr["Branch.name"]
                    ? newDetailslr["Branch.name"]
                    : ""}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item labelStyle={{ fontWeight: 500 }} label="Date">
                {newDetailslr && newDetailslr.date && newDetailslr.date
                  ? newDetailslr.date
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Booking Type"
              >
                {newDetailslr &&
                newDetailslr.bookingType &&
                newDetailslr.bookingType
                  ? newDetailslr.bookingType
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Weight(KG)"
              >
                {newDetailslr && newDetailslr.weight ? newDetailslr.weight : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Package Number"
              >
                {newDetailslr && newDetailslr.packageNumber
                  ? newDetailslr.packageNumber
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Freight Amount"
              >
                {newDetailslr && newDetailslr.amount ? newDetailslr.amount : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Origin State"
              >
                {newDetailslr ? newDetailslr["origin_state.name"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Origin City"
              >
                {newDetailslr ? newDetailslr["origin_city.name"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Destination State"
              >
                {newDetailslr ? newDetailslr["destination_state.name"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Destination City"
              >
                {newDetailslr && newDetailslr["destination_city.name"]
                  ? newDetailslr["destination_city.name"]
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Consignor Name"
              >
                {newDetailslr ? newDetailslr["consignor.name"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Consignor Address"
              >
                {newDetailslr ? newDetailslr["consignor.address"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Consignor Contact Number"
              >
                {newDetailslr ? newDetailslr["consignor.contact_number"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Consignee Name"
              >
                {newDetailslr ? newDetailslr["consignee.name"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Consignee Address"
              >
                {newDetailslr ? newDetailslr["consignee.address"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Consignee Contact Number"
              >
                {newDetailslr ? newDetailslr["consignee.contact_number"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Additional Delivery Point Address"
              >
                {newDetailslr ? newDetailslr["additionalAddress"] : ""}
              </Descriptions.Item>
              {newDetailslr &&
              newDetailslr.lrVehicleType &&
              newDetailslr.lrVehicleType == "Own" ? (
                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label="Vehicle Number"
                >
                  {newDetailslr ? newDetailslr["Vehical.vehicleNumber"] : ""}
                </Descriptions.Item>
              ) : null}
              {newDetailslr &&
              newDetailslr.lrVehicleType &&
              newDetailslr.lrVehicleType == "Own" ? (
                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label="Driver Name"
                >
                  {newDetailslr
                    ? `${newDetailslr["Driver.first_name"]} ${newDetailslr["Driver.last_name"]}`
                    : ""}
                </Descriptions.Item>
              ) : null}
              {newDetailslr &&
              newDetailslr.lrVehicleType &&
              newDetailslr.lrVehicleType == "Hire" ? (
                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label="Vehicle Number"
                >
                  {newDetailslr ? newDetailslr["vehicleNumber"] : ""}
                </Descriptions.Item>
              ) : null}
              {newDetailslr &&
              newDetailslr.lrVehicleType &&
              newDetailslr.lrVehicleType == "Hire" ? (
                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label="Driver name"
                >
                  {newDetailslr ? newDetailslr["driverName"] : ""}
                </Descriptions.Item>
              ) : null}
              {newDetailslr &&
              newDetailslr.lrVehicleType &&
              newDetailslr.lrVehicleType == "Hire" ? (
                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label="Driver Number"
                >
                  {newDetailslr ? newDetailslr["contactNumber"] : ""}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Insurance of Goods"
              >
                {newDetailslr &&
                newDetailslr.insuranceOfGoods &&
                newDetailslr.insuranceOfGoods
                  ? newDetailslr.insuranceOfGoods
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Mode of Payment"
              >
                {newDetailslr &&
                newDetailslr.modeOfPayment &&
                newDetailslr.modeOfPayment
                  ? newDetailslr.modeOfPayment
                  : ""}
              </Descriptions.Item>
              {newDetailslr &&
              newDetailslr.lrVehicleType &&
              newDetailslr.lrVehicleType == "Own" ? (
                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label="Driver Number"
                >
                  {newDetailslr ? newDetailslr["Driver.contactNumber"] : ""}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Status"
              >
                {newDetailslr ? newDetailslr["Deliverystatus.status"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Broker Name"
              >
                {newDetailslr ? newDetailslr["Broker.name"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Broker Address"
              >
                {newDetailslr ? newDetailslr["Broker.address"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Broker Contact"
              >
                {newDetailslr ? newDetailslr["Broker.contactNumber"] : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="E-Way Bill"
              >
                {newDetailslr && newDetailslr.eway ? newDetailslr.eway : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label="Remarks"
              >
                {newDetailslr && newDetailslr.remarks
                  ? newDetailslr.remarks
                  : ""}
              </Descriptions.Item>
            </Descriptions>
            <Table
              pagination={{
                onChange(current, pageSize) {
                  this.setPage(current, pageSize);
                },
              }}
              bordered={true}
              size={"small"}
              className="gx-table-responsive"
              loading={isFetching}
              columns={this.lrcolumns}
              dataSource={newDetailslr.invoices}
            />
          </Card>
        </Modal>
        {visible ? (
          <Modal
            title={
              <div className="fx-modal-trip-title">
                Trip Details{" "}
                <Tag color="#4754C4">
                  <span style={{ display: "grid" }}>
                    Amount Receivable/Payable:{" "}
                    <span>
                      {totalAdvance.toFixed(2)} - {totalExpense.toFixed(2)} ={" "}
                      {driverpayrec.toFixed(2)}
                    </span>
                  </span>
                </Tag>
                <div style={{ marginRight: "20px" }} className="gx-float-right">
                  <Tag color="#4754C4">
                    <span style={{ display: "grid" }}>
                      Driver Amount Receivable/Payable:{" "}
                      <span>{driverAmount.toFixed(2)}</span>
                    </span>
                  </Tag>
                </div>
              </div>
            }
            visible={visible}
            onCancel={this.handleCancel}
            width={"95%"}
            centered
            footer={null}
            closable
            destroyOnClose={true}
          >
            <TripDetails
              tripDieselData={tripDieselData}
              tripLrdetails={tripLrdetails}
              tripDieseldetails={tripDieseldetails}
              tripAdvancedetails={tripAdvancedetails}
              confirmDiesel={this.confirmDiesel}
              onDieselFieldsChange={this.onDieselFieldsChange}
              dieselcolumns={this.dieselcolumns}
              dieselForm={this.dieselForm}
              confirmAdvance={this.confirmAdvance}
              advanceForm={this.advanceForm}
              advancecolumns={this.advancecolumns}
              confirmTrip={this.confirmTrip}
              handleCancel={this.handleCancel}
              onExpenseFieldsChange={this.onExpenseFieldsChange}
              totalKM={totalKM}
              totalExpense={totalExpense}
              startDate={startDate}
              endDate={endDate}
              noOfDays={noOfDays}
              uploadTypeSelect={this.uploadTypeSelect}
              handleUploadType={this.handleUploadType}
              uploadingType={uploadingType}
              fileListUploadTypeObj={fileListUploadTypeObj}
              tripuploadlist={tripuploadlist}
              selectedUploadType={selectedUploadType}
              onRemoveTrip={this.onRemoveTrip}
            />
          </Modal>
        ) : null}
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Card title="Update Transit Status">
            {!isTransitFetching &&
            hasActionPermission(userAccess, FEATURE_TRANSIT, ACTION_EDIT) ? (
              newDetails ? (
                <Row>
                  <Col span={24}>
                    <Form
                      {...formItemLayout}
                      name="transit"
                      onFinish={this.onFinish}
                      initialValues={newDetails}
                      onFieldsChange={(_, allFields) => {
                        this.onFieldsChange(allFields);
                      }}
                      scrollToFirstError
                    >
                      <Form.Item
                        name="statusId"
                        label="Status"
                        rules={[
                          { required: true, message: "Please select status." },
                        ]}
                      >
                        {deliveryStatuses && deliveryStatuses.list ? (
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select status"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                ? option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                : false
                            }
                          >
                            {deliveryStatuses.list.map((d) => (
                              <Option key={d.id}>{d.status}</Option>
                            ))}
                          </Select>
                        ) : (
                          <Skeleton.Input active={true} />
                        )}
                      </Form.Item>
                      <Form.Item
                        name="cityId"
                        label="City"
                        rules={[
                          { required: true, message: "Please select city." },
                        ]}
                      >
                        {alllistCity && alllistCity.list ? (
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select city"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                ? option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                : false
                            }
                          >
                            {alllistCity.list.map((d) => (
                              <Option key={d.id}>{d.name}</Option>
                            ))}
                          </Select>
                        ) : (
                          <Skeleton.Input active={true} />
                        )}
                      </Form.Item>
                      {showUpload ? (
                        <Form.Item label="Ack" name="acknowledgement">
                          <Upload
                            name={"acknowledgement"}
                            customRequest={this.handleUpload}
                            maxCount={1}
                            fileList={fileListObj}
                            showUploadList={{
                              showDownloadIcon: true,
                              downloadIcon: "download ",
                              showRemoveIcon: true,
                            }}
                            onRemove={(file) => this.onRemoveAck(file)}
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                        </Form.Item>
                      ) : null}

                      <Form.Item
                        {...tailFormItemLayout}
                        className="gx-text-center"
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={uploading}
                        >
                          Update
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                  {
                    hasActionPermission(userAccess, FEATURE_TRIP, ACTION_CREATE) && (
                      <>
                        <Col span={4}></Col>
                        <Col span={16}>
                          <Button
                            disabled={record && record.trip_id}
                            style={{ width: "100%" }}
                            type="primary"
                            onClick={() => this.openTripDetails()}
                          >
                            Add Trip Details
                          </Button>
                        </Col>
                        <Col span={4}></Col>
                      </>
                    )
                  }
                </Row>
              ) : (
                <Empty description="Please select LR" />
              )
            ) : hasActionPermission(userAccess, FEATURE_TRANSIT, ACTION_EDIT) &&
              hasActionPermission(userAccess, FEATURE_TRANSIT, ACTION_CREATE) ? (
              <Skeleton />
            ) : (
              <Empty description="No permissions" />
            )}
          </Card>
          <Card title="Recent Activities">
            {isTransitFetching ? (
              <Skeleton avatar paragraph={{ rows: 2 }} />
            ) : activities.length ? (
              <Timeline>
                {activities.map((activity) => {
                  let statusIcon = (
                    <CheckCircleOutlined style={{ fontSize: "16px" }} />
                  );
                  let statusColor = "green";
                  if (!statusMatch && activity.id == record.status_id) {
                    statusIcon = (
                      <ClockCircleOutlined style={{ fontSize: "16px" }} />
                    );
                    statusColor = "blue";
                    statusMatch = true;
                  } else if (statusMatch) {
                    statusIcon = (
                      <QuestionCircleOutlined style={{ fontSize: "16px" }} />
                    );
                    statusColor = "orange";
                  }
                  if (record.status_id == 5) {
                    statusIcon = (
                      <CheckCircleOutlined style={{ fontSize: "16px" }} />
                    );
                    statusColor = "green";
                  }
                  return (
                    <Timeline.Item dot={statusIcon} color={statusColor}>
                      <p className="title">
                        {activity.status}{" "}
                        {activity.status == "Delivered" &&
                        record.acknowledgement ? (
                          <a href={record.acknowledgement}>
                            <FileTextFilled />
                          </a>
                        ) : null}
                      </p>
                      {activity.list.map((activityList) => {
                        return (
                          <>
                            <p className="location">
                              {activityList["City.name"]}
                            </p>
                            <p className="time">
                              {moment(activityList.created_at).format(
                                "DD-MM-YYYY HH:mm a"
                              )}
                            </p>
                          </>
                        );
                      })}
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
        <Col xl={16} lg={16} md={8} sm={24} xs={24}>
          <Card title="LR Details">
            <Search
              placeholder="Search..."
              allowClear
              enterButton="Search"
              onSearch={this.onSearch}
              style={{ width: 300 }}
            />
            <Table
              pagination={{
                onChange(current, pageSize) {
                  setPage(current, pageSize);
                },
                pageSize,
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.onRowClicked(event, record, rowIndex);
                  }, // click row
                };
              }}
              rowClassName={this.setClassName}
              bordered={true}
              size={"small"}
              className="gx-table-responsive"
              loading={isFetching}
              columns={columns}
              dataSource={list}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ lrdetails, transit, branch, trip, user, city }) => {
  const { alllist, isFetching, details } = lrdetails;
  const { lrtransitdetails, deliveryStatuses } = transit;
  const {
    tripLrdetails,
    tripDieseldetails,
    tripAdvancedetails,
    tripuploadlist,
  } = trip;
  const { authUser } = user;
  return {
    alllist,
    isFetching,
    lrtransitdetails,
    deliveryStatuses,
    isTransitFetching: transit.isFetching,
    allBranches: branch.alllist,
    alllistCity: city.alllist,
    tripLrdetails,
    authUser,
    tripDieseldetails,
    tripAdvancedetails,
    tripuploadlist,
    details,
  };
};
export default connect(mapStateToProps, {
  getAllLrDetails,
  getLRTransitDetails,
  getAllDeliveryStatus,
  getAllBranches,
  createTransit,
  createTripDiesel,
  createTripAdvance,
  getTripDetails,
  updateTrip,
  getTripDetailsByLR,
  getTripAdvance,
  getTripDiesel,
  deleteAdvanceDetails,
  deleteDieselDetails,
  getAllCities,
  reset,
  getAllTripUploads,
  updateLrDetails,
  deleteTripuploads,
  getLrDetails,
})(Transit);
