import React from "react";
import { Button, Input, message, Form } from "antd";
import { connect } from "react-redux";
import {
  signin,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import { getFirstAuthorizedPage } from "../util/rolesHelper";

class SignIn extends React.Component {

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  onFinish = values => {
    const { history } = this.props
    const handleSuccess = (e) => {
      const userAccess = JSON.parse(e?.details?.Role?.access || '{}');
      const redirectURL = getFirstAuthorizedPage(userAccess) || "/main/dashboard/dashboard";

      if(userAccess) {
        history.push(
          redirectURL
        );
        return;
      }

      message.error('Try logging in again');
      return;
    }
    const handleFailure = () => {
        message.error('Incorrect user and/or password');
    }
    this.props.signin(handleSuccess, handleFailure, values)
  };
  render() {
    const { isFetching } = this.props;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">

                <img src={"https://via.placeholder.com/272x395"} alt='Neature' />
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn" /></h1>
                <p><IntlMessages id="app.userAuth.bySigning" /></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src="/assets/images/logo.jpg" />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                className="gx-signin-form gx-form-row0">

                <Form.Item
                  rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="email">
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                  <Input type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </Form.Item>
              </Form>
            </div>

            {isFetching ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : null}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({ user }) => {
    const { isFetching } = user
    return { isFetching }
};
export default connect(mapStateToProps, {
  signin
})(SignIn);
