import React from 'react';
import {
    Card,
    Descriptions
} from 'antd';
import { connect } from "react-redux";
import {
    getStateDetails,
} from "../../../../../appRedux/actions";

class Country extends React.Component {

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getStateDetails(id);
    }

    render() {
        const { isFetching, details } = this.props;
        return (
            <Card className="gx-card" title="State Information">
                  <Descriptions title="">
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="State Name">{details && details.name ? details.name: ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Country Name">{details && details.Country && details.Country.name ? details.Country.name: ''}</Descriptions.Item>
                </Descriptions>
            </Card>
        );
    }
};

const mapStateToProps = ({ state }) => {
    const { isFetching, details } = state
    return { isFetching, details }
};
export default connect(mapStateToProps, {
    getStateDetails
})(Country);