import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_CITIES_REQUEST',
  'GET_ALL_CITIES_SUCCESS',
  'GET_ALL_CITIES_FAIL',
  'GET_CITY_DETAILS_REQUEST',
  'GET_CITY_DETAILS_SUCCESS',
  'GET_CITY_DETAILS_FAIL',
  'CITY_REGISTER_REQUEST',
  'CITY_REGISTER_ERROR',
  'CITY_REGISTER_SUCCESS',
  'DELETE_CITY_REQUEST',
  'DELETE_CITY_SUCCESS',
  'DELETE_CITY_FAIL',
  'CITY_UPDATE_REQUEST',
  'CITY_UPDATE_ERROR',
  'CITY_UPDATE_SUCCESS',
  'RESET_DETAILS'
]);
