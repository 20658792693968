import Constants from "constants/Country";

const INITIAL_STATE = {
  data: [],
  alllist: [],
  details: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {    
    case Constants.GET_ALL_COUNTRIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_COUNTRIES_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_COUNTRY_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_COUNTRY_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_COUNTRY_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.COUNTRY_REGISTER_REQUEST:
        return { 
            ...state, 
            isFetching: true 
        };
    case Constants.COUNTRY_REGISTER_ERROR:
        return {
              ...state,
              isFetching: false 
        };
    case Constants.COUNTRY_REGISTER_SUCCESS:
        return {
              ...state, 
              isFetching: false 
        };
    case Constants.DELETE_COUNTRY_REQUEST:
        return {
            ...state,
            isFetching: true
        }
    case Constants.DELETE_COUNTRY_SUCCESS:
        return {
            ...state,
            countryDelete: action.payload,
            isFetching: false
        }
    case Constants.DELETE_COUNTRY_FAIL:
        return {
            ...state,
            error: action.payload,
            isFetching: false
        }

    case Constants.COUNTRY_UPDATE_REQUEST:
      return {
          ...state,
          isFetching: true
      };
    case Constants.COUNTRY_UPDATE_SUCCESS:
        return {
            ...state,
            isFetching: false
        };
    case Constants.COUNTRY_UPDATE_ERROR:
        return {
            ...state,
            isFetching: false
        };
    case Constants.RESET_DETAILS:
      return {
        ...state,
        details: {},
      };
    default:
      return state;
  }
};
