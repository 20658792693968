import React, { useState, useEffect } from "react";
import Pattern from "constants/Pattern";
import moment from "moment";
import { S3_PATH_TU } from "../../../../env";
import {
  Card,
  Row,
  Col,
  Table,
  Form,
  Button,
  Input,
  Collapse,
  DatePicker,
  Select,
  Upload,
  List,
  Typography,
} from "antd";
import {
  UploadOutlined,
  PaperClipOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;
const uploadOptions = [
  {
    key: "Expenses",
    value: "Expenses",
  },
  {
    key: "Advances",
    value: "Advances",
  },
];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 18 },
    lg: { span: 18 },
  },
};
const formItemLayoutExp = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 10 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 14 },
    lg: { span: 14 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
};
function disabledDate(current) {
  // Can not select days after today
  return current && current > moment().endOf("day");
}

const TripDetails = (props) => {
  const {
    tripDieselData,
    tripLrdetails,
    tripDieseldetails,
    tripAdvancedetails,
    confirmDiesel,
    onDieselFieldsChange,
    dieselcolumns,
    confirmAdvance,
    advancecolumns,
    confirmTrip,
    handleCancel,
    onExpenseFieldsChange,
    totalKM,
    dieselForm,
    advanceForm,
    totalExpense,
    startDate,
    endDate,
    noOfDays,
    selectedUploadType,
    uploadTypeSelect,
    handleUploadType,
    uploadingType,
    tripuploadlist,
    onRemoveTrip,
  } = props;
  const [form] = Form.useForm();

  const columns = [
    {
      title: "ID",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) => (
        <span className="gx-link">
          {(this.state.page - 1) * this.state.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Consignee Name",
      dataIndex: "consignee.name",
      key: "consignee.name",
      render: (text) => <span className="gx-link">{text}</span>,
    },
  ];

  const detailsColumns = [
    {
      title: 'Road Tax',
      dataIndex: 'roadTax',
      key: 'roadTax',
    },
    {
      title: 'Fast Tag',
      dataIndex: 'fastTag',
      key: 'fastTag',
    },
    {
      title: 'Bhatta',
      dataIndex: 'bhatta',
      key: 'bhatta',
    },
    {
      title: 'Loading',
      dataIndex: 'loading',
      key: 'loading',
    },
    {
      title: 'Unloading',
      dataIndex: 'unloading',
      key: 'unloading',
    },
    {
      title: 'Border',
      dataIndex: 'border',
      key: 'border',
    },
    {
      title: 'RTO',
      dataIndex: 'RTO',
      key: 'RTO',
    },
    {
      title: 'Maintenance',
      dataIndex: 'maintenance',
      key: 'maintenance',
    },
    {
      title: 'Diesel Pass',
      dataIndex: 'diselPass',
      key: 'diselPass',
    },
    {
      title: 'Parking',
      dataIndex: 'parking',
      key: 'parking',
    },
    {
      title: 'Other',
      dataIndex: 'other',
      key: 'other',
    },
    {
      title: 'Total',
      dataIndex: 'totalExpense',
      key: 'totalExpense',
    },
  ];

  const detailsValues = [{
    roadTax: tripLrdetails.roadTax,
    fastTag: tripLrdetails.fastTag,
    bhatta: tripLrdetails.bhatta,
    loading: tripLrdetails.loading,
    unloading: tripLrdetails.unloading,
    border: tripLrdetails.border,
    RTO: tripLrdetails.RTO,
    maintenance: tripLrdetails.maintenance,
    diselPass: tripLrdetails.diselPass,
    parking: tripLrdetails.parking,
    other: tripLrdetails.other,
    totalExpense: totalExpense,
  }]

  let fileListUploadTypeObj = [];
  fileListUploadTypeObj = tripuploadlist.map((item) => {
    return {
      name: item.url,
      url: `${S3_PATH_TU}${item.url}`,
    };
  });
  const updatedTotalKM = totalKM
    ? totalKM
    : tripLrdetails.startKM && tripLrdetails.endKM
    ? tripLrdetails.endKM - tripLrdetails.startKM
    : 0;
  let totalQuantity = 0;
  let totalRate = 0;
  let totalAmount = 0;
  if (
    tripDieseldetails &&
    tripDieseldetails.rows &&
    tripDieseldetails.rows.length
  ) {
    tripDieseldetails.rows.map((tripDieseldetail) => {
      totalQuantity =
        totalQuantity + parseFloat(tripDieseldetail.dieselQuantity);
      totalRate = totalRate + parseFloat(tripDieseldetail.dieselRate);
      totalAmount = totalAmount + parseFloat(tripDieseldetail.dieselAmount);
    });
  }
  let avgRate = (totalAmount / totalQuantity).toFixed(2);
  tripLrdetails.diselPass =
    updatedTotalKM &&
    tripLrdetails["Vehical.mileage"] &&
    parseFloat(tripLrdetails["Vehical.mileage"]) &&
    avgRate &&
    parseFloat(avgRate)
      ? (
          (updatedTotalKM / parseFloat(tripLrdetails["Vehical.mileage"])) *
          avgRate
        ).toFixed(2)
      : 0;
  tripLrdetails.bhatta =
    noOfDays &&
    parseFloat(noOfDays) &&
    tripLrdetails &&
    tripLrdetails["Driver.dearnessAllowance"] &&
    parseFloat(tripLrdetails["Driver.dearnessAllowance"])
      ? (
          parseFloat(noOfDays) *
          parseFloat(tripLrdetails["Driver.dearnessAllowance"])
        ).toFixed(2)
      : 0;
  form.setFieldsValue({
    diselPass: tripLrdetails.diselPass,
    bhatta: tripLrdetails.bhatta,
    bhattaRemarks: tripLrdetails.bhatta
      ? `${parseFloat(noOfDays)} * ${parseFloat(
          tripLrdetails["Driver.dearnessAllowance"]
        )}`
      : "",
  });
  return (
    <Row className="fx-trip-details-wrap">
      <Col span={24}>
        <Collapse defaultActiveKey={["1", "2", "3"]}>
          <Panel header="Round Trip" key="1">
            <Row>
              <Col span={24}>
                <Card className="gx-card">
                  <Form
                    {...formItemLayoutExp}
                    name="trip"
                    onFinish={confirmTrip}
                    scrollToFirstError
                    initialValues={tripLrdetails}
                    onFieldsChange={(_, allFields) => {
                      onExpenseFieldsChange(allFields);
                    }}
                  >
                    <Row>
                      <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                        <Form.Item
                          name="startKM"
                          label="Start KM"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Start KM.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                        <Form.Item
                          name="endKM"
                          label="End KM"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid End KM.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                        <Form.Item name="totalKM" label="Total KM">
                          <label>{updatedTotalKM}</label>
                        </Form.Item>
                      </Col>
                      <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={24}
                        xs={24}
                        className="gx-text-center"
                      ></Col>
                    </Row>
                    <Row>
                      <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                        <Form.Item name="startDate" label="Start Date">
                          <label>
                            {startDate
                              ? moment(startDate).format("DD/MM/YYYY")
                              : "-"}
                          </label>
                        </Form.Item>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                        <Form.Item name="endDate" label="End Date">
                          <label>
                            {endDate
                              ? moment(endDate).format("DD/MM/YYYY")
                              : "-"}
                          </label>
                        </Form.Item>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                        <Form.Item name="noOfDays" label="No Of Days">
                          <label>{noOfDays}</label>
                        </Form.Item>
                      </Col>
                      <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={24}
                        xs={24}
                        className="gx-text-center"
                      >
                        <Button type="primary" htmlType="submit">
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Panel>
          <Panel header="Trip Advance" key="2">
            <Row>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card className="gx-card" title={`Trip Diesel Details`}>
                  <Form
                    ref={(ref) => dieselForm(ref)}
                    {...formItemLayout}
                    name="trip"
                    onFinish={confirmDiesel}
                    scrollToFirstError
                    onFieldsChange={(_, allFields) => {
                      onDieselFieldsChange(allFields);
                    }}
                  >
                    <Form.Item
                      name="dieselQuantity"
                      label="Quantity"
                      rules={[
                        { required: true, message: "Please enter quantity." },
                        {
                          pattern: new RegExp(Pattern.AMOUNT),
                          message: "Please enter valid Quantity.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="dieselRate"
                      label="Rate"
                      rules={[
                        { required: true, message: "Please enter Rate." },
                        {
                          pattern: new RegExp(Pattern.AMOUNT),
                          message: "Please enter valid Rate.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="dieselAmount" label="Amount">
                      <label>
                        {tripDieselData.dieselAmount &&
                        parseFloat(tripDieselData.dieselAmount)
                          ? tripDieselData.dieselAmount
                          : "0.00"}
                      </label>
                    </Form.Item>
                    <Form.Item className="gx-text-center">
                      <Button type="primary" htmlType="submit">
                        Add
                      </Button>
                    </Form.Item>
                  </Form>
                  <Table
                    pagination={{
                      position: [
                        "none",
                        tripDieseldetails &&
                        tripDieseldetails.rows &&
                        tripDieseldetails.rows.length >= 10
                          ? "bottomRight"
                          : "none",
                      ],
                    }}
                    bordered={true}
                    size={"small"}
                    className="gx-table-responsive"
                    columns={dieselcolumns}
                    dataSource={
                      tripDieseldetails.rows ? tripDieseldetails.rows : []
                    }
                  />
                  <Row className="fx-table-total-wrap-top">
                    <Col span={18} className="fx-table-total-wrap-col-top">
                      <Row className="fx-table-total-wrap">
                        <Col span={8} className="fx-table-total-wrap-col">
                          {totalQuantity.toFixed(2)}
                        </Col>
                        <Col span={8} className="fx-table-total-wrap-col">
                          -
                        </Col>
                        <Col
                          span={8}
                          className="fx-table-total-wrap-col border-none"
                        >
                          {totalAmount.toFixed(2)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="fx-table-total-wrap-top">
                    <Col span={18} className="fx-table-total-wrap-col-top">
                      <Row className="fx-table-total-wrap">
                        <Col span={16} className="fx-table-total-wrap-col">
                          Diesel AVG Rate (Quantity/Rate)
                        </Col>
                        <Col
                          span={8}
                          className="fx-table-total-wrap-col border-none"
                        >
                          {avgRate && parseFloat(avgRate) ? avgRate : "0.00"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card className="gx-card" title={`Trip Advance Details`}>
                  <Form
                    ref={(ref) => advanceForm(ref)}
                    {...formItemLayout}
                    name="trip"
                    onFinish={confirmAdvance}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="advanceFor"
                      label="Advance For"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Advance For.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="advanceTo"
                      label="Advance Date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Advance Date.",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format={"DD/MM/YYYY"}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                    <Form.Item
                      name="advanceAmount"
                      label="Amount"
                      rules={[
                        { required: true, message: "Please enter Amount." },
                        {
                          pattern: new RegExp(Pattern.AMOUNT),
                          message: "Please enter valid Amount.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item className="gx-text-center">
                      <Button type="primary" htmlType="submit">
                        Add
                      </Button>
                    </Form.Item>
                  </Form>
                  <Table
                    bordered={true}
                    size={"small"}
                    className="gx-table-responsive"
                    columns={advancecolumns}
                    dataSource={
                      tripAdvancedetails.rows ? tripAdvancedetails.rows : []
                    }
                  />
                </Card>
              </Col>
            </Row>
          </Panel>
          <Panel header="Trip Expense" key="3">
            <Row>
              <Col span={24}>
                <Card className="gx-card">
                  <Form
                    form={form}
                    {...formItemLayoutExp}
                    name="trip"
                    onFinish={confirmTrip}
                    scrollToFirstError
                    initialValues={tripLrdetails}
                  >
                    <Row>
                      <Col
                        xl={3}
                        lg={3}
                        md={3}
                        sm={24}
                        xs={24}
                        className="gx-text-center gx-mb-xl-4"
                      >
                        Expenses
                      </Col>
                      <Col
                        xl={4}
                        lg={4}
                        md={4}
                        sm={24}
                        xs={24}
                        className="gx-text-center gx-mb-xl-4"
                      >
                        Amount(₹)
                      </Col>
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={24}
                        xs={24}
                        className="gx-text-center gx-mb-xl-4"
                      >
                        Remarks
                      </Col>
                      <Col
                        xl={3}
                        lg={3}
                        md={3}
                        sm={24}
                        xs={24}
                        className="gx-text-center gx-mb-xl-4"
                      >
                        Expenses
                      </Col>
                      <Col
                        xl={4}
                        lg={4}
                        md={4}
                        sm={24}
                        xs={24}
                        className="gx-text-center gx-mb-xl-4"
                      >
                        Amount(₹)
                      </Col>
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={24}
                        xs={24}
                        className="gx-text-center gx-mb-xl-4"
                      >
                        Remarks
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="roadTax"
                          label="Road Tax (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Road Tax.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="roadTaxRemark"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="fastTag"
                          label="Fast Tag (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Fast Tag.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="fastTagRemark"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="bhatta"
                          label="Bhatta (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Bhatta.",
                            },
                          ]}
                        >
                          <Input disabled={true} />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="bhattaRemarks"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="loading"
                          label="Loading (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Loading.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="loadingRemarks"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="unloading"
                          label="Unloading (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Unloading.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="unloadingRemarks"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="border"
                          label="Border (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Border.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="borderRemarks"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="RTO"
                          label="RTO (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid RTO.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="RTORemarks"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="maintenance"
                          label="Maintenance (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Maintenance.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="maintenanceRemarks"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="diselPass"
                          label="Diesel Pass (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Diesel Pass.",
                            },
                          ]}
                        >
                          <Input disabled={true} />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="diselPassRemark"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="parking"
                          label="Parking (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Parking.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="parkingRemarks"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <Form.Item
                          name="other"
                          label="Other (&#8377;)"
                          rules={[
                            {
                              pattern: new RegExp(Pattern.AMOUNT),
                              message: "Please enter valid Other.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                        <Form.Item
                          name="otherRemarks"
                          {...formItemLayoutWithOutLabel}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
                      <Col
                        xl={8}
                        lg={8}
                        md={8}
                        sm={24}
                        xs={24}
                        className="gx-text-center"
                      >
                        <Button type="primary" htmlType="submit">
                          Add
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={() => handleCancel()}
                        >
                          Close
                        </Button>
                      </Col>
                      <Col span={8}></Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col span={24}>
                      <Table
                        bordered={true}
                        size={"small"}
                        className="gx-table-responsive"
                        columns={detailsColumns}
                        dataSource={detailsValues}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <Select
                        className="gx-float-left"
                        showSearch
                        value={selectedUploadType}
                        style={{
                          width: "100px",
                          marginRight: "20px",
                          marginBottom: "10px",
                        }}
                        placeholder="Select Upload Type"
                        optionFilterProp="children"
                        onSelect={(event) => uploadTypeSelect(event)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {uploadOptions.map((d) => (
                          <Option key={d.key}>{d.value}</Option>
                        ))}
                      </Select>
                      {uploadingType ? (
                        <Button type="primary" loading>
                          {" "}
                          Uploading{" "}
                        </Button>
                      ) : (
                        <Upload
                          name={"uploadType"}
                          customRequest={handleUploadType}
                          fileList={fileListUploadTypeObj}
                          showUploadList={false}
                        >
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                      )}
                      <List
                        size="small"
                        dataSource={fileListUploadTypeObj}
                        renderItem={(item) => (
                          <List.Item>
                            <PaperClipOutlined />
                            <a href={item.url}>{item.name}</a>
                            <DeleteOutlined
                              onClick={() => onRemoveTrip(item)}
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default TripDetails;
