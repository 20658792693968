import React, { useState } from "react";
import { Layout, Popover } from "antd";
import { Link } from "react-router-dom";
import { Select } from "antd";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
import AppNotification from "../../components/AppNotification";
import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "../../util/IntlMessages";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { hasActionPermission } from "../../util/rolesHelper";
import { ACTION_CREATE, ACTION_VIEW, FEATURE_BRANCH, FEATURE_BROKER, FEATURE_CITY, FEATURE_COUNTRY, FEATURE_CUSTOMER, FEATURE_DASHBOARD, FEATURE_DRIVER, FEATURE_INVOICE, FEATURE_LR_DETAILS, FEATURE_STATE, FEATURE_TRANSIT, FEATURE_TRIP, FEATURE_USER, FEATURE_VEHICLE } from "../../constants/Roles";

const { Header } = Layout;
const { Option, OptGroup } = Select;
function handleChange(value) {
  console.log(`selected ${value}`);
}

const Topbar = ({ authUser }) => {
  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const [searchText, setSearchText] = useState("Search...");
  const dispatch = useDispatch();

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const updateSearchChatUser = (value) => {
    setSearchText(value);
  };
  let userAccess = [];
  if (
    authUser &&
    authUser.details &&
    authUser.details.Role &&
    authUser.details.Role
  ) {
    userAccess = JSON.parse(authUser.details.Role.access);
  }
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-none gx-d-lg-none gx-pointer">
        <img alt="" src="/assets/images/w-logo.png" />
      </Link>
      <Select
        bordered={false}
        showArrow={false}
        autoClearSearchValue={true}
        value={searchText}
        onChange={() => updateSearchChatUser("Search...")}
        className="top-header"
        showSearch
        style={{ width: 100 }}
        placeholder="Search..."
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {hasActionPermission(userAccess, FEATURE_DASHBOARD, ACTION_VIEW) ? (
          <Option value="dashboard">
            <Link to="/main/dashboard/dashboard">
              <IntlMessages id="sidebar.dashboard" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_TRANSIT, ACTION_VIEW) ? (
          <Option value="transit">
            <Link to="/main/transit/transit">
              <IntlMessages id="sidebar.transit" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_LR_DETAILS, ACTION_VIEW) ? (
          <Option value="lr details">
            <Link to="/main/lrdetails/lrdetails">
              <IntlMessages id="sidebar.lrdetails" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_INVOICE, ACTION_VIEW) ? (
          <Option value="invoices">
            <Link to="/main/invoice/invoices">
              <IntlMessages id="sidebar.invoices" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_TRIP, ACTION_VIEW) ? (
          <Option value="trips">
            <Link to="/main/trip/trips">
              <IntlMessages id="sidebar.trips" />
            </Link>
          </Option>
        ) : (
          ""
        )}

        {hasActionPermission(userAccess, FEATURE_USER, ACTION_VIEW) ? (
          <Option value="users">
            <Link to="/main/settings/user/users">
              <IntlMessages id="sidebar.settings.viewusers" />
            </Link>
          </Option>
        ) : (
          ""
        )}

        {hasActionPermission(userAccess, FEATURE_COUNTRY, ACTION_VIEW) ? (
          <Option value="view countries">
            <Link to="/main/master/country/countries">
              <IntlMessages id="sidebar.master.viewcountries" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_STATE, ACTION_VIEW) ? (
          <Option value="view states">
            <Link to="/main/master/state/states">
              <IntlMessages id="sidebar.master.viewstates" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_CITY, ACTION_VIEW) ? (
          <Option value="view cities">
            <Link to="/main/master/city/cities">
              <IntlMessages id="sidebar.master.viewcities" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_BRANCH, ACTION_VIEW) ? (
          <Option value="view branches">
            <Link to="/main/master/branch/branches">
              <IntlMessages id="sidebar.master.viewbranches" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_CUSTOMER, ACTION_VIEW) ? (
          <Option value="view customers">
            <Link to="/main/master/customer/customers">
              <IntlMessages id="sidebar.master.viewcustomers" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_VIEW) ? (
          <Option value="view vehicles">
            <Link to="/main/master/vehicle/vehicles">
              <IntlMessages id="sidebar.master.viewvehicles" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_DRIVER, ACTION_VIEW) ? (
          <Option value="view drivers">
            <Link to="/main/master/driver/drivers">
              <IntlMessages id="sidebar.master.viewdrivers" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_BROKER, ACTION_VIEW) ? (
          <Option value="view brokers">
            <Link to="/main/master/broker/brokers">
              <IntlMessages id="sidebar.master.viewbrokers" />
            </Link>
          </Option>
        ) : (
          ""
        )}

        {hasActionPermission(userAccess, FEATURE_COUNTRY, ACTION_CREATE) ? (
          <Option value="create countries">
            <Link to="/main/master/country/create">
              <IntlMessages id="sidebar.master.createcountries" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_STATE, ACTION_CREATE) ? (
          <Option value="create states">
            <Link to="/main/master/state/create">
              <IntlMessages id="sidebar.master.createstates" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_CITY, ACTION_CREATE) ? (
          <Option value="create cities">
            <Link to="/main/master/city/create">
              <IntlMessages id="sidebar.master.createcities" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_BRANCH, ACTION_CREATE) ? (
          <Option value="create branches">
            <Link to="/main/master/branch/create">
              <IntlMessages id="sidebar.master.createbranches" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_CUSTOMER, ACTION_CREATE) ? (
          <Option value="create customers">
            <Link to="/main/master/customer/create">
              <IntlMessages id="sidebar.master.createcustomers" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_VEHICLE, ACTION_CREATE) ? (
          <Option value="create vehicles">
            <Link to="/main/master/vehicle/create">
              <IntlMessages id="sidebar.master.createvehicles" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_DRIVER, ACTION_CREATE) ? (
          <Option value="create drivers">
            <Link to="/main/master/driver/create">
              <IntlMessages id="sidebar.master.createdrivers" />
            </Link>
          </Option>
        ) : (
          ""
        )}
        {hasActionPermission(userAccess, FEATURE_BROKER, ACTION_CREATE) ? (
          <Option value="create brokers">
            <Link to="/main/master/broker/create">
              <IntlMessages id="sidebar.master.createbrokers" />
            </Link>
          </Option>
        ) : (
          ""
        )}
      </Select>
      <ul className="gx-header-notifications gx-ml-auto">
        {/* <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={
              <SearchBox
                styleName="gx-popover-search-bar"
                placeholder="Search in app..."
                onChange={updateSearchChatUser}
                value={searchText}
              />
            }
            trigger="click"
          >
            <span className="gx-pointer gx-d-block">
              <i className="icon icon-search-new" />
            </span>
          </Popover>
        </li> */}
        <Auxiliary>
          <li className="gx-notify">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={<AppNotification />}
              trigger="click"
            >
              <span className="gx-pointer gx-d-block">
                <i className="icon icon-notification" />
              </span>
            </Popover>
          </li>

          <li className="gx-msg">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={<MailNotification />}
              trigger="click"
            >
              <span className="gx-pointer gx-status-pos gx-d-block">
                <i className="icon icon-chat-new" />
                <span className="gx-status gx-status-rtl gx-small gx-orange" />
              </span>
            </Popover>
          </li>
        </Auxiliary>
        <li className="gx-language">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={languageMenu()}
            trigger="click"
          >
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <i className={`flag flag-24 flag-${locale.icon}`} />
              <span className="gx-pl-2 gx-language-name">{locale.name}</span>
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover>
        </li>
        <Auxiliary>
          <li className="gx-user-nav">
            <UserInfo />
          </li>
        </Auxiliary>
      </ul>
    </Header>
  );
};

export default Topbar;
