import Constants from '../../constants/Customer';

const INITIAL_STATE = {
  data: [],
  alllist: [],
  details: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {    
    case Constants.GET_ALL_CUSTOMERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_CUSTOMERS_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_CUSTOMER_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_CUSTOMER_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.CUSTOMER_REGISTER_REQUEST:
        return { 
            ...state, 
            isFetching: true 
        };
    case Constants.CUSTOMER_REGISTER_ERROR:
        return {
              ...state,
              isFetching: false 
        };
    case Constants.CUSTOMER_REGISTER_SUCCESS:
        return {
              ...state, 
              isFetching: false 
        };
    case Constants.DELETE_CUSTOMER_REQUEST:
        return {
            ...state,
            isFetching: true
        }
    case Constants.DELETE_CUSTOMER_SUCCESS:
        return {
            ...state,
            customerDelete: action.payload,
            isFetching: false
        }
    case Constants.DELETE_CUSTOMER_FAIL:
        return {
            ...state,
            error: action.payload,
            isFetching: false
        }
    case Constants.CUSTOMER_UPDATE_REQUEST:
        return {
            ...state,
            isFetching: true
        };
    case Constants.CUSTOMER_UPDATE_SUCCESS:
        return {
            ...state,
            isFetching: false
        };
    case Constants.CUSTOMER_UPDATE_ERROR:
        return {
            ...state,
            isFetching: false
        };
    case Constants.RESET_DETAILS:
      return {
        ...state,
        details: {},
      };
    default:
      return state;
  }
};
