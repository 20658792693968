import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_TRIP_REQUEST',
  'GET_ALL_TRIP_SUCCESS',
  'GET_ALL_TRIP_FAIL',
  'GET_TRIP_DETAILS_REQUEST',
  'GET_TRIP_DETAILS_SUCCESS',
  'GET_TRIP_DETAILS_FAIL',
  'GET_TRIP_DETAILS_LR_REQUEST',
  'GET_TRIP_DETAILS_LR_SUCCESS',
  'GET_TRIP_DETAILS_LR_FAIL',
  'GET_LR_TRIP_DETAILS_REQUEST',
  'GET_LR_TRIP_DETAILS_SUCCESS',
  'GET_LR_TRIP_DETAILS_FAIL',
  'CREATE_TRIP_REQUEST',
  'CREATE_TRIP_ERROR',
  'CREATE_TRIP_SUCCESS',
  'DELETE_TRIP_REQUEST',
  'DELETE_TRIP_SUCCESS',
  'DELETE_TRIP_FAIL',
  'UPDATE_TRIP_REQUEST',
  'UPDATE_TRIP_ERROR',
  'UPDATE_TRIP_SUCCESS',
  'CREATE_TRIP_DIESEL_REQUEST',
  'CREATE_TRIP_DIESEL_ERROR',
  'CREATE_TRIP_DIESEL_SUCCESS',
  'CREATE_TRIP_ADVANCE_REQUEST',
  'CREATE_TRIP_ADVANCE_ERROR',
  'CREATE_TRIP_ADVANCE_SUCCESS',
  'GET_TRIP_DIESEL_REQUEST',
  'GET_TRIP_DIESEL_ERROR',
  'GET_TRIP_DIESEL_SUCCESS',
  'GET_TRIP_ADVANCE_REQUEST',
  'GET_TRIP_ADVANCE_ERROR',
  'GET_TRIP_ADVANCE_SUCCESS',
  'DELETE_TRIP_DIESEL_REQUEST',
  'DELETE_TRIP_DIESEL_ERROR',
  'DELETE_TRIP_DIESEL_SUCCESS',
  'DELETE_TRIP_ADVANCE_REQUEST',
  'DELETE_TRIP_ADVANCE_ERROR',
  'DELETE_TRIP_ADVANCE_SUCCESS',
  'CREATE_TRIP_URL_REQUEST',
  'CREATE_TRIP_URL_ERROR',
  'CREATE_TRIP_URL_SUCCESS',
  'GET_ALL_TRIP_URL_REQUEST',
  'GET_ALL_TRIP_URL_SUCCESS',
  'GET_ALL_TRIP_URL_FAIL',
  'DELETE_TRIPURL_REQUEST',
  'DELETE_TRIPURL_SUCCESS',
  'GET_TRIP_URL_REQUEST',
  'GET_TRIP_URL_SUCCESS',
  'GET_TRIP_URL_FAIL',
  'GET_ALL_TRIP_UPLOAD_REQUEST',
  'GET_ALL_TRIP_UPLOAD_SUCCESS',
  'GET_ALL_TRIP_UPLOAD_FAIL',
  'DELETE_TRIP_UPLOADS_REQUEST',
  'DELETE_TRIP_UPLOADS_SUCCESS',
  'DELETE_TRIP_UPLOADS_FAIL',
]);
