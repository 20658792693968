import React from 'react';
import {
    Card,
    Descriptions,
    Checkbox,
    Table
} from 'antd';
import { connect } from "react-redux";
import {
    getRoleDetails,
} from "../../../../../appRedux/actions";

class User extends React.Component {

    state = {
        tableConfig: {
        },
        list: [{
          key: 0,
          feature: 'Dashboard',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 1,
          feature: 'Transit',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 2,
          feature: 'LR Details',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 3,
          feature: 'Invoice',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 4,
          feature: 'Trip',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 5,
          feature: 'User',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 6,
          feature: 'Role',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 7,
          feature: 'Country',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 8,
          feature: 'State',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 9,
          feature: 'City',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 10,
          feature: 'Branch',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 11,
          feature: 'Customer',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 12,
          feature: 'Vehicle',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 13,
          feature: 'Driver',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        },{
          key: 14,
          feature: 'Broker',
          view: 0,
          create: 0,
          edit: 0,
          delete: 0
        }],
    };
      columns = [    
      {
        title: 'Feature',
        dataIndex: 'feature',
        key: 'feature',
        render: text => <span className="gx-link">{text}</span>,
      },
      {
        title: 'View',
        dataIndex: 'view',
        key: 'view',
        width: 150,
        render: (text, record) => <Checkbox checked={!!text} disabled={true}></Checkbox>,
      },
      {
        title: 'Create',
        dataIndex: 'create',
        key: 'create',
        width: 150,
        render: (text, record) => <Checkbox checked={!!text} disabled={true}></Checkbox>,
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        key: 'edit',
        width: 150,
        render: (text, record) => <Checkbox checked={!!text} disabled={true}></Checkbox>,
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        key: 'delete',
        width: 150,
        render: (text, record) => <Checkbox checked={!!text} disabled={true}></Checkbox>,
      }];
    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getRoleDetails(id);
    }

    render() {
        const { isFetching, detailsRole } = this.props;
        let access = [];
        if(detailsRole && detailsRole.access) {
            access = JSON.parse(detailsRole.access);
        }
        return (
            <Card className="gx-card" title="Role Information">
                <Table pagination={false} bordered={false} size={'middle'} className="gx-table-responsive" loading={ isFetching } columns={this.columns} dataSource={access} />
            </Card>
        );
    }
};

const mapStateToProps = ({ user }) => {
    const { isFetching, detailsRole } = user
    return { isFetching, detailsRole }
};
export default connect(mapStateToProps, {
    getRoleDetails
})(User);