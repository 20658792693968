import Constants from "./../../constants/Vehicle";

const INITIAL_STATE = {
  data: [],
  alllist: [],
  details: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
  alllistPAlist:[],
  alllistFTlist:[],
  alllistVTlist:[]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {    
    case Constants.GET_ALL_VEHICLES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_VEHICLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllist: action.payload,
      };
    case Constants.GET_ALL_VEHICLES_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
    case Constants.GET_VEHICLE_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.payload,
      };
    case Constants.GET_VEHICLE_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.VEHICLE_REGISTER_REQUEST:
        return { 
            ...state, 
            isFetching: true 
        };
    case Constants.VEHICLE_REGISTER_ERROR:
        return {
              ...state,
              isFetching: false 
        };
    case Constants.VEHICLE_REGISTER_SUCCESS:
        return {
              ...state, 
              isFetching: false 
        };
    case Constants.DELETE_VEHICLE_REQUEST:
        return {
            ...state,
            isFetching: true
        }
    case Constants.DELETE_VEHICLE_SUCCESS:
        return {
            ...state,
            vehicleDelete: action.payload,
            isFetching: false
        }
    case Constants.DELETE_VEHICLE_FAIL:
        return {
            ...state,
            error: action.payload,
            isFetching: false
        }
    case Constants.VEHICLE_UPDATE_REQUEST:
        return {
            ...state,
            isFetching: true
        };
    case Constants.VEHICLE_UPDATE_SUCCESS:
        return {
            ...state,
            isFetching: false
        };
    case Constants.VEHICLE_UPDATE_ERROR:
        return {
            ...state,
            isFetching: false
        };
    case Constants.GET_ALL_PURCHASEAGENCYS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_PURCHASEAGENCYS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllistPAlist: action.payload,
      };
    case Constants.GET_ALL_PURCHASEAGENCYS_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
      case Constants.GET_ALL_FUELTANKS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_FUELTANKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        alllistFTlist: action.payload,
      };
    case Constants.GET_ALL_FUELTANKS_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };
      case Constants.GET_ALL_VEHICLETYPES_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case Constants.GET_ALL_VEHICLETYPES_SUCCESS:
        return {
          ...state,
          isFetching: false,
          alllistVTlist: action.payload,
        };
      case Constants.GET_ALL_VEHICLETYPES_FAIL:
        return {
          ...state,
          isFetching: false,
          listError: action.error,
        };
      case Constants.RESET_DETAILS:
        return {
          ...state,
          details: {},
        };
    default:
      return state;
  }
};
