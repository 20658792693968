import React from 'react';
import {
    Card,
    Descriptions
} from 'antd';
import { connect } from "react-redux";
import {
    getVehicleDetails,
} from "../../../../../appRedux/actions";
import moment from 'moment';

class Vehicle extends React.Component {

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getVehicleDetails(id);
    }

    render() {
        const { isFetching, details } = this.props;
        let newDetails=null;
        if(details) {
            newDetails = {...details};
            if(details.RCDate){
                newDetails.RCDate = moment(details.RCDate).format('DD/MM/YYYY')
            } 
            if(details.RCDate){
                newDetails.RCDate = moment(details.RCDate).format('DD/MM/YYYY')
            } 
        }
        return (
            <Card className="gx-card" title="Vehicle Information">
                  <Descriptions title="">
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Vehicle Number">{details && details.vehicleNumber ? details.vehicleNumber:''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Engine Number" >{details && details.engineNumber  ? details.engineNumber:''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Chasis Number" >{details && details.chasisNumber ? details.chasisNumber :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="RC Date">{details && details.RCDate ? details.RCDate :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Model Number" >{details && details.modelNumber ? details.modelNumber :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Body Manufacturer" >{details && details['Bodymanufacturer.bodyManufacturer'] ? details['Bodymanufacturer.bodyManufacturer'] :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Financer Name" >{details && details['Financer.financerName'] ? details['Financer.financerName'] :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Insurance Expiry Date" >{details && details['InsuranceExpiryDate'] ? details['InsuranceExpiryDate'] :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Manufacturing Year" >{details && details.manufacturingYear ? details.manufacturingYear :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Fuel Type">{details && details.fuelType ? details.fuelType :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Fuel Tank Capacity" >{details && details['Fueltank.capacity'] ? details['Fueltank.capacity'] :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Purchase Agency" >{details && details['Purchaseagency.agencyName'] ? details['Purchaseagency.agencyName'] :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Purchase Cost" >{details && details.purchaseCost ? details.purchaseCost :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Purchase Date" >{details && details.purchaseDate ? details.purchaseDate :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Body Cost">{details && details.bodyCost ? details.bodyCost :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Body Manufacturing Date" >{details && details.bodyManufacturingDate ? details.bodyManufacturingDate :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Loan Amount">{details && details.loanAmount ? details.loanAmount :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Loan EMI" >{details && details.loanEMI ? details.loanEMI :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Loan Interest Rate" >{details && details.loanInterestRate ? details.loanInterestRate :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Completion Date">{details && details.completionDate ? details.completionDate :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Vehicle Insurance Number" >{details && details.vehicleInsuranceNumber ? details.vehicleInsuranceNumber :''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Vehicle Tyre Count" >{details && details['Vehicaltype.tyreCount'] ? details['Vehicaltype.tyreCount'] :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Vehicle Type" >{details && details['Vehicaltype.vehicalType'] ? details['Vehicaltype.vehicalType'] :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Mileage" >{details && details.mileage ? details.mileage :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Vehicle Manufacturer" >{details && details['Vehiclemanufacturer.manufacturer'] ? details['Vehiclemanufacturer.manufacturer'] :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Insurance Company" >{details && details.insuranceCompany ? details.insuranceCompany :''}</Descriptions.Item>      
                    <Descriptions.Item labelStyle={{fontWeight:500}} label="Insurance Premium Amount">{details && details.insurancePremiumAmount ? details.insurancePremiumAmount :''}</Descriptions.Item>
                </Descriptions>
            </Card>
        );
    }
};

const mapStateToProps = ({ vehicle }) => {
    const { isFetching, details } = vehicle
    return { isFetching, details }
};
export default connect(mapStateToProps, {
    getVehicleDetails
})(Vehicle);