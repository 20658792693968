// expects the userAccess array, feature name and action name as input

import { ACTION_CREATE, ACTION_EDIT, ACTION_VIEW, FEATURE_BRANCH, FEATURE_BROKER, FEATURE_CITY, FEATURE_COUNTRY, FEATURE_CUSTOMER, FEATURE_DASHBOARD, FEATURE_DRIVER, FEATURE_INVOICE, FEATURE_LR_DETAILS, FEATURE_ROLE, FEATURE_STATE, FEATURE_TRANSIT, FEATURE_TRIP, FEATURE_USER, FEATURE_VEHICLE } from "../constants/Roles";

// returns whether the user has action permission for the feature
export const hasActionPermission = (userAccess, feature, action) => {
    if(userAccess && (userAccess.length > 0)) {
        const permissionIndex = userAccess.findIndex(
            element => {
                return (
                    (element.feature === feature) && (element[action] === 1)
                )
            }
        )

        return (permissionIndex !== -1)
    }

    return false;
}

export const getFirstAuthorizedPage = (userAccess) => {
    const viewPage = userAccess.find(ua => ua.view === 1);
    switch (viewPage?.feature) {
        case "Dashboard":
            return "/main/dashboard/dashboard";
        case "Transit":
            return "/main/transit/transit";
        case "LR Details":
            return "/main/lrdetails/lrdetails";
        case "Invoice":
            return "/main/invoice/invoices";
        case "Trip":
            return "/main/trip/trips";
        case "User":
            return "/main/settings/user/users";
        case "Role":
            return "/main/settings/role/roles";
        case "Country":
            return "/main/master/country/countries";
        case "State":
            return "/main/master/state/states";
        case "City":
            return "/main/master/city/cities";
        case "Branch":
            return "/main/master/branch/branches";
        case "Customer":
            return "/main/master/customer/customers";
        case "Vehicle":
            return "/main/master/vehicle/vehicles";
        case "Driver":
            return "/main/master/driver/drivers";
        case "Broker":
            return "/main/master/broker/brokers";
        default:
            return;
    }
}

const getFeatureAndActionFromRoute = (route) => {
    let feature;
    let action;

    if(route?.includes("dashboard")) {
        feature = FEATURE_DASHBOARD
    }
    if(route?.includes("transit")) {
        feature = FEATURE_TRANSIT
    }
    if(route?.includes("lrdetails")) {
        feature = FEATURE_LR_DETAILS
    }
    if(route?.includes("invoice")) {
        feature = FEATURE_INVOICE
    }
    if(route?.includes("trip")) {
        feature = FEATURE_TRIP
    }
    if(route?.includes("user")) {
        feature = FEATURE_USER
    }
    if(route?.includes("role")) {
        feature = FEATURE_ROLE
    }
    if(route?.includes("country")) {
        feature = FEATURE_COUNTRY
    }
    if(route?.includes("state")) {
        feature = FEATURE_STATE
    }
    if(route?.includes("city")) {
        feature = FEATURE_CITY
    }
    if(route?.includes("branch")) {
        feature = FEATURE_BRANCH
    }
    if(route?.includes("customer")) {
        feature = FEATURE_CUSTOMER
    }
    if(route?.includes("vehicle")) {
        feature = FEATURE_VEHICLE
    }
    if(route?.includes("driver")) {
        feature = FEATURE_DRIVER
    }
    if(route?.includes("broker")) {
        feature = FEATURE_BROKER
    }

    if(route?.includes("create")) {
        action = ACTION_CREATE;
    } else if(route?.includes("edit")) {
        action = ACTION_EDIT;
    } else {
        action = ACTION_VIEW;
    }

    return {feature, action}
}

export const userAllowedOnRoute = (route, userAccess) => {
    console.log("route is ", route);
    const {feature, action} = getFeatureAndActionFromRoute(route)
    console.log("feature and action are - ", feature, action);
    return hasActionPermission(userAccess, feature, action);
}