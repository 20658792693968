import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    createBroker,
    getAllCities
} from "../../../../../appRedux/actions";
import Create from './create';

class CreateContainer extends React.Component {

    onFinish = values => {
        const { history ,alllist: { list }, modalAction } = this.props
        console.log("list",list)
        const handleSuccess = () => {
            message.success('Broker created successfully');
            if(modalAction) {
                modalAction();
            } else {
                history.push('/main/master/broker/brokers');
            }
        }
        const handleFailure = () => {
            message.error('Broker creation failed, please try again');
        }
        list.map( item => {
            if(item.name === values.cityId) {                
                values.cityId = item.id
            }
        })
        console.log("data values",values)
        this.props.createBroker(handleSuccess, handleFailure, values)
    }
    componentDidMount() {
        this.props.getAllCities();
      }
    render() {
        const { isFetching,alllist } = this.props;
        console.log("alllist",alllist)
        return (
            <Create onFinish={this.onFinish} isFetching={isFetching} alllist={alllist}/>
        );
    }
};

const mapStateToProps = ({ broker,city}) => {
    const { isFetching } = broker
    const { alllist } = city
    return { isFetching ,alllist }
};
export default connect(mapStateToProps, {
    createBroker,
    getAllCities
})(CreateContainer);