import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Card,
    Select
} from 'antd';
import Pattern from '../../../../../constants/Pattern'
const { Option } = Select;


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 5 },
        lg: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
      span: 24,
    },
};
const Create = (props) => {
    const { onFinish, roles } = props;
    const [form] = Form.useForm();

    const onReset = () => {
      form.resetFields();
    };
    const options = [];
    if (roles && roles.list) {
        for (let i = 0; i < roles.list.length; i++) {
            options.push({
                key: roles.list[i].id,
                value: roles.list[i].role
            })
        }
    }
    return (
        <Card className="gx-card" title="User creation Form">
            <Form
                {...formItemLayout}
                form={form}
                name="user"
                onFinish={onFinish}
                scrollToFirstError
            >

                <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[
                        { required: true, message: 'Please enter first name.',whitespace:true },
                        {
                            pattern: new RegExp(Pattern.NAME),
                            message: "Please enter valid first name."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[
                        { required: true, message: 'Please enter last name.',whitespace:true },
                        {
                            pattern: new RegExp(Pattern.NAME),
                            message: "Please enter valid last name."
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail.',
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input password.',whitespace:false
                        },
                        {
                            pattern: new RegExp(Pattern.PASSWORD),
                            message: "Please enter password of minimum 6 character with no whitespaces."
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password.',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="role_id"
                    label="Role"
                    rules={[{ required: true, message: 'Please select role.', whitespace: true }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select role"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        { options.map(d => <Option key={d.key}>{d.value}</Option>) }
                    </Select>
                </Form.Item>

                <Form.Item {...tailFormItemLayout} className="gx-text-center">
                    <Button type="primary" htmlType="submit">
                        Create
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
export default Create;