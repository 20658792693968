import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    createCountry,
} from "../../../../../appRedux/actions";
import Create from './create';

class CreateContainer extends React.Component {

    onFinish = values => {
        const { history } = this.props
        const handleSuccess = () => {
            message.success('Country created successfully');
            history.push('/main/master/country/countries');
        }
        const handleFailure = () => {
            message.error('Country creation failed, please try again');
        }
        this.props.createCountry(handleSuccess, handleFailure, values)
    }
    render() {
        const { isFetching } = this.props;
        return (
            <Create onFinish={this.onFinish} isFetching={isFetching} />
        );
    }
};

const mapStateToProps = ({ country }) => {
    const { isFetching } = country
    return { isFetching }
};
export default connect(mapStateToProps, {
    createCountry
})(CreateContainer);