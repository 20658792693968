import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_USERS_REQUEST',
  'GET_ALL_USERS_SUCCESS',
  'GET_ALL_USERS_FAIL',
  'GET_USER_DETAILS_REQUEST',
  'GET_USER_DETAILS_SUCCESS',
  'GET_USER_DETAILS_FAIL',
  'USER_REGISTER_REQUEST',
  'USER_REGISTER_ERROR',
  'USER_REGISTER_SUCCESS',
  'DELETE_USER_REQUEST',
  'DELETE_USER_SUCCESS',
  'DELETE_USER_FAIL',
  'USER_UPDATE_REQUEST',
  'USER_UPDATE_ERROR',
  'USER_UPDATE_SUCCESS',
  'USER_SIGNIN_REQUEST',
  'USER_SIGNIN_SUCCESS',
  'USER_SIGNIN_ERROR',
  'USER_SIGNOUT_SUCCESS',
  'INIT_URL',
  'GET_ALL_ROLES_REQUEST',
  'GET_ALL_ROLES_SUCCESS',
  'GET_ALL_ROLES_FAIL',
  'ROLE_REGISTER_REQUEST',
  'ROLE_REGISTER_ERROR',
  'ROLE_REGISTER_SUCCESS',
  'RESET_DETAILS',
  'GET_ROLE_DETAILS_SUCCESS',
  'GET_ROLE_DETAILS_FAIL',
  'GET_ROLE_DETAILS_REQUEST',
  'DELETE_ROLE_REQUEST',
  'DELETE_ROLE_SUCCESS',
  'ROLE_UPDATE_REQUEST',
  'ROLE_UPDATE_ERROR',
  'ROLE_UPDATE_SUCCESS'
]);
