import Constants from '../../constants/lrdetails';
import { API_DOMAIN } from '../../env';

export const getAllLrDetails = (data) => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_LRDETAIL_REQUEST,
    });
    const queryString = `startDate=${data && data.startDate ? data.startDate : ''}&endDate=${data && data.endDate ? data.endDate : ''}&searchText=${data && data.searchText ? data.searchText : ''}&status=${data && data.status ? JSON.stringify(data.status) : ''}&ids=${data && data.ids ? JSON.stringify(data.ids) : ''}&consigneeids=${data && data.consigneeids ? JSON.stringify(data.consigneeids) : ''}&consignorids=${data && data.consignorids ? JSON.stringify(data.consignorids) : ''}&vehicleIds=${data && data.vehicleIds ? JSON.stringify(data.vehicleIds) : ''}&paranoid=${data && data.paranoid ? JSON.stringify(data.paranoid) : ''}&trip=${data && data.trip ? JSON.stringify(data.trip) : ''}`
    return fetch(`${API_DOMAIN}/lrdetails?${queryString}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_LRDETAIL_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_LRDETAIL_SUCCESS,
                    payload: body,
                });
            }
        });
};

export const clearAllLrDetails = () => function (dispatch) {
    dispatch({
        type: Constants.CLEAR_ALL_LRDETAIL,
    });
};
export const getAllDeleveryStatus = () => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_DELIVERYSTATUS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/deliverystatus`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_DELIVERYSTATUS_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_DELIVERYSTATUS_SUCCESS,
                    payload: body,
                });
            }
        });
};
export const getLrDetails = (id) => function (dispatch) {
    dispatch({
      type: Constants.GET_LRDETAIL_REQUEST,
    });
    return fetch(`${API_DOMAIN}/lrdetails/${id}`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_LRDETAIL_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_LRDETAIL_SUCCESS,
            payload: body,
          });
        }
      });
  };
  export const getLRNumber = (id) => function (dispatch) {
      dispatch({
        type: Constants.GET_LRDETAIL_NUMBER_REQUEST,
      });
      return fetch(`${API_DOMAIN}/lrdetails/number`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: Constants.GET_LRDETAIL_NUMBER_FAIL,
              payload: body.error,
            });
          } else {
            dispatch({
              type: Constants.GET_LRDETAIL_NUMBER_SUCCESS,
              payload: body,
            });
          }
        });
    };
  export const checkDuplicate = (id) => function (dispatch) {
      dispatch({
        type: Constants.GET_LRDETAIL_DUPLICATE_REQUEST,
      });
      return fetch(`${API_DOMAIN}/lrdetails/duplicate/${id}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: Constants.GET_LRDETAIL_DUPLICATE_FAIL,
              payload: body.error,
            });
          } else {
            dispatch({
              type: Constants.GET_LRDETAIL_DUPLICATE_SUCCESS,
              payload: body,
            });
          }
        });
    };

export const createLrDetails = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.LRDETAIL_REGISTER_REQUEST
        });
        fetch(`${API_DOMAIN}/lrdetails/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.LRDETAIL_REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.LRDETAIL_REGISTER_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}
export const deleteLrDetails = (handleSuccess, handleFailure, id, permanant, data) => {
    return function (dispatch) {
        dispatch({
            type: Constants.DELETE_LRDETAIL_REQUEST
        });
        const queryString = `&permanant=${permanant ? JSON.stringify(permanant) : ''}`
        return fetch(`${API_DOMAIN}/lrdetails/delete/${id}?${queryString}`, {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(data),
        }).then(() => {
            dispatch({
                type: Constants.DELETE_LRDETAIL_SUCCESS,
                payload: ''
            });
            handleSuccess();
        });
    }
};

export const simpleAction = () => (dispatch) => {
    dispatch({
        type: 'SIMPLE_ACTION',
        payload: 'result_of_simple_action',
    });
}


export const updateLrDetails = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.LRDETAIL_UPDATE_REQUEST
        });
        fetch(`${API_DOMAIN}/lrdetails/update/${data.id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.LRDETAIL_UPDATE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.LRDETAIL_UPDATE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
  }

  export const reset = () => function (dispatch) {
    dispatch({
      type: Constants.RESET_DETAILS,
    });
  };

