import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    updateState,
    getStateDetails,
    getAllCountries,
    reset
} from "../../../../../appRedux/actions";
import Edit from './edit';

class EditContainer extends React.Component {

    onFinish = values => {
        const { match: { params: { id } }, history, alllist: { list }  } = this.props
        const handleSuccess = () => {
            message.success('State updated successfully');
            history.push('/main/master/state/states');
        }
        const handleFailure = () => {
            message.error('State updation failed, please try again');
        }
        values.id = id;
        list.map( item => {
            if(item.name === values.country_id) {
                values.country_id = item.id
            }
        })
        this.props.updateState(handleSuccess, handleFailure, values)
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getStateDetails(id);
        this.props.getAllCountries();
    }
    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const { isFetching, details, alllist } = this.props;
        return (
            <Edit onFinish={this.onFinish} isFetching={isFetching} details={details} alllist={alllist} />
        );
    }
};

const mapStateToProps = ({ state, country }) => {
    const { alllist } = country
    const { isFetching, details } = state
    return { isFetching, details, alllist }
};
export default connect(mapStateToProps, {
    updateState,
    getStateDetails,
    getAllCountries,
    reset
})(EditContainer);