import Constants from '../../constants/Dashboard';

const INITIAL_STATE = {
  data: [],
  statusCount: [],
  statusCountBy: [],
  monthlyRevenue: {},
  monthlyRevenueVehicle: {},
  isFetching: false,
  error: undefined,
  listError: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {    
    case Constants.GET_ALL_STATUS_COUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.GET_ALL_STATUS_COUNT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        statusCount: action.payload,
      };
    case Constants.GET_ALL_STATUS_COUNT_FAIL:
      return {
        ...state,
        isFetching: false,
        listError: action.error,
      };    
      case Constants.GET_ALL_STATUS_COUNT_BY_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case Constants.GET_ALL_STATUS_COUNT_BY_SUCCESS:
        return {
          ...state,
          isFetching: false,
          statusCountBy: action.payload,
        };
      case Constants.GET_ALL_STATUS_COUNT_BY_FAIL:
        return {
          ...state,
          isFetching: false,
          listError: action.error,
        };
      case Constants.GET_MONTHLY_REVENUE_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case Constants.GET_MONTHLY_REVENUE_SUCCESS:
        return {
          ...state,
          isFetching: false,
          monthlyRevenue: action.payload,
        };
      case Constants.GET_MONTHLY_REVENUE_FAIL:
        return {
          ...state,
          isFetching: false,
          listError: action.error,
        };
      case Constants.GET_MONTHLY_REVENUE_VEHICLE_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case Constants.GET_MONTHLY_REVENUE_VEHICLE_SUCCESS:
        return {
          ...state,
          isFetching: false,
          monthlyRevenueVehicle: action.payload,
        };
      case Constants.GET_MONTHLY_REVENUE_VEHICLE_FAIL:
        return {
          ...state,
          isFetching: false,
          listError: action.error,
        };
    default:
      return state;
  }
};
