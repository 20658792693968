import Constants from '../../constants/Dashboard';
import { API_DOMAIN } from '../../env';

export const getStatusCount = (startDate, endDate) => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_STATUS_COUNT_REQUEST,
  });
  return fetch(`${API_DOMAIN}/lrdetail/status?startDate=${startDate}&endDate=${endDate}`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_STATUS_COUNT_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_STATUS_COUNT_SUCCESS,
          payload: body,
        });
      }
    });
};

export const getStatusCountBy = (startDate, endDate) => function (dispatch) {
  dispatch({
    type: Constants.GET_ALL_STATUS_COUNT_BY_REQUEST,
  });
  return fetch(`${API_DOMAIN}/lrdetail/status?startDate=${startDate}&endDate=${endDate}`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_ALL_STATUS_COUNT_BY_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_ALL_STATUS_COUNT_BY_SUCCESS,
          payload: body,
        });
      }
    });
};

export const getMonthlyRevenue = () => function (dispatch) {
  dispatch({
    type: Constants.GET_MONTHLY_REVENUE_REQUEST,
  });
  return fetch(`${API_DOMAIN}/lrdetail/revenue`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_MONTHLY_REVENUE_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_MONTHLY_REVENUE_SUCCESS,
          payload: body,
        });
      }
    });
};

export const getMonthlyRevenueVehicle = (startDate, endDate) => function (dispatch) {
  dispatch({
    type: Constants.GET_MONTHLY_REVENUE_VEHICLE_REQUEST,
  });
  return fetch(`${API_DOMAIN}/lrdetail/revenueVehicle?startDate=${startDate}&endDate=${endDate}`)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        dispatch({
          type: Constants.GET_MONTHLY_REVENUE_VEHICLE_FAIL,
          payload: body.error,
        });
      } else {
        dispatch({
          type: Constants.GET_MONTHLY_REVENUE_VEHICLE_SUCCESS,
          payload: body,
        });
      }
    });
};