import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import _ from 'lodash';
import moment from 'moment'
import {
    updateLrDetails,
    getLrDetails,
    getAllLrDetails,
    getAllCities,
    getAllStates,
    getAllBrokers,
    getAllCustomers,
    getAllVehicles,
    getAllDrivers,
    getAllDeleveryStatus,
    reset,
    getAllBranches
} from "../../../../appRedux/actions";
import Edit from './edit';
import Grid from './grid';

class EditContainer extends React.Component {
    state = {
        branchValue: '',
        vehicleValue: '',
        brokerValue: '',
        view: 'list'
    };

    onFinish = values => {
        const { match: { params: { id } }, history, alllistCity,alllistDS, alllistState,alllistBroker,alllistCustomer,alllistVehicle,alllistDriver, details  } = this.props
        console.log("values",values)
        const handleSuccess = () => {
            message.success('LR Details updated successfully');
            history.push('/main/lrdetails/lrdetails');
        }
        const handleFailure = () => {
            message.error('LR Details updation failed, please try again');
        }
        values.id = id;
        alllistDS.list.map( item => {
            if(item.status === values.status_id) {
                values.status_id = item.id
            }
        })

        let originCityObj = _.find(alllistCity.list, ['id', parseInt(values.origin_city_id)]);
        values.origin_state_id = originCityObj ? originCityObj.state_id : details.origin_state_id
        values.origin_city_id = originCityObj ? values.origin_city_id : details.origin_city_id
        let destinationCityObj = _.find(alllistCity.list, ['id', parseInt(values.destination_city_id)]);
        values.destination_state_id = destinationCityObj ? destinationCityObj.state_id : details.destination_state_id
        values.destination_city_id = destinationCityObj ? values.destination_city_id : details.destination_city_id

        alllistDriver.list.map( item => {
            let value= `${item.first_name} ${item.last_name}`
            if(value === values.driver_id) {
                values.driver_id = item.id
            }
        })
        alllistVehicle.list.map( item => {
            if(item.vehicleNumber === values.vehical_id) {
                values.vehical_id = item.id
            }
        })
        alllistBroker.list.map( item => {
            if(item.name === values.broker_id) {
                values.broker_id = item.id
            }
        })
        alllistCustomer.list.map( item => {
            if(item.name === values.consignor_id) {
                values.consignor_id = item.id
            }
        })
        alllistCustomer.list.map( item => {
            if(item.name === values.consignee_id) {
                values.consignee_id = item.id
            }
        })
        if(values.lrVehicleType == 'Own') {
            values.vehicleNumber = null
            values.driverName = null
            values.contactNumber = null
        } else {
            values.vehical_id = null
            values.driver_id = null
        }
        values.date = values.date.startOf('day')
        values.dateText = (moment(values.date.startOf('day')).format("DD/MM/YYYY")).toString()
        this.props.updateLrDetails(handleSuccess, handleFailure, values)
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getLrDetails(id);
        this.props.getAllLrDetails();
        this.props.getAllCities();     
        this.props.getAllStates();
        this.props.getAllBrokers();
        this.props.getAllCustomers();  
        this.props.getAllVehicles();
        this.props.getAllDrivers();
        this.props.getAllDeleveryStatus()
        this.props.getAllBranches();
    }
    componentWillUnmount() {
        this.props.reset();
    }
    oFieldsChange = (fields) => {
        console.log("fields>>>", fields)
        if(fields && fields.length && fields[0].value == "BR") {
            this.setState({branchValue: fields[0].value});
        } else if(fields && fields.length && fields[0].value !== "BR") {
            this.setState({branchValue: fields[0].value});
        }
        if(fields && fields.length && fields[1].value == "Hire") {
            this.setState({vehicleValue: fields[1].value});
        } else if(fields && fields.length && fields[1].value == "Own") {
            this.setState({vehicleValue: fields[1].value});
        }
        if(fields && fields.length && fields[2].value == "Broker") {
            this.setState({brokerValue: "Broker"});
        } else if(fields && fields.length && fields[2].value == "Self") {
            this.setState({brokerValue: "Self"});
        }
    }
    setView = (view) => {
        this.setState({view})
    }
    render() {
        const { isFetching, details, alllistState,alllistCity,alllistDS, alllistBroker,alllistCustomer, alllistVehicle,alllistDriver, alllistBranch} = this.props;
        const { branchValue, vehicleValue, brokerValue, view } = this.state;
        if(view === 'list') {
            return (
                <Edit
                    onFinish={this.onFinish}
                    isFetching={isFetching}
                    alllistDS={alllistDS}
                    details={details}
                    alllistCity={alllistCity}
                    alllistState={alllistState}
                    alllistBroker={alllistBroker}
                    alllistCustomer={alllistCustomer}
                    alllistVehicle={alllistVehicle} alllistDriver={alllistDriver} 
                    oFieldsChange={this.oFieldsChange}
                    branchValue={branchValue}
                    brokerValue={brokerValue}
                    vehicleValue={vehicleValue}
                    alllistBranch={alllistBranch}
                    getAllCities={this.props.getAllCities}
                    getAllStates={this.props.getAllStates}
                    getAllBrokers={this.props.getAllBrokers}
                    getAllCustomers={this.props.getAllCustomers}
                    getAllVehicles={this.props.getAllVehicles}
                    getAllDrivers={this.props.getAllDrivers}
                    getAllBranches={this.props.getAllBranches}
                    setView={this.setView}
                />
            );
        } else {
            return (
                <Grid
                    onFinish={this.onFinish}
                    isFetching={isFetching}
                    alllistDS={alllistDS}
                    details={details}
                    alllistCity={alllistCity}
                    alllistState={alllistState}
                    alllistBroker={alllistBroker}
                    alllistCustomer={alllistCustomer}
                    alllistVehicle={alllistVehicle} alllistDriver={alllistDriver} 
                    oFieldsChange={this.oFieldsChange}
                    branchValue={branchValue}
                    brokerValue={brokerValue}
                    vehicleValue={vehicleValue}
                    alllistBranch={alllistBranch}
                    getAllCities={this.props.getAllCities}
                    getAllStates={this.props.getAllStates}
                    getAllBrokers={this.props.getAllBrokers}
                    getAllCustomers={this.props.getAllCustomers}
                    getAllVehicles={this.props.getAllVehicles}
                    getAllDrivers={this.props.getAllDrivers}
                    getAllBranches={this.props.getAllBranches}
                    setView={this.setView}
                />
            );
        }
    }
};

const mapStateToProps = ({ lrdetails,city ,state,broker,customer ,vehicle,driver, branch}) => {
   
    const { isFetching, details ,alllistDS} = lrdetails
    return { 
        isFetching,
        details, 
        alllistDS,
        alllistState: state.alllist,
        alllistCity:city.alllist,
        alllistBroker:broker.alllist,
        alllistCustomer:customer.alllist, 
        alllistVehicle:vehicle.alllist,
        alllistDriver:driver.alllist,
        alllistBranch:branch.alllist,
     }
};
export default connect(mapStateToProps, {
    updateLrDetails,
    getLrDetails,
    getAllLrDetails,
    getAllCities,
    getAllStates,
    getAllBrokers,
    getAllCustomers,
    getAllVehicles,
    getAllDrivers,
    getAllDeleveryStatus,
    reset,
    getAllBranches
})(EditContainer);