import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Users from "./users";
import Create from "./create";
import Edit from "./edit";
import View from "./view";

const User = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/users`}/>
    <Route path={`${match.url}/users`} component={Users}/>
    <Route path={`${match.url}/create`} component={Create}/>
    <Route path={`${match.url}/view/:id`} component={View}/>
    <Route path={`${match.url}/edit/:id`} component={Edit}/>
  </Switch>
);

export default User;
