import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_FINANCER_REQUEST',
  'GET_ALL_FINANCER_SUCCESS',
  'GET_ALL_FINANCER_FAIL',
  'FINANCER_REGISTER_REQUEST',
  'FINANCER_REGISTER_ERROR',
  'FINANCER_REGISTER_SUCCESS',
]);
