import React from 'react';
import {
    message
} from 'antd';
import { connect } from "react-redux";
import {
    updateCustomer,
    getCustomerDetails,
    getAllCities,
    reset
} from "../../../../../appRedux/actions";
import Edit from './edit';

class EditContainer extends React.Component {

    onFinish = values => {
        const { match: { params: { id } }, history,alllist: { list }  } = this.props
        const handleSuccess = () => {
            message.success('Customer updated successfully');
            history.push('/main/master/customer/customers');
        }
        const handleFailure = () => {
            message.error('Customer updation failed, please try again');
        }
        values.id = id;
        this.props.updateCustomer(handleSuccess, handleFailure, values)
        console.log("values",values,"id",id)
        this.props.getCustomerDetails(id);
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getCustomerDetails(id);
        this.props.getAllCities();
    }
    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const { isFetching, details,alllist } = this.props;
        console.log("details",details,"alllist",alllist)
        return (
            <Edit onFinish={this.onFinish} isFetching={isFetching} details={details} alllist={alllist}/>
        );
    }
};

const mapStateToProps = ({ customer , city}) => {
    const { isFetching, details } = customer
    const { alllist } = city
    return { isFetching, details ,alllist}
};
export default connect(mapStateToProps, {
    updateCustomer,
    getCustomerDetails,
    getAllCities,
    reset
})(EditContainer);