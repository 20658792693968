import React, { useState } from "react";
import Pattern from "../../../../constants/Pattern";
import {
  Form,
  Input,
  Button,
  Card,
  DatePicker,
  Select,
  Radio,
  Row,
  Col,
  Space,
  Modal,
  Tooltip
} from "antd";
import { MinusCircleOutlined, PlusOutlined, UnorderedListOutlined, AppstoreOutlined } from "@ant-design/icons";
import _ from "lodash";
import * as lrutil from "../../../../preview/lr";
import CityCreate from '../../master/city/create';
import CustomerCreate from '../../master/customer/create';
import VehicleCreate from '../../master/vehicle/create';
import DriverCreate from '../../master/driver/create';
import BranchCreate from '../../master/branch/create';
import BrokerCreate from '../../master/broker/create';
const { TextArea } = Input;

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    span: 24,
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
};

const Grid = (props) => {
  const {
    onFinish,
    alllistCity,
    alllistState,
    alllistBroker,
    alllistDS,
    alllistCustomer,
    alllistVehicle,
    alllistDriver,
    onBlur,
    duplicateLR,
    lrNumber,
    alllistBranch,
    oFieldsChange,
    branchValue,
    brokerValue,
    vehicleValue,
    lrDetailsData,
    isPreview,
    onConfirm,
    handleCancel,
    getAllCities,
    getAllStates,
    getAllBrokers,
    getAllCustomers,
    getAllVehicles,
    getAllDrivers,
    getAllBranches,
    setView
  } = props;
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };
  const onFieldsChangeForm = (fields) => {
    let vehicleField = _.find(fields, (field) =>
      _.includes(field.name, "vehicalId")
    );
    if (vehicleField) {
      let driverObj = _.find(alllistDriver.list, [
        "Vehical.id",
        parseInt(vehicleField.value),
      ]);
      if (driverObj && driverObj.id) {
        form.setFieldsValue({
          driverId: `${driverObj["first_name"]} ${driverObj["last_name"]}`,
        });
      }
      let driverField = _.find(fields, (field) =>
        _.includes(field.name, "driverId")
      );
      let driverObjV = _.find(alllistDriver.list, [
        "id",
        parseInt(driverField.value),
      ]);
      if (driverObjV && driverObjV.id) {
        let vehicleObj = _.find(alllistVehicle.list, [
          "id",
          driverObjV["Vehical.id"],
        ]);
        form.setFieldsValue({ vehicalId: `${vehicleObj["vehicleNumber"]}` });
      }
    }
    oFieldsChange(fields);
  };
  const [originCities, setOriginCities] = useState([]);
  const [destinationCities, setDestinationCities] = useState([]);
  const onOriginChange = (value) => {
    const originCitiesObj = _.filter(alllistCity.list, {
      state_id: parseInt(value),
    });
    const originCitiesArray = [];
    for (let i = 0; i < originCitiesObj.length; i++) {
      originCitiesArray.push({
        key: originCitiesObj[i].id,
        value: originCitiesObj[i].name,
      });
    }
    setOriginCities(originCitiesArray);
  };
  const onDestinationChange = (value) => {
    const destinationCitiesObj = _.filter(alllistCity.list, {
      state_id: parseInt(value),
    });
    const destinationCitiesArray = [];
    for (let i = 0; i < destinationCitiesObj.length; i++) {
      destinationCitiesArray.push({
        key: destinationCitiesObj[i].id,
        value: destinationCitiesObj[i].name,
      });
    }
    setDestinationCities(destinationCitiesArray);
  };
  const Cityoptions = [];
  if (alllistCity && alllistCity.list) {
    for (let i = 0; i < alllistCity.list.length; i++) {
      Cityoptions.push({
        key: alllistCity.list[i].id,
        value: `${alllistCity.list[i].name}, ${alllistCity.list[i]["State.name"]}`,
      });
    }
  }
  const Stateoptions = [];
  if (alllistState && alllistState.list) {
    for (let i = 0; i < alllistState.list.length; i++) {
      Stateoptions.push({
        key: alllistState.list[i].id,
        value: alllistState.list[i].name,
      });
    }
  }
  const Branchoptions = [];
  if (alllistBranch && alllistBranch.list) {
    for (let i = 0; i < alllistBranch.list.length; i++) {
      Branchoptions.push({
        key: alllistBranch.list[i].id,
        value: alllistBranch.list[i].name,
      });
    }
  }
  const Brokeroptions = [];
  if (alllistBroker && alllistBroker.list) {
    for (let i = 0; i < alllistBroker.list.length; i++) {
      Brokeroptions.push({
        key: alllistBroker.list[i].id,
        value: alllistBroker.list[i].name,
      });
    }
  }
  const Customeroptions = [];
  if (alllistCustomer && alllistCustomer.list) {
    for (let i = 0; i < alllistCustomer.list.length; i++) {
      Customeroptions.push({
        key: alllistCustomer.list[i].id,
        value: alllistCustomer.list[i].name,
      });
    }
  }

  const deliverystatusoptions = [];
  if (alllistDS && alllistDS.list) {
    for (let i = 0; i < alllistDS.list.length; i++) {
      deliverystatusoptions.push({
        key: alllistDS.list[i].id,
        value: alllistDS.list[i].status,
      });
    }
  }

  const Vehicleroptions = [];
  if (alllistVehicle && alllistVehicle.list) {
    for (let i = 0; i < alllistVehicle.list.length; i++) {
      Vehicleroptions.push({
        key: alllistVehicle.list[i].id,
        value: alllistVehicle.list[i].vehicleNumber,
      });
    }
  }
  const Driveroptions = [];
  if (alllistDriver && alllistDriver.list) {
    for (let i = 0; i < alllistDriver.list.length; i++) {
      Driveroptions.push({
        key: alllistDriver.list[i].id,
        value: `${alllistDriver.list[i].first_name} ${alllistDriver.list[i].last_name}`,
      });
    }
  }


  //Handle Models for configuration
  const [originVisible, setOriginVisible] = useState(false);
  const [destinationVisible, setDestinationVisible] = useState(false);
  const [consignorVisible, setConsignorVisible] = useState(false);
  const [consigneeVisible, setConsigneeVisible] = useState(false);
  const [vehicleVisible, setVehicleVisible] = useState(false);
  const [driverVisible, setDriverVisible] = useState(false);
  const [brokerVisible, setBrokerVisible] = useState(false);
  const [branchVisible, setBranchVisible] = useState(false);

  return (
    <Card className="gx-card fx-card-grid" title="LR Details creation Form">
      <Row>
        <Col span={24}>
          <Tooltip title="List view">
            <Button onClick={() => setView('list')} type="dashed" shape="circle" icon={<UnorderedListOutlined />} />
          </Tooltip>
          <Tooltip title="Grid view">
            <Button onClick={() => setView('grid')} type="primary" shape="circle" icon={<AppstoreOutlined />} />
          </Tooltip>
        </Col>
      </Row>
      <Form
        {...formItemLayout}
        form={form}
        name="lrdetails"
        onFinish={onFinish}
        scrollToFirstError
        onFieldsChange={(_, allFields) => {
          onFieldsChangeForm(allFields);
        }}
        initialValues={{
          number: lrNumber,
        }}
      >
        <Row>
          <Col span={24} className="lr-radio-options">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="locationType"
                  label=""
                  rules={[
                    { required: true, message: "Please select Location." },
                  ]}
                >
                  <Radio.Group className="fx-radio-group">
                    <Radio value="HO">HO</Radio>
                    <Radio value="BR">BR</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="lrVehicleType"
                  label=""
                  rules={[
                    { required: true, message: "Please select Vehicle Type." },
                  ]}
                >
                  <Radio.Group className="fx-radio-group">
                    <Radio value="Own">Own</Radio>
                    <Radio value="Hire">Hire</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="lrType"
                  label=""
                  rules={[
                    { required: true, message: "Please select LR Type." },
                  ]}
                >
                  <Radio.Group className="fx-radio-group">
                    <Radio value="Broker">Broker</Radio>
                    <Radio value="Self">Self</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {branchValue && branchValue == "BR" ? (
              <Form.Item
                label="Branch Name"
              >
                <Form.Item
                  noStyle
                  name="branchId"
                  rules={[{ required: true, message: "Please select branch name." }]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select branch"
                    optionFilterProp="children"
                    onChange={(value) => onOriginChange(value)}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {Branchoptions.map((d) => (
                      <Option key={d.key}>{d.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button onClick={() => setBranchVisible(true)} className="fx-link-btn" type="link">Create Branch</Button>
                <Modal
                  title={`Create Branch`}
                  centered
                  visible={branchVisible}
                  destroyOnClose={true}
                  onCancel={() => {
                    getAllBranches()
                    setBranchVisible(false)
                  }}
                  footer={false}
                  width={'80%'}
                >
                  <BranchCreate
                    modalAction={() => {
                      getAllBranches()
                      setBranchVisible(false)
                    }}
                  />
                </Modal>
              </Form.Item>
            ) : null}
            <Form.Item
              label="Consignor"
            >
              <Form.Item
                noStyle
                name="consignorId"
                rules={[
                  {
                    required: true,
                    message: "Please select consignor.",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Consignor"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Customeroptions.map((d) => (
                    <Option key={d.key}>{d.value}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Button onClick={() => setConsignorVisible(true)} className="fx-link-btn" type="link">Create Consignor</Button>
              <Modal
                title={`Create Consignor`}
                centered
                visible={consignorVisible}
                destroyOnClose={true}
                onCancel={() => {
                  getAllCustomers()
                  setConsignorVisible(false)
                }}
                footer={false}
                width={'80%'}
              >
                <CustomerCreate
                  modalAction={() => {
                    getAllCustomers()
                    setConsignorVisible(false)
                  }}
                />
              </Modal>
            </Form.Item>
            <Form.Item
              label="Consignee"
            >
              <Form.Item
                noStyle
                name="consigneeId"
                rules={[
                  {
                    required: true,
                    message: "Please select consignee.",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select consignee"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Customeroptions.map((d) => (
                    <Option key={d.key}>{d.value}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Button onClick={() => setConsigneeVisible(true)} className="fx-link-btn" type="link">Create Consignee</Button>
              <Modal
                title={`Create Consignee`}
                centered
                visible={consigneeVisible}
                destroyOnClose={true}
                onCancel={() => {
                  getAllCustomers()
                  setConsigneeVisible(false)
                }}
                footer={false}
                width={'80%'}
              >
                <CustomerCreate
                  modalAction={() => {
                    getAllCustomers()
                    setConsigneeVisible(false)
                  }}
                />
              </Modal>
            </Form.Item>
            <Form.Item
              name="additionalAddress"
              label="Additional Delivery Point Address"
            >
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item
              label="Delivery Status"
              name="statusId"
              initialValue={"Package Picked"}
            // rules={[{ required: true, message: 'Please select Delivery Status.', whitespace: true }]}
            >
              <Select
                disabled
                showSearch
                style={{ width: "100%" }}
                placeholder="Select status"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {deliverystatusoptions.map((d) => (
                  <Option key={d.key}>{d.value}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="E-Way Bill"
              name="eway"
              rules={[
                { required: false, message: "Please enter eway bill." },
                {
                  pattern: new RegExp(Pattern.ADHAR_NO),
                  message: "Please enter valid eway bill(ex.9999 4105 7058).",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>

          </Col>
          <Col lg={10} md={10} sm={24} xs={24}>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="date"
                  label="LR Date"
                  rules={[{ required: true, message: "Please enter LR date." }]}
                >
                  <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item name="bookingType" label="Booking Type">
                  <Radio.Group>
                    <Radio value="FTL">FTL</Radio>
                    <Radio value="PTL">PTL</Radio>
                    <Radio value="Others">Others</Radio>
                  </Radio.Group>
                </Form.Item>
                {vehicleValue && vehicleValue == "Hire" ? (
                  <>
                    <Form.Item
                      name="vehicleNumber"
                      label="Vehicle Number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter vehicle number.",
                          whitespace: true,
                        },
                        {
                          pattern: new RegExp(Pattern.VEHICLE_NUMBER),
                          message: "Please enter valid vehicle number.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      label="Vehicle Number"
                    >
                      <Form.Item
                        noStyle
                        name="vehicalId"
                        rules={[
                          {
                            required: true,
                            message: "Please select vehicle number.",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select vehicle number"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          }
                        >
                          {Vehicleroptions.map((d) => (
                            <Option key={d.key}>{d.value}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Button onClick={() => setVehicleVisible(true)} className="fx-link-btn" type="link">Create Vehicle</Button>
                      <Modal
                        title={`Create Vehicle`}
                        centered
                        visible={vehicleVisible}
                        destroyOnClose={true}
                        onCancel={() => {
                          getAllVehicles()
                          setVehicleVisible(false)
                        }}
                        footer={false}
                        width={'80%'}
                      >
                        <VehicleCreate
                          modalAction={() => {
                            getAllVehicles()
                            setVehicleVisible(false)
                          }}
                        />
                      </Modal>
                    </Form.Item>
                  </>
                )}
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Origin"
                >
                  <Form.Item
                    noStyle
                    name="originCityId"
                    rules={[
                      {
                        required: true,
                        message: "Please select origin city.",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select origin"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {Cityoptions.map((d) => (
                        <Option key={d.key}>{d.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button onClick={() => setOriginVisible(true)} className="fx-link-btn" type="link">Create Origin</Button>
                  <Modal
                    title={`Create Origin`}
                    centered
                    visible={originVisible}
                    destroyOnClose={true}
                    onCancel={() => {
                      getAllCities()
                      setOriginVisible(false)
                    }}
                    footer={false}
                    width={'80%'}
                  >
                    <CityCreate
                      modalAction={() => {
                        getAllCities()
                        setOriginVisible(false)
                      }}
                    />
                  </Modal>
                </Form.Item>
                <Form.Item
                  label="Destination"
                >
                  <Form.Item
                    noStyle
                    name="destinationCityId"
                    rules={[
                      {
                        required: true,
                        message: "Please select destination city.",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select destination"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {Cityoptions.map((d) => (
                        <Option key={d.key}>{d.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button onClick={() => setDestinationVisible(true)} className="fx-link-btn" type="link">Create Destination</Button>
                  <Modal
                    title={`Create Destination`}
                    centered
                    visible={destinationVisible}
                    destroyOnClose={true}
                    onCancel={() => {
                      getAllCities()
                      setDestinationVisible(false)
                    }}
                    footer={false}
                    width={'80%'}
                  >
                    <CityCreate
                      modalAction={() => {
                        getAllCities()
                        setOriginVisible(false)
                      }}
                    />
                  </Modal>
                </Form.Item>
                {vehicleValue && vehicleValue == "Hire" ? (
                  <>
                    <Form.Item
                      name="driverName"
                      label="Driver name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter driver name.",
                          whitespace: true,
                        },
                        {
                          pattern: new RegExp("^[a-zA-Z ]*$"),
                          message: "Please enter valid driver name.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Number"
                      name="contactNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter contact number.",
                          whitespace: true,
                        },
                        {
                          pattern: new RegExp(Pattern.PHONE_NUMBER),
                          message: "Please enter valid contact number.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      label="Driver"
                    >
                      <Form.Item
                        noStyle
                        name="driverId"
                        rules={[
                          {
                            required: true,
                            message: "Please select driver.",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select driver"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          }
                        >
                          {Driveroptions.map((d) => (
                            <Option key={d.key}>{d.value}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Button onClick={() => setDriverVisible(true)} className="fx-link-btn" type="link">Create Driver</Button>
                      <Modal
                        title={`Create Driver`}
                        centered
                        visible={driverVisible}
                        destroyOnClose={true}
                        onCancel={() => {
                          getAllDrivers()
                          setDriverVisible(false)
                        }}
                        footer={false}
                        width={'80%'}
                      >
                        <DriverCreate
                          modalAction={() => {
                            getAllDrivers()
                            setDriverVisible(false)
                          }}
                        />
                      </Modal>
                    </Form.Item>
                  </>
                )}
              </Col>
              <Col span={24}>
                <Form.Item name="remarks" label="Remarks">
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            {brokerValue && brokerValue == "Broker" ? (
              <Form.Item
                label="Broker"
              >
                <Form.Item
                  noStyle
                  name="brokerId"
                  rules={[
                    {
                      required: false,
                      message: "Please select broker.",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Broker"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {Brokeroptions.map((d) => (
                      <Option key={d.key}>{d.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button onClick={() => setBrokerVisible(true)} className="fx-link-btn" type="link">Create Broker</Button>
                <Modal
                  title={`Create Broker`}
                  centered
                  visible={brokerVisible}
                  destroyOnClose={true}
                  onCancel={() => {
                    getAllBrokers()
                    setBrokerVisible(false)
                  }}
                  footer={false}
                  width={'80%'}
                >
                  <BrokerCreate
                    modalAction={() => {
                      getAllBrokers()
                      setBrokerVisible(false)
                    }}
                  />
                </Modal>
              </Form.Item>
            ) : null}
            <Form.Item name="insuranceOfGoods" label="Insurance of Goods">
              <Radio.Group>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="modeOfPayment" label="Mode of Payment">
              <Radio.Group>
                <Radio value="Paid">Paid</Radio>
                <Radio value="To be billed">To be billed</Radio>
                <Radio value="To Pay">To Pay</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="weight"
              label="Weight(KG)"
              rules={[
                { required: true, message: "Please enter weight." },
                {
                  pattern: new RegExp(Pattern.AMOUNT),
                  message: "Please enter valid weight.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="packageNumber"
              label="Quantity"
              rules={[
                {
                  required: true,
                  message: "Please enter quantity.",
                  whitespace: true,
                },
                {
                  pattern: new RegExp(Pattern.AMOUNT),
                  message: "Please enter valid quantity.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="amount"
              label="Freight Amount"
              rules={[
                { required: true, message: "Please enter amount." },
                {
                  pattern: new RegExp(Pattern.AMOUNT),
                  message: "Please enter valid amount.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.List name="invoices">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space
                      size="small"
                      key={key}
                      //   style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                      className="lrDetails-invoice-grid"
                    >
                      <Form.Item
                        {...restField}
                        {...formItemLayoutWithOutLabel}
                        name={[name, "invoiceNo"]}
                        fieldKey={[fieldKey, "invoiceNo"]}
                      >
                        <Input placeholder="Invoice Number" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        {...formItemLayoutWithOutLabel}
                        name={[name, "description"]}
                        fieldKey={[fieldKey, "description"]}
                      >
                        <Input placeholder="Description" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        {...formItemLayoutWithOutLabel}
                        name={[name, "weight"]}
                        fieldKey={[fieldKey, "weight"]}
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid weight.",
                          },
                        ]}
                      >
                        <Input placeholder="Weight" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        {...formItemLayoutWithOutLabel}
                        name={[name, "amount"]}
                        fieldKey={[fieldKey, "amount"]}
                        rules={[
                          {
                            pattern: new RegExp(Pattern.AMOUNT),
                            message: "Please enter valid amount.",
                          },
                        ]}
                      >
                        <Input placeholder="Amount" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        {...formItemLayoutWithOutLabel}
                        name={[name, "eway"]}
                        fieldKey={[fieldKey, "eway"]}
                        rules={[
                          {
                            required: false,
                            message: "Please enter eway bill.",
                          },
                          {
                            pattern: new RegExp(Pattern.ADHAR_NO),
                            message:
                              "Please enter valid eway bill(ex.9999 4105 7058).",
                          },
                        ]}
                      >
                        <Input placeholder="E-way Bill Number" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        {...formItemLayoutWithOutLabel}
                        name={[name, "validity"]}
                        fieldKey={[fieldKey, "validity"]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Invoices
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Form.Item {...tailFormItemLayout} className="gx-text-center">
          <Button type="primary" htmlType="submit" disabled={duplicateLR}>
            Create
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
      {lrDetailsData && lrDetailsData.id ? (
        <Modal
          title="LR Preview"
          visible={isPreview}
          onOk={() => onConfirm()}
          okText="Confirm"
          onCancel={() => handleCancel()}
          width={"75%"}
          centered
        >
          <div
            dangerouslySetInnerHTML={{ __html: lrutil.lrhtml(lrDetailsData) }}
          ></div>
        </Modal>
      ) : null}
    </Card>
  );
};
export default Grid;
