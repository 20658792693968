import moment from 'moment';

export const triphtml = (details) => {
    const { data } = details;
    let dieselHtml = '';
    let dieselRateHtml = '';
    let dieselAmountHtml = '';
    let advanceToHtml = '';
    let advanceForHtml = '';
    let advanceAmountHtml = '';
    data.diesel.map(item => dieselHtml += `<h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${item.dieselQuantity}</h6>`)
    data.diesel.map(item => dieselRateHtml += `<h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${item.dieselRate}</h6>`)
    data.diesel.map(item => dieselAmountHtml += `<h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${item.dieselAmount}</h6>`)

    data.advance.map(item => advanceToHtml += `<h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${item.advanceTo ? moment(item.advanceTo).format('DD/MM/YYYY') : ''}</h6>`)
    data.advance.map(item => advanceForHtml += `<h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${item.advanceFor}</h6>`)
    data.advance.map(item => advanceAmountHtml += `<h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${item.advanceAmount}</h6>`)
    console.log("data>>>", data);

    let routesHtml = '';
    data.route.map(item => routesHtml += `<h6 style="min-height: 13px; width: 18%; float: left; margin: 0px; padding: 5px; border-left: 1px solid; border-top: 1px solid;">${item.lr_number}</h6>
    <h6 style="min-height: 13px; width: 36%; float: left; margin: 0px; padding: 5px; border-left: 1px solid; border-top: 1px solid;">${item.origin}</h6>
    <h6 style="min-height: 13px; width: 36%; float: left; margin: 0px; padding: 5px; border-left: 1px solid; border-top: 1px solid;">${item.destination}</h6>`)
 
    
    let statusHtml = '';
    data.formattedLrTrips.map(item => statusHtml += `<div style="width: 10%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px; border-top: 1px solid;">${item.lr_number}</h6></div>
    <div style="width: 22.5%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px; border-left: 1px solid; border-top: 1px solid;">${item.PackagePicked}</h6></div>
    <div style="width: 22.5%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px; border-left: 1px solid; border-top: 1px solid;">${item.Dispatched}</h6></div>
    <div style="width: 22.5%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px; border-left: 1px solid; border-top: 1px solid;">${item.InTransit}</h6></div>
    <div style="width: 22.5%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px; border-left: 1px solid; border-top: 1px solid;">${item.Delivered}</h6></div>`)
     
    return `<!DOCTYPE html>
    <html>
      <head>
        <mate charest="utf-8" />
        <title>Trip Details</title>
        <style>
          body { font-size: 10px; }
          h2 { font-family: Arial, Helvetica, sans-serif; font-weight: normal; font-size: 14px;}
          h6 { font-family: Arial, Helvetica, sans-serif; font-weight: normal; font-size: 10px;}
          h4 { font-family: Arial, Helvetica, sans-serif; font-size: 14px;}
          .titletxt { font-weight: 600; }
        </style>
      </head>
      <body style="margin: 20px;">
        <div style="text-align: right;">${data.tripNo}</div>
        <div><h2 style="margin: 5px; text-align: center;" class="titletxt">ELEGANT EXPRESS LOGISTICS PRIVATE LIMITED</h2></div>
        <div style="border: 1px solid #000;">
            <div style="width: 34%; float: left;">
                <div style="width: 100%; float: left;">
                    <div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 0px; padding: 5px; border-right: 1px solid;" class="titletxt">Vehicle no.</h6>
                        </div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 5px;">${data.vehicle.vehicleNumber}</h6>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 0px; padding: 5px; border-right: 1px solid;" class="titletxt">Driver Name</h6>
                        </div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 5px;">${data.driver.first_name} ${data.driver.last_name}</h6>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
            </div>
            <div style="width: 33%; float: left;">
                <div style="width: 100%; float: left; border-left: 1px solid;">
                    <div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 0px; padding: 5px; border-right: 1px solid;" class="titletxt">Start Date</h6>
                        </div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 5px;">${data.startDate}</h6>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 0px; padding: 5px; border-right: 1px solid;" class="titletxt">End Date</h6>
                        </div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 5px;">${data.endDate}</h6>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div>
                        <div style="width: 50%; float: left;">
                            <h6 style="margin: 0px; padding: 5px; border-right: 1px solid;" class="titletxt">No. of days</h6>
                        </div>
                        <div style="width: 50%; float: left;">
                            <h6 style="margin: 5px;">${data.noOfDays}</h6>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
            </div>
            <div style="width: 33%; float: left;">
                <div style="width: 100%; float: left; border-left: 1px solid;">
                    <div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 0px; padding: 5px; border-right: 1px solid;" class="titletxt">Start KM</h6>
                        </div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 5px;">${data.startKM}</h6>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 0px; padding: 5px; border-right: 1px solid;" class="titletxt">End KM</h6>
                        </div>
                        <div style="width: 50%; float: left; border-bottom: 1px solid #000;">
                            <h6 style="margin: 5px;">${data.endKM}</h6>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div>
                        <div style="width: 50%; float: left;">
                            <h6 style="margin: 0px; padding: 5px; border-right: 1px solid;" class="titletxt">Total KM</h6>
                        </div>
                        <div style="width: 50%; float: left;">
                            <h6 style="margin: 5px;">${data.totalTripKm}</h6>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
        <div>
            <div style="width: 100%;">
                <div style="border: 1px solid #000; margin-top: 10px; width:49%;  float: left;">
                    <div>
                        <div style="width: 20%; float: left;">
                            <h6 style="margin: 0px; padding: 5px;" class="titletxt">Diesel Details</h6>
                        </div>
                        <div style="width: 80%; float: left;">
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Quantity</h6>
                                ${dieselHtml}
                            </div>
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Rate</h6>
                                ${dieselRateHtml}
                            </div>
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Amount</h6>
                                ${dieselAmountHtml}
                            </div>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div>
                        <div style="width: 20%; float: left;">
                            <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000;" class="titletxt">Total</h6>
                        </div>
                        <div style="width: 80%; float: left;">
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.dieselTotal.quantity}</h6>
                            </div>
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">-</h6>
                            </div>
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.dieselTotal.amount}</h6>
                            </div>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div>
                        <div style="width: 72.8%; float: left;">
                            <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000;" class="titletxt">Diesel AVG Rate(Quantity/Rate)</h6>
                        </div>
                        <div style="width: 27%; float: left;">
                            <div style="width: 100%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.dieselTotal.avg}</h6>
                            </div>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
                <div style="border: 1px solid #000; margin-top: 10px;  width:49%; float: right;">
                    <div>
                        <div style="width: 20%; float: left;">
                            <h6 style="margin: 0px; padding: 5px;" class="titletxt">Advance Details</h6>
                        </div>
                        <div style="width: 80%; float: left;">
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Advance Date</h6>
                                ${advanceToHtml}
                            </div>
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Advance For</h6>
                                ${advanceForHtml}
                            </div>
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Amount</h6>
                                ${advanceAmountHtml}
                            </div>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div>
                        <div style="width: 20%; float: left;">
                            <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000;" class="titletxt">Total</h6>
                        </div>
                        <div style="width: 80%; float: left;">
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">&nbsp;</h6>
                            </div>
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">&nbsp;</h6>
                            </div>
                            <div style="width: 33%; float: left;">
                                <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.advanceTotal}</h6>
                            </div>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
                <div style="margin-top: 10px;">
                    <div style="width: 100%; float: left;">
                        <h6 style="margin: 0px; padding: 5px;margin-top: 10px;border: 1px solid;" class="titletxt">Total Advance(Diesel Details + Advance Details): ${data.totalAdvance}</h6>
                    </div>
                    <div style="clear: both;"></div>
                </div>
            </div>
            <div style="width: 100%; float: left;">
                <div style="border: 1px solid #000; margin-top: 10px; margin-left: 5px;">
                    <div>
                        <div style="width: 15%; float: left;">
                            <h6 style="margin: 0px; padding: 5px;" class="titletxt">Trip Expenses</h6>
                        </div>
                        <div style="width: 85%; float: left;">
                            <div style="width: 15%; float: left;">
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Title</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Road Tax</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Fast Tag</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Bhatta</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Loading</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Unloading</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Border</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">RTO</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Maintenance</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Diesel Pass</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Other</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Parking</h6>
                            </div>
                            <div style="width: 15%; float: left;">
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Amount</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.roadTax}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.fastTag}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.bhatta}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.loading}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.unloading}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.border}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.RTO}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.maintenance}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.diselPass}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.other}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.parking}</h6>
                            </div>
                            <div style="width: 69%; float: left;">
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Remark</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.roadTaxRemark ? data.expenses.roadTaxRemark : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.fastTagRemark ? data.expenses.fastTagRemark : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.bhattaRemarks ? data.expenses.bhattaRemarks : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.loadingRemarks ? data.expenses.loadingRemarks : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.unloadingRemarks ? data.expenses.unloadingRemarks : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.borderRemarks ? data.expenses.borderRemarks : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.RTORemarks ? data.expenses.RTORemarks : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.maintenanceRemarks ? data.expenses.maintenanceRemarks : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.diselPassRemark ? data.expenses.diselPassRemark : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.otherRemarks ? data.expenses.otherRemarks : ''}</h6>
                                <h6 style="min-height: 23px; margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expenses.parkingRemarks ? data.expenses.parkingRemarks : ''}</h6>
                            </div>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div>
                        <div style="width: 27.8%; float: left;">
                            <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000;" class="titletxt">Total</h6>
                        </div>
                        <div style="width: 72.2%; float: left;">
                            <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.expensesTotal}</h6>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
        <div style="border: 1px solid #000; margin-top: 10px;">
            <div style="width: 50%; float: left;">
                <h6 style="margin: 0px; padding: 5px;" class="titletxt">Driver Receivable/Payable Amount(Total Advance - Total Expenses)</h6>
            </div>
            <div style="width: 50%; float: left;">
                <div style="width: 100%; float: left;">
                    <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;">${data.driverPayable}</h6>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
        <div style="border: 1px solid #000; margin-top: 10px;">
            <div style="width: 50%; float: left;">
                <div style="width: 50%; float: left;">
                    <h6 style="margin: 0px; padding: 5px;" class="titletxt">Total Trip Expenses</h6>
                    <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000;" class="titletxt">Total Trip KM</h6>
                    <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000;" class="titletxt">Per KM Rate</h6>
                </div>
                <div style="width: 50%; float: left;">
                    <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;">${data.expensesTotal}</h6>
                    <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.totalTripKm}</h6>
                    <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.costPerKM}</h6>
                </div>
                <div style="clear: both;"></div>
            </div>
            <div style="width: 50%; float: left;">
                <div style="width: 50%; float: left;">
                    <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Total Trip Freight</h6>
                    <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Total Trip KM</h6>
                    <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;" class="titletxt">Per KM Rate</h6>
                </div>
                <div style="width: 50%; float: left;">
                    <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;">${data.fright}</h6>
                    <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.totalTripKm}</h6>
                    <h6 style="margin: 0px; padding: 5px; border-top: 1px solid #000; border-left: 1px solid;">${data.chargedPerKM}</h6>
                </div>
                <div style="clear: both;"></div>
            </div>
            <div style="clear: both;"></div>
        </div>
        <div style="border: 1px solid #000; margin-top: 10px;">
            <div style="width: 50%; float: left;">
                <h6 style="margin: 0px; padding: 5px;" class="titletxt">Per KM Profit/Loss</h6>
            </div>
            <div style="width: 50%; float: left;">
                <div style="width: 100%; float: left;">
                    <h6 style="margin: 0px; padding: 5px; border-left: 1px solid;">${data.profitLoss}</h6>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
        <div style="border: 1px solid #000; margin-top: 10px;">
            <div style="width: 10%; float: left;">
                <h6 style="margin: 0px; padding: 5px;" class="titletxt">Route</h6>
            </div>
            <div style="width: 90%; float: left;">
                <h6 style="min-height: 13px; width: 18%; float: left; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">LR</h6>
                <h6 style="min-height: 13px; width: 36%; float: left; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Start City</h6>
                <h6 style="min-height: 13px; width: 36%; float: left; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Destination City</h6>
                ${routesHtml}
            </div>
            <div style="clear: both;"></div>
        </div>
        <div style="border: 1px solid #000; margin-top: 10px;">
            <div style="width: 100%; float: left; ">
                <div style="width: 10%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px;" class="titletxt">LR</h6></div>
                <div style="width: 22.5%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Package Picked</h6></div>
                <div style="width: 22.5%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Dispatch</h6></div>
                <div style="width: 22.5%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Transit</h6></div>
                <div style="width: 22.5%; float: left; "><h6 style="min-height: 13px; margin: 0px; padding: 5px; border-left: 1px solid;" class="titletxt">Delivered</h6></div>
                ${statusHtml}
            </div>
            <div style="clear: both;"></div>
        </div>
      </body>
    </html>`;
};