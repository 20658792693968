import React from "react";
import {Route, Switch} from "react-router-dom";
import City from "./city";
import Create from "./create";
import Edit from "./edit";
import View from "./view";

const Country = ({match}) => (
  <Switch>
    <Route path={`${match.url}/cities`} component={City}/>
    <Route path={`${match.url}/create`} component={Create}/>
    <Route path={`${match.url}/view/:id`} component={View}/>
    <Route path={`${match.url}/edit/:id`} component={Edit}/>
  </Switch>
);

export default Country;
