import Constants from '../../constants/state';
import { API_DOMAIN } from '../../env';

export const getAllStates = () => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_STATES_REQUEST,
    });
    return fetch(`${API_DOMAIN}/states`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_STATES_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_STATES_SUCCESS,
                    payload: body,
                });
            }
        });
};
export const getStateDetails = (id) => function (dispatch) {
    dispatch({
      type: Constants.GET_STATE_DETAILS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/state/details/${id}`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_STATE_DETAILS_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_STATE_DETAILS_SUCCESS,
            payload: body,
          });
        }
      });
  };

export const createState = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.STATE_REGISTER_REQUEST
        });
        fetch(`${API_DOMAIN}/state`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.STATE_REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.STATE_REGISTER_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}
export const deleteState = (handleSuccess, handleFailure, id) => {
    console.log("id", id)
    return function (dispatch) {
        dispatch({
            type: Constants.DELETE_STATE_REQUEST
        });
        return fetch(`${API_DOMAIN}/state/delete/${id}`, {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrer: "no-referrer",
        }).then(() => {
            dispatch({
                type: Constants.DELETE_STATE_SUCCESS,
                payload: ''
            });
            handleSuccess();
        });
    }
};

export const simpleAction = () => (dispatch) => {
    dispatch({
        type: 'SIMPLE_ACTION',
        payload: 'result_of_simple_action',
    });
}


export const updateState = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.STATE_UPDATE_REQUEST
        });
        fetch(`${API_DOMAIN}/state/update/${data.id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.STATE_UPDATE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.STATE_UPDATE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
  }

  export const reset = () => function (dispatch) {
    dispatch({
      type: Constants.RESET_DETAILS,
    });
  };