import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Card,
    DatePicker,
    Select
} from 'antd';

import Pattern from '../../../../../constants/Pattern'

const { Option } = Select


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 5 },
        lg: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
      span: 24,
    },
};
const Create = (props) => {
    const { onFinish , alllistPAlist,alllistFTlist,alllistVTlist,allFinanceList,alllManufacturer,alllBodyManufacturer } = props;
    console.log("main/master/vehicle/create",props)
    const [form] = Form.useForm();

    const onReset = () => {
      form.resetFields();
    };

    const optionsFT = [];
    if (alllistFTlist && alllistFTlist.list) {
        for (let i = 0; i < alllistFTlist.list.length; i++) {
            optionsFT.push({
                key: alllistFTlist.list[i].id,
                value: alllistFTlist.list[i].capacity
            })
        }
    }
    const optionsPA = [];
    if (alllistPAlist && alllistPAlist.list) {
        for (let i = 0; i < alllistPAlist.list.length; i++) {
            optionsPA.push({
                key: alllistPAlist.list[i].id,
                value: alllistPAlist.list[i].agencyName
            })
        }
    }

    const optionsTV = [];
    if (alllistVTlist && alllistVTlist.list) {
        for (let i = 0; i < alllistVTlist.list.length; i++) {
            optionsTV.push({
                key: alllistVTlist.list[i].id,
                value: alllistVTlist.list[i].vehicalType
            })
        }
    }
    const optionsFinance = [];
    if (allFinanceList && allFinanceList.list) {
        for (let i = 0; i < allFinanceList.list.length; i++) {
            optionsFinance.push({
                key: allFinanceList.list[i].id,
                value: allFinanceList.list[i].financerName
            })
        }
    }
    const optionsManufacture = [];
    if (alllManufacturer && alllManufacturer.list) {
        for (let i = 0; i < alllManufacturer.list.length; i++) {
            optionsManufacture.push({
                key: alllManufacturer.list[i].id,
                value: alllManufacturer.list[i].manufacturer
            })
        }
    }
    const optionsBodyManufacture = [];
    if (alllBodyManufacturer && alllBodyManufacturer.list) {
        for (let i = 0; i < alllBodyManufacturer.list.length; i++) {
            optionsBodyManufacture.push({
                key: alllBodyManufacturer.list[i].id,
                value: alllBodyManufacturer.list[i].bodyManufacturer
            })
        }
    }
console.log("optionsFT",optionsFT,"optionsPA",optionsPA,"optionsTV",optionsTV)
    return (
        <Card className="gx-card" title="Vehicle creation Form">
            <Form
                {...formItemLayout}
                form={form}
                name="vehicle"
                onFinish={onFinish}
                scrollToFirstError
            >

                <Form.Item
                    name="vehicle_number"
                    label="Vehicle Number"
                    rules={[
                        { required: true, message: 'Please enter vehicle number.', whitespace: true},
                        {
                            pattern: new RegExp(Pattern.VEHICLE_NUMBER),
                            message: "Please enter valid vehicle number."
                        },
                    ]} >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Engine Number"
                    name="engine_number"
                    rules={[{ required: true, message: 'Please enter your engine number.', whitespace: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Chasis Number"
                    name="chasis_number"
                    rules={[{ required: true, message: 'Please enter your chasis number.', whitespace: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                label="Manufacturer"
                name="vehicle_manufacturer_id"         
                rules={[{ required: false, message: 'Please select manufacturer.' , whitespace: true  }]}
                >
                    
                         <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select manufacturer"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        { optionsManufacture.map(d => <Option key={d.key}>{d.value}</Option>) }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="RC Date"
                    name="RC_date"
                    rules={[{ required: true, message: 'Please Enter your RC date.'}]}
                >
                    <DatePicker 
                    style={{width:'100%'}}
                    format={'DD/MM/YYYY'} />
                </Form.Item>
              
                <Form.Item
                    label="Model Number"
                    name="model_number"
                    // rules={[{ required: false, message: 'Please enter your model number.', whitespace: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Manufacturing Year"
                    name="manufacturing_year"
                    // rules={[{ required: false, message: 'Please enter manufacturing year.'}]}
                >
                    <DatePicker style={{width:'100%'}} picker="year" format={'YYYY'} />
                </Form.Item>
                <Form.Item
                label="Fuel Tank"
                name="fuel_tank_id"         
                rules={[{ required: false, message: 'Please select fuel tank capacity.' , whitespace: true  }]}
                >
                    
                         <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select fuel tank capacity"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        { optionsFT.map(d => <Option key={d.key}>{d.value}</Option>) }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Fuel Type"
                    name="fuel_type"
                    rules={[{ required: false, message: 'Please select fuel type.', whitespace: true }]}
                >
                    <Select style={{ width: '100%' }}
                        placeholder="Select Fuel Type"
                    >
                        <Option value="Diesel">Diesel</Option>
                        <Option value="Petrol">Petrol</Option>
                        <Option value="CNG">CNG</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                label="Vehicle Type"
                name="vehical_type_id"         
                rules={[{ required: true, message: 'Please select vehical type.' , whitespace: true  }]}
                >                   
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select vehicle type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        { optionsTV.map(d => <Option key={d.key}>{d.value}</Option>) }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Mileage"
                    name="mileage"
                    rules={[
                    { required: false, message: 'Please enter mileage.'},
                    {
                        pattern: new RegExp(Pattern.AMOUNT),
                        message: "Please enter valid mileage."
                    },
                ]}
                >
                <Input/>
                </Form.Item>
                <Form.Item
                label="Purchase Agency Name"
                name="purchase_agency_id"         
                rules={[{ required: false, message: 'Please select purchase agency name.' , whitespace: true  }]}
                >                    
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select purchase agency name"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        { optionsPA.map(d => <Option key={d.key}>{d.value}</Option>) }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Purchase Cost"
                    name="purchase_cost"
                    rules={[
                    { required: false, message: 'Please enter purchase cost.'},
                    {
                        pattern: new RegExp(Pattern.AMOUNT),
                        message: "Please enter valid purchase cost."
                    },
                ]}
                >
                <Input/>
                </Form.Item>
                <Form.Item
                    label="Purchase Date"
                    name="purchase_date"
                    rules={[{ required: false, message: 'Please Enter your purchase date.'}]}
                >
                    <DatePicker 
                    style={{width:'100%'}}
                    format={'DD/MM/YYYY'} />
                </Form.Item>
                <Form.Item
                label="Body Manufacturer"
                name="body_manufacturer_id"         
                rules={[{ required: false, message: 'Please select body manufacturer.' , whitespace: true  }]}
                >
                    
                         <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select body manufacturer"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        { optionsBodyManufacture.map(d => <Option key={d.key}>{d.value}</Option>) }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Body Cost"
                    name="body_cost"
                    rules={[{ required: false, message: 'Please enter body Cost.'},
                    {
                        pattern: new RegExp(Pattern.AMOUNT),
                        message: "Please enter valid body Cost"
                    },
                ]}
                >
                <Input min="0" style={{ width: '100%' }}/>
                </Form.Item>
                <Form.Item
                    label="Body Manufacturing Date"
                    name="body_manufacturing_date"
                    rules={[{ required: false, message: 'Please enter body manufacturing date.'}]}
                >
                    <DatePicker 
                    style={{width:'100%'}}
                    format={'DD/MM/YYYY'} />
                </Form.Item>
                <Form.Item
                label="Vehicle Financer"
                name="vehicle_financer_id"         
                rules={[{ required: false, message: 'Please select vehicle financer.' , whitespace: true  }]}
                >
                    
                         <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select vehicle financer"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        { optionsFinance.map(d => <Option key={d.key}>{d.value}</Option>) }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Loan Amount"
                    name="loan_amount"
                    rules={[{ required: false, message: 'Please enter loan amount.'},
                    {
                        pattern: new RegExp(Pattern.AMOUNT),
                        message: "Please enter valid loan amount"
                    },
                
                ]}
                >
                <Input min="0" style={{ width: '100%' }}/>
                </Form.Item>

                <Form.Item
                    label="Loan EMI"
                    name="loan_EMI"
                    rules={[{ required: false, message: 'Please enter loan EMI.'},
                    {
                        pattern: new RegExp(Pattern.AMOUNT),
                        message: "Please enter valid loan EMI"
                    },
                ]}
                >
                <Input min="0" style={{ width: '100%' }}/>
                </Form.Item>
                <Form.Item
                    label="Loan Interest Rate"
                    name="loan_interest_rate"
                    rules={[{ required: false, message: 'Please enter loan interest rate.'},
                    {
                        pattern: new RegExp(Pattern.AMOUNT),
                        message: "Please enter valid loan interest rate"
                    },
                
                ]}
                >
                <Input min="0" style={{ width: '100%' }}/>
                </Form.Item>
                <Form.Item
                label="Loan Tenure"
                name="LoanTenure"         
                rules={[{ required: false, message: 'Please select loan tenure.'  },
                {
                    pattern: new RegExp(Pattern.AMOUNT),
                    message: "Please enter valid loan tenure"
                },]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Completion Date"
                    name="completion_date"
                    rules={[{ required: false, message: 'Please enter completion date.'}]}
                >
                    <DatePicker 
                    style={{width:'100%'}}
                    format={'DD/MM/YYYY'} />
                </Form.Item>
                <Form.Item
                    label="Vehicle Insurance Number"
                    name="vehicle_insurance_number"
                    rules={[{ required: true, message: 'Please enter vehicle insurance number.', whitespace: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Insurance Expiry Date"
                    name="insurance_expiry_date"
                    rules={[{ required: true, message: 'Please enter insurance expiry date.'}]}
                >
                    <DatePicker 
                    style={{width:'100%'}}
                    format={'DD/MM/YYYY'} />
                </Form.Item>
                <Form.Item
                    label="Insurance Company"
                    name="insurance_company"
                    rules={[{ required: true, message: 'Please enter insurance company.', whitespace: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Insurance Premium Amount"
                    name="insurance_premium_amount"
                    rules={[{ required: false, message: 'Please enter insurance premium amount.'},
                    {
                        pattern: new RegExp(Pattern.AMOUNT),
                        message: "Please enter valid insurance premium amount"
                    }]}
                >
                <Input min="0" style={{ width: '100%' }}/>
                </Form.Item>
                <Form.Item {...tailFormItemLayout} className="gx-text-center">
                    <Button type="primary" htmlType="submit">
                        Create
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
export default Create;