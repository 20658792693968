import Constants from "constants/Trip";
import { API_DOMAIN } from '../../env';

export const getAllTrip = () => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_TRIP_REQUEST,
    });
    return fetch(`${API_DOMAIN}/trips`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_TRIP_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_TRIP_SUCCESS,
                    payload: body,
                });
            }
        });
};

export const getTripDetails = (id) => function (dispatch) {
    dispatch({
      type: Constants.GET_TRIP_DETAILS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/trip/details/${id}`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.GET_TRIP_DETAILS_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: Constants.GET_TRIP_DETAILS_SUCCESS,
            payload: body,
          });
        }
      });
  };

  export const getTripDetailsByLR = (handleSuccess, handleFailure, id) => function (dispatch) {
      dispatch({
        type: Constants.GET_TRIP_DETAILS_LR_REQUEST,
      });
      return fetch(`${API_DOMAIN}/trip/lr/details/${id}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: Constants.GET_TRIP_DETAILS_LR_FAIL,
              payload: body.error,
            });
            handleFailure()
          } else {
            dispatch({
              type: Constants.GET_TRIP_DETAILS_LR_SUCCESS,
              payload: body,
            });
            handleSuccess(body)
          }
        });
    };

export const createTrip = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.CREATE_TRIP_REQUEST
        });
        fetch(`${API_DOMAIN}/trip/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.CREATE_TRIP_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.CREATE_TRIP_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

export const createTripDiesel = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.CREATE_TRIP_DIESEL_REQUEST
        });
        fetch(`${API_DOMAIN}/tripdiesel/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.CREATE_TRIP_DIESEL_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.CREATE_TRIP_DIESEL_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

export const getTripDiesel = (id) => {
    return (dispatch) => {
        dispatch({
            type: Constants.GET_TRIP_DIESEL_REQUEST
        });
        fetch(`${API_DOMAIN}/tripdiesel/diesel/${id}`)
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_TRIP_DIESEL_ERROR,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_TRIP_DIESEL_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

export const getTripAdvance = (id) => {
    return (dispatch) => {
        dispatch({
            type: Constants.GET_TRIP_ADVANCE_REQUEST
        });
        fetch(`${API_DOMAIN}/tripadvance/trip/${id}`)
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_TRIP_ADVANCE_ERROR,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_TRIP_ADVANCE_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

export const createTripAdvance = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.CREATE_TRIP_ADVANCE_REQUEST
        });
        fetch(`${API_DOMAIN}/tripadvancedetails/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.CREATE_TRIP_ADVANCE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.CREATE_TRIP_ADVANCE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

export const updateTrip = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.UPDATE_TRIP_REQUEST
        });
        fetch(`${API_DOMAIN}/trip/update/${data.id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_TRIP_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.UPDATE_TRIP_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
  }


export const deleteDieselDetails = (handleSuccess, handleFailure, id) => {
    console.log("id", id)
    return function (dispatch) {
        dispatch({
            type: Constants.DELETE_TRIP_DIESEL_REQUEST
        });
        return fetch(`${API_DOMAIN}/tripdiesel/delete/${id}`, {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrer: "no-referrer",
        }).then(() => {
            dispatch({
                type: Constants.DELETE_TRIP_DIESEL_SUCCESS,
                payload: ''
            });
            handleSuccess();
        });
    }
};

export const deleteAdvanceDetails = (handleSuccess, handleFailure, id) => {
    console.log("id", id)
    return function (dispatch) {
        dispatch({
            type: Constants.DELETE_TRIP_ADVANCE_REQUEST
        });
        return fetch(`${API_DOMAIN}/tripadvancedetails/delete/${id}`, {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrer: "no-referrer",
        }).then(() => {
            dispatch({
                type: Constants.DELETE_TRIP_ADVANCE_SUCCESS,
                payload: ''
            });
            handleSuccess();
        });
    }
};


export const createTripUrl = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.CREATE_TRIP_URL_REQUEST
        });
        fetch(`${API_DOMAIN}/tripurl/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.CREATE_TRIP_URL_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.CREATE_TRIP_URL_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
};

export const getAllTripUrl = () => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_TRIP_URL_REQUEST,
    });
    return fetch(`${API_DOMAIN}/tripurl`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_TRIP_URL_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_TRIP_URL_SUCCESS,
                    payload: body,
                });
            }
        });
};
  
export const deleteTripUrl = (handleSuccess,id, data) =>{
  return function (dispatch) {
      dispatch({
          type: Constants.DELETE_TRIPURL_REQUEST
      });
      return fetch(`${API_DOMAIN}/tripurl/delete/${id}`,{
          method: "DELETE", 
          mode: "cors", 
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
              "Content-Type": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow",
          referrer: "no-referrer",
          body: JSON.stringify(data),
      }).then(() => {
                  dispatch({
                      type: Constants.DELETE_TRIPURL_SUCCESS,
                      payload:''
                  });
                  handleSuccess();
          });
  }
};

export const priviewTripUrl = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: Constants.GET_TRIP_URL_REQUEST
        });
        fetch(`${API_DOMAIN}/tripurl/preview`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_TRIP_URL_FAIL,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.GET_TRIP_URL_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
};

export const getAllTripUploads = (id) => function (dispatch) {
    dispatch({
        type: Constants.GET_ALL_TRIP_UPLOAD_REQUEST,
    });
    return fetch(`${API_DOMAIN}/tripuploads?trip_id=${id}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_ALL_TRIP_UPLOAD_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_ALL_TRIP_UPLOAD_SUCCESS,
                    payload: body,
                });
            }
        });
};

export const deleteTripuploads = (handleSuccess, handleFailure, id) => {
    console.log("id", id)
    return function (dispatch) {
        dispatch({
            type: Constants.DELETE_TRIP_UPLOADS_REQUEST
        });
        return fetch(`${API_DOMAIN}/tripuploads/delete/${id}`, {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrer: "no-referrer",
        }).then(() => {
            dispatch({
                type: Constants.DELETE_TRIP_UPLOADS_SUCCESS,
                payload: ''
            });
            handleSuccess();
        });
    }
};