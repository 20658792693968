import mirrorkey from 'mirrorkey';

export default mirrorkey([
  'GET_ALL_CUSTOMERS_REQUEST',
  'GET_ALL_CUSTOMERS_SUCCESS',
  'GET_ALL_CUSTOMERS_FAIL',
  'GET_CUSTOMER_DETAILS_REQUEST',
  'GET_CUSTOMER_DETAILS_SUCCESS',
  'GET_CUSTOMER_DETAILS_FAIL',
  'CUSTOMER_REGISTER_REQUEST',
  'CUSTOMER_REGISTER_ERROR',
  'CUSTOMER_REGISTER_SUCCESS',
  'DELETE_CUSTOMER_REQUEST',
  'DELETE_CUSTOMER_SUCCESS',
  'DELETE_CUSTOMER_FAIL',
  'CUSTOMER_UPDATE_REQUEST',
  'CUSTOMER_UPDATE_ERROR',
  'CUSTOMER_UPDATE_SUCCESS',
  'RESET_DETAILS'
]);
