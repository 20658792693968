import React from "react";
import {Route, Switch} from "react-router-dom";
import LrDetails from "./lrdetails";
import Create from "./create";
import Edit from "./edit";
import View from "./view";
import Invoice from "./invoice";
import Trip from "./trip";

const Country = ({match}) => (
  <Switch>
    <Route path={`${match.url}/lrdetails`} component={LrDetails}/>
    <Route path={`${match.url}/create`} component={Create}/>
    <Route path={`${match.url}/view/:id`} component={View}/>
    <Route path={`${match.url}/edit/:id`} component={Edit}/>
    <Route path={`${match.url}/invoice`} component={Invoice}/>
    <Route path={`${match.url}/trip`} component={Trip}/>
  </Switch>
);

export default Country;
